export default {
    // Common
    autocomplete: {
        placeholder: 'Click here to add users',
    },
    model: {
        handicap: {
            label: 'Handicap',
            hcp: 'HCP',
        },
    },

    // Routes
};
