<template>
    <div class="auth-password-reset">
        <template v-if="!d_form.success">
            <form
                class="auth-password-reset-form"
                @submit.prevent="submit"
            >
                <h1
                    v-trans
                    class="h3"
                >
                    auth.password.reset.title
                </h1>

                <p
                    v-trans
                    class="mb-0"
                >
                    auth.password.reset.text
                </p>

                <fieldset class="py-5">
                    <form-group
                        v-model="d_form.data.token"
                        :class="{ 'mb-0': ! d_form.errors.has('token') }"
                        :error="d_form.errors.first('token')"
                        :show-label="false"
                        type="hidden"
                    />

                    <form-group
                        v-model="d_form.data.email"
                        :error="d_form.errors.first('email')"
                        :label="$t('email')"
                        :placeholder="$t('email')"
                        type="email"
                    />

                    <form-group
                        v-model="d_form.data.password"
                        :error="d_form.errors.first('password')"
                        :label="$t('password')"
                        :placeholder="$t('password')"
                        type="password"
                    />

                    <form-group
                        v-model="d_form.data.password_confirmation"
                        :error="d_form.errors.first('password_confirmation')"
                        :label="$t('password_confirmation')"
                        :placeholder="$t('password_confirmation')"
                        type="password"
                    />
                </fieldset>

                <div class="text-center mb-3">
                    <btn
                        :error="d_form.error"
                        :loading="d_form.loading"
                        :success="d_form.success"
                        class="btn-primary"
                    >
                        {{ $t('form.submit') }}
                    </btn>
                </div>
            </form>

            <div class="text-center">
                <router-link
                    :to="{name: 'auth.login'}"
                    class="btn btn-link"
                >
                    {{ $t('auth.password.back_to_login') }}
                </router-link>
            </div>
        </template>

        <div v-else>
            <h1
                v-trans
                class="h3"
            >
                auth.password.reset.success.title
            </h1>

            <p
                v-trans
                class="py-5 mb-0"
            >
                auth.password.reset.success.text
            </p>

            <div class="text-center">
                <router-link
                    :to="{name: 'cups.index'}"
                    class="btn btn-primary"
                >
                    {{ $t('continue') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '@/library/Form';
import ApiToken from '@/library/ApiToken';
import FormGroup from '@/components/common/form/Group';

export default {
    name: 'AuthPasswordReset',
    components: {FormGroup},
    data() {
        return {
            d_form: new Form({
                token: '',
                email: '',
                password: '',
                password_confirmation: '',
            }),
        };
    },
    mounted() {
        // Delete the users token in case (s)he is logged in
        ApiToken.deleteToken();

        this.d_form.data.token = this.$route.params.token;
    },
    methods: {
        submit() {
            this.d_form.post('/auth/password/reset').then(({data}) => {
                new ApiToken().setToken(data.api_token);
            });
        },
    },
};
</script>
