import Vue from 'vue';
import Request from '@/library/Request';

export default {
    data() {
        return {
            pos_disabled: false,
            pos_lastUrl: undefined,
            pos_loading: false,
            pos_page: 1,
            pos_rows: [],
            pos_scrollY: 0,
            pos_triggerHeight: 50,
            pos_url: undefined,
        };
    },
    created() {
        window.addEventListener('scroll', this.pos_onScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.pos_onScroll);
    },
    mounted() {
        this.pos_getPaginated();
    },
    methods: {
        pos_getMoreIfNeeded() {
            if (this.pos_loading || this.pos_disabled) {
                return;
            }

            const totalScroll = this.pos_scrollY + innerHeight;
            const scrollHeight = document.body.scrollHeight;

            if (totalScroll >= scrollHeight) {
                this.pos_getPaginated();
            }
        },
        pos_onScroll() {
            this.pos_scrollY = window.scrollY || window.pageYOffset;

            const totalScroll = this.pos_scrollY + innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const sum = scrollHeight - totalScroll;

            if (sum <= this.pos_triggerHeight && !this.pos_loading && !this.pos_disabled) {
                this.pos_getPaginated();
            }
        },
        pos_getPaginated() {
            if (this.pos_loading || this.pos_disabled) {
                return;
            }

            let url = this.$helpers.buildUrl(this.pos_url, {page: this.pos_page});
            this.pos_lastUrl = url;

            this.pos_loading = true;

            new Request().get(url).then((response) => {
                if (url !== this.pos_lastUrl) return;

                if (
                    response.data.to === response.data.total ||
                    !response.data.next_page_url ||
                    response.data.current_page === response.data.last_page
                ) {
                    this.pos_disabled = true;
                }

                this.pos_page++;
                this.pos_loading = false;

                this.pos_success(response);

                Vue.nextTick(() => {
                    this.pos_getMoreIfNeeded();
                });
            }).finally(() => {
                if (url !== this.pos_lastUrl) return;

                this.pos_lastUrl = undefined;
            });
        },
        pos_success() {},
    },
    watch: {
        pos_url() {
            this.pos_page = 1;
            this.pos_disabled = false;
            this.pos_rows = [];

            this.pos_getPaginated();
        },
    },
};
