<template>
    <tr class="cups-users-table-row">
        <td
            v-if="columns.includes('image')"
            class="position-relative w-auto"
        >
            <ow-img
                v-if="row.id"
                :src="row"
                class="img-table rounded-circle"
                src-key="image.140"
            />

            <img
                v-else
                :src="var_placeholderSrc"
                class="img-table rounded-circle"
            >

            <div
                v-if="error"
                class="d-block invalid-feedback position-absolute"
            >
                {{ error }}
            </div>
        </td>

        <td v-if="columns.includes('name')">
            {{ User.displayName(row) }}
        </td>

        <td
            v-if="columns.includes('controls')"
            class="controls text-right w-auto"
        >
            <btn
                v-if="!row.id"
                class="btn-primary btn-sm"
                element="div"
                @click="$emit('changeDetails', row)"
            >
                {{ $t(c_detailsFilled ? 'change_details' : 'add_details') }}
            </btn>

            <btn-icon @click="$emit('delete', row)" />
        </td>
    </tr>
</template>

<script>
import User from '@/models/User';
import TableRow from '@/components/common/TableRow';

export default {
    name: 'CupsUsersTableRow',
    extends: TableRow,
    props: {
        error: {
            type: String,
            default: undefined,
        },
        row: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            User: User,
        };
    },
    computed: {
        c_detailsFilled() {
            for (const property of ['email', 'first_name', 'last_name']) {
                if (!this.row[property]) return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.controls {
    .btn + .btn {
        margin-left: 1rem;
    }
}

.invalid-feedback {
    bottom: 0;
    left: 0;
}
</style>
