<template>
    <b-card>
        <b-card-title>
            Borders
        </b-card-title>

        <p class="mb-4">
            Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons, or any other element.
        </p>

        <div class="mb-4">
            <h5>
                Border color
            </h5>

            <p>
                Change the border color using utilities built on our theme colors.
            </p>

            <preview>
                <span
                    v-for="color in themeColors"
                    :key="color"
                    class="border border-preview square bg-light mr-1"
                    :class="`border-${color}`"
                />

                <span class="border border-white border-preview square bg-light" />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Border-radius
            </h5>

            <p>
                Add classes to an element to easily round its corners.
            </p>

            <preview>
                <span
                    v-for="borderClass in borderClasses"
                    :key="borderClass"
                    class="border-preview square bg-secondary mr-1"
                    :class="borderClass"
                />
            </preview>
        </div>

        <div>
            <h5>
                Sizes
            </h5>

            <p>
                Use <code class="highlighter-rouge">.rounded-lg</code> or <code class="highlighter-rouge">.rounded-sm</code> for larger or smaller border-radius.
            </p>

            <preview>
                <span class="rounded-sm border-preview square bg-secondary mr-1" />

                <span class="rounded border-preview square bg-secondary mr-1" />

                <span class="rounded-lg border-preview square bg-secondary" />
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Borders',
    components: {Preview},
    data() {
        return {
            themeColors,
            borderClasses: [
                'rounded',
                'rounded-top',
                'rounded-right',
                'rounded-bottom',
                'rounded-left',
                'rounded-circle',
                'rounded-pill',
                'rounded-0',
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.border-preview {
    display: inline-block;
    width: 5rem;

    &.rounded-pill {
        width: 7.5rem;
        height: 5rem;
    }
}
</style>
