<template>
    <b-btn
        v-bind="$attrs"
        :variant="variant"
        :size="size"
        :class="baseClass"
        v-on="$listeners"
    >
        <i :class="`fa fa-${icon}`" />
    </b-btn>
</template>

<script>
/**
 * The component to display a clickable button with an icon inside. By default,
 * this component is styled as a red small circle button with `fa-times` (X) icon.
 */
export default {
    name: 'BtnIcon',
    props: {
        variant: {
            type: String,
            default: 'danger',
        },
        size: {
            type: String,
            default: 'sm',
        },
        /**
         * The parent component may add classes using `:class`. `:baseClass` can be used
         * if the parent wants to replace / remove the default class, in this case: `.btn-circle`.
         */
        baseClass: {
            type: [Object, Array, String],
            default: 'btn-circle',
        },
        icon: {
            type: String,
            default: 'times',
        },
    },
};
</script>
