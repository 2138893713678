<template>
    <b-card>
        <b-card-title>
            ListGroups
        </b-card-title>

        <p class="mb-4">
            List groups are a flexible and powerful component for displaying a series of content. Modify and extend them to support just about any content within.
        </p>

        <div class="mb-4">
            <h5>
                Basic example
            </h5>

            <p>
                The most basic list group is an unordered list with list items and the proper classes. Build upon it with the options that follow, or with your own CSS as needed.
            </p>

            <preview>
                <b-list-group>
                    <b-list-group-item
                        v-for="item in items"
                        :key="item"
                    >
                        {{ item }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Active
            </h5>

            <p>
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                Set the <code translate="no" class="notranslate text-nowrap">active</code> prop on a <code translate="no" class="notranslate text-nowrap">&lt;b-list-group-item&gt;</code> to indicate the current active selection.
            </p>

            <preview>
                <b-list-group>
                    <b-list-group-item
                        v-for="(item, index) in items"
                        :key="item"
                        :active="index === 1"
                    >
                        {{ item }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Disabled
            </h5>

            <p>
                Set the <code class="notranslate text-nowrap">disabled</code> prop on a <code class="notranslate text-nowrap">&lt;b-list-group-item&gt;</code> to  actionalable items. see below).
            </p>

            <preview>
                <b-list-group>
                    <b-list-group-item
                        v-for="(item, index) in items"
                        :key="item"
                        :disabled="[1, 3].includes(index)"
                    >
                        {{ item }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Buttons
            </h5>

            <p>
                Use <code class="highlighter-rouge">&lt;a&gt;</code>s or <code class="highlighter-rouge">&lt;button&gt;</code>s to create <em>actionable</em> list group items with hover, disabled, and active states by adding <code class="highlighter-rouge">.list-group-item-action</code>. We separate these pseudo-classes to ensure list groups made of non-interactive elements (like <code class="highlighter-rouge">&lt;li&gt;</code>s or <code class="highlighter-rouge">&lt;div&gt;</code>s) don’t provide a click or tap affordance.
            </p>

            <preview>
                <b-list-group>
                    <b-list-group-item
                        v-for="(item, index) in items"
                        :key="item"
                        :active="index === 0"
                        button
                        :disabled="index === 4"
                    >
                        {{ item }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Flush
            </h5>

            <p>
                Add <code class="highlighter-rouge">.list-group-flush</code> to remove some borders and rounded corners to render list group items edge-to-edge in a parent container (e.g., cards).
            </p>

            <preview>
                <b-list-group flush>
                    <b-list-group-item
                        v-for="item in items"
                        :key="item"
                    >
                        {{ item }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Horizontal
            </h5>

            <p>
                Add <code class="highlighter-rouge">.list-group-horizontal</code> to change the layout of list group items from vertical to horizontal across all breakpoints. Alternatively, choose a responsive variant <code class="highlighter-rouge">.list-group-horizontal-{sm|md|lg|xl}</code> to make a list group horizontal starting at that breakpoint’s <code class="highlighter-rouge">min-width</code>. Currently <strong>horizontal list groups cannot be combined with flush list groups.</strong>
            </p>

            <preview>
                <b-list-group horizontal>
                    <b-list-group-item
                        v-for="item in itemsShort"
                        :key="item"
                    >
                        {{ item }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>

        <div>
            <h5>
                Contextual classes
            </h5>

            <p>
                Use contextual classes to style list items with a stateful background and color.
            </p>

            <preview>
                <b-list-group>
                    <b-list-group-item>
                        Default
                    </b-list-group-item>

                    <b-list-group-item
                        v-for="color in themeColors"
                        :key="color"
                        :variant="color"
                    >
                        {{ color }}
                    </b-list-group-item>
                </b-list-group>
            </preview>

            <p>
                Contextual classes also work with <code class="highlighter-rouge">.list-group-item-action</code>. Note the addition of the hover styles here not present in the previous example. Also supported is the <code class="highlighter-rouge">.active</code> state; apply it to indicate an active selection on a contextual list group item.
            </p>

            <preview>
                <b-list-group>
                    <b-list-group-item button>
                        Default
                    </b-list-group-item>

                    <b-list-group-item
                        v-for="color in themeColors"
                        :key="color"
                        button
                        :variant="color"
                    >
                        {{ color }}
                    </b-list-group-item>
                </b-list-group>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'ListGroups',
    components: {Preview},
    data() {
        return {
            themeColors,
            items: [
                'Cras justo odio',
                'Dapibus ac facilisis in',
                'Morbi leo risus',
                'Porta ac consectetur ac',
                'Vestibulum at eros',
            ],
        };
    },
    computed: {
        itemsShort() {
            return this.items.map((item) => {
                return item.split(' ')[0];
            });
        },
    },
};
</script>

