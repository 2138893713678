export default {
    data() {
        return {
            cfd_dataChanged: false,
            cfd_hasMounted: false,
        };
    },
    beforeRouteLeave(to, from, next) {
        if (!this.cfd_dataChanged) {
            next();

            return;
        }

        const confirm = this.$helpers.confirm({
            body: 'confirm.discard.body',
        }).show();

        confirm.on('resolve', () => {
            next();

            confirm.close();

            return;
        });

        next(false);
    },
    mounted() {
        this.$nextTick (function() {
            this.cfd_hasMounted = true;
            this.$emit('cfd_mounted');
        });
    },
    methods: {
        cfd_onChange() {
            this.cfd_dataChanged = true;
        },
    },
    watch: {
        d_data: {
            handler() {
                if (this.cfd_hasMounted) this.$emit('cfd_change');
            },
            deep: true,
        },
    },
};
