<template>
    <div
        ref="modal"
        class="modal fade show"
        @click="d_modal.close()"
    >
        <div
            class="modal-dialog"
            @click.stop=""
        >
            <div class="modal-content">
                <div
                    v-if="hasSlot('modal-header')"
                    class="modal-header"
                >
                    <slot name="modal-header" />
                </div>

                <div class="modal-body">
                    <slot name="modal-body" />
                </div>

                <div class="modal-footer">
                    <slot name="modal-footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        modal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            d_modal: this.modal,
            d_backdropDiv: undefined,
        };
    },
    mounted() {
        let body = document.querySelector('body');
        body.classList.add('modal-open');

        this.addScrollbarWidthPaddings();
        this.addModalBackdrop();
    },
    beforeDestroy() {
        this.removeModalBackdrop();
        this.removeScrollbarWidthPaddings();

        let body = document.querySelector('body');
        body.classList.remove('modal-open');
    },
    methods: {
        /**
         * Adds the modal backdrop to the body.
         */
        addModalBackdrop() {
            const backdropDiv = document.createElement('div');
            document.body.appendChild(backdropDiv);
            backdropDiv.className = 'modal-backdrop fade show';

            this.d_backdropDiv = backdropDiv;
        },

        /**
         * Adds the current scrollbar width to the body.
         */
        addScrollbarWidthPaddings() {
            const scrollbarWidth = this.getScrollbarWidth();
            const currentBodyRightPadding = this.saveCurrentBodyRightPadding();

            document.body.style.paddingRight = `${scrollbarWidth + currentBodyRightPadding}px`;
            this.$refs.modal.style.paddingRight = `${scrollbarWidth + currentBodyRightPadding}px`;
        },

        /**
         * Checks if the specified slot is present.
         */
        hasSlot(slot) {
            return !!this.$slots[slot];
        },

        /**
         * Saves the current body right padding and stores it in the elements
         * dataset. Returns the calculated value.
         */
        saveCurrentBodyRightPadding() {
            let elementStyle = document.body.currentStyle || window.getComputedStyle(document.body);
            let paddingRight = elementStyle.paddingRight;
            let fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

            document.body.dataset.paddingRight = paddingRight;

            if (/rem/g.test(paddingRight)) {
                paddingRight = parseFloat(paddingRight) * fontSize;
            } else {
                paddingRight = parseFloat(paddingRight);
            }

            return paddingRight;
        },

        /**
         * Removes the modal backdrop element from the body.
         */
        removeModalBackdrop() {
            document.body.removeChild(this.d_backdropDiv);
        },

        /**
         * Returns the scrollbar width.
         */
        getScrollbarWidth() {
            const scrollDiv = document.createElement('div');
            document.body.appendChild(scrollDiv);
            scrollDiv.className = 'modal-scrollbar-measure';

            const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;

            document.body.removeChild(scrollDiv);

            return scrollbarWidth;
        },

        /**
         * Removes the added paddings and resets the saved value.
         */
        removeScrollbarWidthPaddings() {
            document.body.style.paddingRight = document.body.dataset.paddingRight;
            this.$refs.modal.style.paddingRight = '';
        },
    },
};
</script>
