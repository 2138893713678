import Vue from 'vue';
import '@babel/polyfill';
import {get} from 'lodash';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';
import PortalVue from 'portal-vue';
import VueLodash from 'vue-lodash';
import VueMoment from 'vue-moment';
import Modal from '@/library/Modal';
import vueI18n from '@/lang/vueI18n';
import mixins from '@/library/mixins';
import VueLazyload from 'vue-lazyload';
import VueResource from 'vue-resource';
import filters from '@/library/filters';
import Btn from '@/components/common/Btn';
import * as Sentry from '@sentry/browser';
import OwImg from '@/components/common/OwImg';
import {InlineSvgPlugin} from 'vue-inline-svg';
import Loader from '@/components/common/Loader';
import bootstrapVue from '@/library/bootstrapVue';
import BtnIcon from '@/components/common/BtnIcon';
import transDirective from '@/library/directives/trans';
import BtnResource from '@/components/common/BtnResource';
import componentHelpers from '@/library/componentHelpers';
import httpInterceptors from '@/library/httpInterceptors';
import infiniteScroll from '@/library/directives/infinite-scroll';
import WaitForResource from '@/components/common/WaitForResource';
import AlertResourceError from '@/components/common/AlertResourceError';

Vue.config.productionTip = false;

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
        integrations: [new Sentry.Integrations.Vue({Vue})],
    });
}

Vue.use(Modal);
Vue.use(filters);
Vue.use(VueMeta);
Vue.use(VueMoment);
Vue.use(PortalVue);
Vue.use(VueResource);
Vue.use(InlineSvgPlugin);
Vue.use(componentHelpers);
Vue.use(httpInterceptors);
Vue.use(VueLodash, {lodash: {get}});
Vue.use(VueLazyload, {
    preLoad: 1.3,
});

Vue.directive('trans', transDirective);
Vue.directive('infinite-scroll', infiniteScroll);

Vue.mixin(mixins);

Vue.component('btn', Btn);
Vue.component('ow-img', OwImg);
Vue.component('loader', Loader);
Vue.component('btn-icon', BtnIcon);
Vue.component('btn-resource', BtnResource);
Vue.component('wait-for-resource', WaitForResource);
Vue.component('alert-resource-error', AlertResourceError);

bootstrapVue.init();

new Vue({
    router,
    i18n: vueI18n,
    render: h => h(App),
}).$mount('#app');
