import Course from '@/models/Course';
import Show from '@/components/courses/Show';
import Index from '@/components/courses/Index';
import Create from '@/components/courses/Create';
import OwRouterView from '@/components/common/OwRouterView';

let routes = [
    {
        path: '/courses',
        component: OwRouterView,
        meta: {
            middleware: ['Auth'],
        },
        children: [
            {
                path: '',
                name: 'courses.index',
                component: Index,
            },
            {
                path: 'create',
                name: 'courses.create',
                component: Create,
                meta: {
                    middleware: ['Policy'],
                    action: 'store',
                    model: Course,
                },
            },
            {
                path: ':id',
                name: 'courses.show',
                component: Show,
            },
        ],
    },
];

export default routes;
