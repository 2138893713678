class Policy {
    /**
     * Before.
     *
     * @param {User} user
     * @param {Model} model
     */
    static before(user, model = undefined) {
        return !!user.id && user.roles;
    }

    /**
     * Can view.
     *
     * @param {User} user
     * @param {Model} model
     */
    static view(user, model = undefined) {
        return true;
    }

    /**
     * Can store.
     *
     * @param {User} user
     * @param {Model} model
     */
    static store(user, model) {
        return true;
    }

    /**
     * Can update.
     *
     * @param {User} user
     * @param {Model} model
     */
    static update(user, model) {
        return true;
    }

    /**
     * Can delete.
     *
     * @param {User} user
     * @param {Model} model
     */
    static delete(user, model) {
        return true;
    }
}

export default Policy;
