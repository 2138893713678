<template>
    <td
        :class="{ 'is-invalid': error }"
        class="form-table-group"
    >
        <div class="position-relative">
            <slot name="input">
                <input
                    ref="input"
                    v-model="d_value"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :type="type"
                >
            </slot>

            <div
                v-if="error"
                class="invalid-feedback alert-danger position-absolute p-1"
            >
                {{ error }}
            </div>
        </div>
    </td>
</template>

<script>
export default {
    name: 'FormTableGroup',
    props: {
        disabled: Boolean,
        error: {
            type: String,
            default: undefined,
        },
        placeholder: {
            default: '',
            type: String,
        },
        type: {
            type: String,
            default: 'text',
            validator: (value) => {
                return [
                    'text', 'email', 'password', 'date', 'hidden',
                ].indexOf(value) !== -1;
            },
        },
        value: {
            default: '',
            type: [String, Number],
        },
    },
    data() {
        return {
            d_value: this.value,
        };
    },
    watch: {
        d_value() {
            this.$emit('input', this.d_value);
        },
        value(value) {
            this.d_value = value;
        },
    },
};
</script>
