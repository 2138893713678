import Store from '@/library/Store';
import helpers from '@/library/helpers';
import Confirm from '@/components/common/Confirm';

const componentHelpers = {
    install(Vue) {
        Vue.prototype.$helpers = {

            ...helpers,

            confirm(options = {}) {
                let modal = Vue.modal(options);

                modal.options.modalComponent = Confirm;

                return modal;
            },

            modal(options = {}) {
                return Vue.modal(options);
            },

        };

        Vue.prototype.$store = Store;
    },
};

export default componentHelpers;
