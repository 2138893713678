<template>
    <div>
        <template v-if="!posts.loading && !posts.models.length">
            {{ noPostsText }}
        </template>

        <b-row>
            <b-col
                v-for="post of posts.models"
                :key="post.id"
                :sm="6"
                :md="4"
            >
                <post-card
                    :post="post"
                    @click="$emit('postClick', $event)"
                />
            </b-col>
        </b-row>

        <div class="text-center">
            <btn
                v-if="!posts.isLastPage()"
                class="btn-outline-secondary text-dark"
                :loading="posts.loading"
                @click="posts.fetch()"
            >
                {{ $t('posts.cardDeck.loadMore') }}
            </btn>
        </div>
    </div>
</template>

<script>
import PostCard from '@/components/posts/Card';
import {Posts} from '@/models/Post';

export default {
    name: 'PostCardDeck',
    components: {PostCard},
    props: {
        noPostsText: {
            default: function() {
                return this.$t('posts.cardDeck.noPostsText');
            },
            type: String,
        },
        posts: {
            required: true,
            type: Posts,
        },
    },
    mounted() {
        this.posts.page(1).fetch();
    },
};
</script>
