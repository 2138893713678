<template>
    <b-card>
        <b-card-title>
            Pagination
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for showing pagination to indicate a series of related content exists across multiple pages.
        </p>

        <div class="mb-4">
            <h5>
                Example
            </h5>

            <p>
                We use a large block of connected links for our pagination, making links hard to miss and easily scalable—all while providing large hit areas. Pagination is built with list HTML elements so screen readers can announce the number of available links. Use a wrapping <code class="highlighter-rouge">&lt;nav&gt;</code> element to identify it as a navigation section to screen readers and other assistive technologies.
            </p>

            <preview>
                <b-pagination
                    :value="2"
                    hide-goto-end-buttons
                    next-text="Next"
                    :per-page="10"
                    prev-text="Prev"
                    :total-rows="30"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Working with icons
            </h5>

            <p>
                Looking to use an icon or symbol in place of text for some pagination links? Be sure to provide proper screen reader support with <code class="highlighter-rouge">aria</code> attributes.
            </p>

            <preview>
                <b-pagination
                    :value="2"
                    hide-goto-end-buttons
                    :per-page="10"
                    :total-rows="30"
                />
            </preview>
        </div>

        <div>
            <h5>
                Sizing
            </h5>

            <p>
                Fancy larger or smaller pagination? Add <code class="highlighter-rouge">.pagination-lg</code> or <code class="highlighter-rouge">.pagination-sm</code> for additional sizes.
            </p>

            <preview>
                <b-pagination
                    :value="2"
                    hide-goto-end-buttons
                    :per-page="10"
                    :total-rows="30"
                    size="sm"
                />

                <b-pagination
                    :value="2"
                    hide-goto-end-buttons
                    :per-page="10"
                    :total-rows="30"
                />

                <b-pagination
                    :value="2"
                    hide-goto-end-buttons
                    :per-page="10"
                    :total-rows="30"
                    size="lg"
                />
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Pagination',
    components: {Preview},
};
</script>
