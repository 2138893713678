<template>
    <b-card>
        <b-card-title>
            Tables
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for opt-in styling of tables (given their prevalent use in JavaScript plugins) with Bootstrap.
        </p>

        <div class="mb-4">
            <h5>
                Default
            </h5>

            <p>
                Using the most basic table markup, here’s how <code class="highlighter-rouge">.table</code>-based tables look in Bootstrap. <strong>All table styles are inherited in Bootstrap 4</strong>, meaning any nested tables will be styled in the same manner as the parent.
            </p>

            <preview class="bg-light">
                <b-table :items="rows" />
            </preview>

            <p>
                You can also invert the colors—with light text on dark backgrounds—with <code class="highlighter-rouge">.table-dark</code>.
            </p>

            <preview class="bg-light">
                <b-table
                    dark
                    :items="rows"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Striped
            </h5>

            <p>
                Use <code class="highlighter-rouge">.table-striped</code> to add zebra-striping to any table row within the <code class="highlighter-rouge">&lt;tbody&gt;</code>.
            </p>

            <preview class="bg-light">
                <b-table
                    :items="rows"
                    striped
                />
            </preview>

            <preview class="bg-light">
                <b-table
                    dark
                    :items="rows"
                    striped
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Borderless
            </h5>

            <p>
                Add <code class="highlighter-rouge">.table-borderless</code> for a table without borders.
            </p>

            <preview class="bg-light">
                <b-table
                    borderless
                    :items="rows"
                />
            </preview>

            <preview class="bg-light">
                <b-table
                    borderless
                    dark
                    :items="rows"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Hoverable rows
            </h5>

            <p>
                Add <code class="highlighter-rouge">.table-hover</code> to enable a hover state on table rows within a <code class="highlighter-rouge">&lt;tbody&gt;</code>.
            </p>

            <preview class="bg-light">
                <b-table
                    hover
                    :items="rows"
                />
            </preview>

            <preview class="bg-light">
                <b-table
                    dark
                    hover
                    :items="rows"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Small table
            </h5>

            <p>
                Add <code class="highlighter-rouge">.table-sm</code> to make tables more compact by cutting cell padding in half.
            </p>

            <preview class="bg-light">
                <b-table
                    :items="rows"
                    small
                />
            </preview>

            <preview class="bg-light">
                <b-table
                    dark
                    :items="rows"
                    small
                />
            </preview>
        </div>

        <div>
            <h5>
                Responsive tables
            </h5>

            <p>
                Across every breakpoint, use <code class="highlighter-rouge">.table-responsive</code> for horizontally scrolling tables.
            </p>

            <preview class="bg-light">
                <b-table
                    :items="responsiveRows"
                    responsive
                />
            </preview>
        </div>
    </b-card>
</template>

<script>
import {cloneDeep} from 'lodash';
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Tables',
    components: {Preview},
    data() {
        return {
            rows: [
                {'#': '1', 'First': 'Mark', 'Last': 'Otto', 'Handle': '@mdo'},
                {'#': '2', 'First': 'Jacob ', 'Last': 'Thornton', 'Handle': '@fat'},
                {'#': '3', 'First': 'Larry', 'Last': ' the Bird', 'Handle': '@twitter'},
            ],
        };
    },
    computed: {
        responsiveRows() {
            return cloneDeep(this.rows).map((row) => {
                for (let i in [1, 2, 3]) {
                    for (let column in row) {
                        row[`${column}${i}`] = row[column];
                    }
                }

                return row;
            });
        },
    },
};
</script>
