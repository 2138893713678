<template>
    <b-alert
        v-bind="$attrs"
        :show="error"
        :dismissible="dismissible"
        :variant="variant"
        class="fixed-bottom-center"
        v-on="$listeners"
    >
        {{ resource.firstErrorMessage }}
    </b-alert>
</template>

<script>
import {Collection, Model} from '@/models/vue-mc/Model';

/**
 * A red alert component that is shown in the bottom of the page when
 * the given resource has an error.
 */
export default {
    name: 'AlertResourceError',
    props: {
        resource: {
            type: [Collection, Model],
            required: true,
        },
        dismissible: {
            type: Boolean,
            default: true,
        },
        variant: {
            type: String,
            default: 'danger',
        },
    },
    computed: {
        error() {
            return this.resource.hasErrors || this.resource.fatal;
        },
    },
};
</script>
