<template>
    <b-card>
        <b-card-title>
            Badges
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for badges, our small count and labeling component.
        </p>

        <div class="mb-4">
            <h5>
                Headings
            </h5>

            <p>
                Badges scale to match the size of the immediate parent element by using relative font sizing and <code class="highlighter-rouge">em</code> units.
            </p>

            <preview>
                <component
                    :is="'h' + i"
                    v-for="i in 6"
                    :key="i"
                >
                    h{{ i }}. Bootstrap heading <b-badge>New</b-badge>
                </component>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Buttons
            </h5>

            <p>
                Badges can be used as part of links or buttons to provide a counter.
            </p>

            <preview>
                <b-button variant="primary">
                    Notifications

                    <b-badge variant="light">
                        4
                    </b-badge>
                </b-button>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Contextual
            </h5>

            <p>
                Add any of the below mentioned modifier classes to change the appearance of a badge.
            </p>

            <preview>
                <b-badge
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    :variant="color"
                >
                    {{ color }}
                </b-badge>
            </preview>
        </div>

        <div>
            <h5>
                Pill badges
            </h5>

            <p>
                Use the <code class="highlighter-rouge">.badge-pill</code> modifier class to make badges more rounded (with a larger <code class="highlighter-rouge">border-radius</code> and additional horizontal <code class="highlighter-rouge">padding</code>). Useful if you miss the badges from v3.
            </p>

            <preview>
                <b-badge
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    pill
                    :variant="color"
                >
                    {{ color }}
                </b-badge>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Badges',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
};
</script>
