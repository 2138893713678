import {Model, Collection} from './vue-mc/Model';
import {LeagueGroupTeams} from './LeagueGroupTeam';

export class LeagueGroup extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            league_group_teams: [],
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'league-groups',
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            league_group_teams: (val) => {
                if (!(val instanceof LeagueGroupTeams)) {
                    return new LeagueGroupTeams(val);
                }

                return val;
            },
        };
    }
};

export class LeagueGroups extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: LeagueGroup,
        };
    }
};
