import Enum from './Enum';

export default class HandicapType extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            ZERO: 0,
            QUARTER: 1,
            HALF: 2,
            THREE_QUARTER: 3,
            FULL: 4,
        };
    };

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'handicap_type';
    }
};
