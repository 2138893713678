import axios from 'axios';
import ApiToken from '@/library/ApiToken';

/**
 * The axios instance that will be used as the HTTP client for all requests to
 * the API.
 */
const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

/**
 * Add API token (if exists) to request header before it is sent to the API.
 */
const authInterceptor = (config) => {
    const apiToken = (new ApiToken).getToken();

    if (apiToken) {
        config.headers['Authorization'] = `Bearer ${apiToken}`;
    }

    return config;
};

httpClient.interceptors.request.use(authInterceptor);

export default httpClient;
