<template>
    <div>
        <b-navbar class="bg-white border-bottom">
            <b-navbar-brand>
                <img :src="owowLogo">
            </b-navbar-brand>

            <b-navbar-nav class="ml-auto">
                <b-button
                    href="mailto:info@owow.io"
                    variant="outline-dark"
                >
                    Contact
                </b-button>
            </b-navbar-nav>
        </b-navbar>

        <b-container class="py-5">
            <div class="text-center py-10">
                <h1>
                    Welcome to the WAMP styleguide
                </h1>

                <p>
                    This library covers the base styles, components, and layouts for an example design system.
                </p>
            </div>

            <b-row>
                <b-col cols="2">
                    <b-nav
                        v-b-scrollspy
                        class="sticky-top py-3"
                        pills
                        vertical
                    >
                        <b-nav-item
                            v-for="component in components"
                            :key="component.name"
                            :href="`#styleguide-${component.name}`"
                        >
                            {{ component.name }}
                        </b-nav-item>
                    </b-nav>
                </b-col>

                <b-col
                    class="card-stack"
                    cols="10"
                >
                    <component
                        :is="component"
                        v-for="component in components"
                        :id="`styleguide-${component.name}`"
                        :key="component.name"
                    />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Card from '@/components/styleguide/Card.vue';
import Navs from '@/components/styleguide/Navs.vue';
import Forms from '@/components/styleguide/Forms.vue';
import Modal from '@/components/styleguide/Modal.vue';
import Alerts from '@/components/styleguide/Alerts.vue';
import Shadows from '@/components/styleguide/Shadows.vue';
import Colors from '@/components/styleguide/Colors.vue';
import Badges from '@/components/styleguide/Badges.vue';
import Navbar from '@/components/styleguide/Navbar.vue';
import Tables from '@/components/styleguide/Tables.vue';
import Toasts from '@/components/styleguide/Toasts.vue';
import Borders from '@/components/styleguide/Borders.vue';
import Buttons from '@/components/styleguide/Buttons.vue';
import Popover from '@/components/styleguide/Popover.vue';
import Spinners from '@/components/styleguide/Spinners.vue';
import Tooltips from '@/components/styleguide/Tooltips.vue';
import Dropdowns from '@/components/styleguide/Dropdowns.vue';
// Prevent interference with existing html tag.
import SgProgress from '@/components/styleguide/Progress.vue';
import ListGroups from '@/components/styleguide/ListGroups.vue';
import Pagination from '@/components/styleguide/Pagination.vue';
import Typography from '@/components/styleguide/Typography.vue';
import ButtonGroup from '@/components/styleguide/ButtonGroup.vue';
import InputGroups from '@/components/styleguide/InputGroups.vue';

export default {
    name: 'StyleguideIndex',
    data() {
        return {
            components: [
                Alerts,
                Badges,
                Borders,
                Buttons,
                ButtonGroup,
                Card,
                Colors,
                Dropdowns,
                Forms,
                InputGroups,
                ListGroups,
                Modal,
                Navs,
                Navbar,
                Pagination,
                Popover,
                SgProgress,
                Shadows,
                Spinners,
                Tables,
                Toasts,
                Tooltips,
                Typography,
            ],
            owowLogo: require('@/components/styleguide/owow.svg'),
        };
    },
};
</script>

<style scoped>
.py-10 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
</style>
