<template>
    <div class="users-show position-relative">
        <div class="top-edge clearfix">
            <router-link
                :to="{ name: 'users.index' }"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>
        </div>

        <div class="row justify-content-center mb-4 py-5">
            <div class="col-md-8">
                <loader
                    v-if="d_loading"
                    :loading="true"
                    class="w-100 text-center"
                />

                <div
                    v-else-if="d_form.data.id"
                    class="card shadow-sm"
                >
                    <users-form
                        :data="d_form.data"
                        :errors="d_form.errors"
                        :loading="d_form.loading"
                        class="card-body"
                        @submit="submit"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/models/User';
import Form from '@/library/Form';
import Redirect from '@/library/Redirect';
import UsersForm from '@/components/users/Form';
import navbarTitle from '@/library/mixins/navbarTitle';

export default {
    name: 'UsersShow',
    components: {UsersForm},
    mixins: [navbarTitle],
    data() {
        return {
            d_form: new Form,
            d_loading: false,
        };
    },
    mounted() {
        this.d_navbarTitle = this.$t('users');

        const id = this.$route.params.id;

        this.d_loading = true;

        User.find(id).then(({data}) => {
            this.d_form.data = data;

            if (this.d_form.data.course_id === null) {
                this.d_form.data.course_id = undefined;
            }

            User.getRoles(id).then(({data}) => {
                this.$set(this.d_form.data, 'roles', data);
            });

            this.d_navbarTitle = User.displayName(data);

            this.d_loading = false;
        });
    },
    methods: {
        submit() {
            const userUrl = `${User.endpoint}/${this.d_form.data.id}`;
            const roleUrl = `${userUrl}/roles`;

            let roleForm = new Form({
                roles: this.d_form.data.roles,
            });

            const method = this.d_form.data.pro_at ? 'put' : 'delete';

            this.d_form.loading = true;

            User.updatePro(this.d_form.data.id, method).then(() => {
                roleForm.put(roleUrl).then(() => {
                    const image = this.d_form.data.image;

                    // Don't send image object to back-end
                    this.$set(this.d_form.data, 'image', undefined);

                    this.d_form.put(userUrl).then(() => {
                        Redirect.to({name: 'users.index'});
                    }).catch(() => {
                        // Something happened, put image object back
                        this.$set(this.d_form.data, 'image', image);
                    });
                });
            });
        },
    },
};
</script>