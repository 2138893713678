<template>
    <div class="cups-create position-relative">
        <div class="top-edge clearfix">
            <router-link
                :to="{name: 'cups.index'}"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>
        </div>

        <div class="row justify-content-center py-5">
            <div class="col-md-8 col-lg-6">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <cups-form
                            :data="d_form.data"
                            :errors="d_form.errors"
                            :loading="d_form.loading"
                            class="form-groups-divided"
                            @cfd_change="cfd_onChange"
                            @submit="submit"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cup from '@/models/Cup';
import Form from '@/library/Form';
import Redirect from '@/library/Redirect';
import CupsForm from '@/components/cups/Form';
import confirmFormDiscard from '@/mixins/confirmFormDiscard';

export default {
    name: 'CupsCreate',
    components: {CupsForm},
    mixins: [confirmFormDiscard],
    data() {
        return {
            d_form: new Form({
                is_bcko: false,
            }),
        };
    },
    created() {
        this.d_form.addModifier('players', Cup.modifyPlayers);
    },
    methods: {
        submit(data) {
            this.d_form.data = Object.assign({}, data);

            this.d_form.post(Cup.endpoint).then(({data}) => {
                this.cfd_dataChanged = false;

                Redirect.to({name: 'cups.show', params: {id: data.id}});
            });
        },
    },
};
</script>
