import Me from '@/library/Me';
import User from '@/models/User';
import ApiToken from '@/library/ApiToken';
import Middleware from '@/library/Middleware';

export default class AuthOrGuest extends Middleware {
    /**
     * AuthOrGuest constructor.
     */
    constructor() {
        super();

        this.name = 'AuthOrGuest';
    }

    /**
     * Handles the middleware.
     * Try to get the authenticated user. If failed, redirect to login page.
     *
     * @param {Object} to
     * @param {Object} from
     * @param {Function} next
     */
    handle(to, from, next) {
        if (!this.needsToHandle(to)) {
            return next();
        }

        let apiToken = new ApiToken();

        // Do not try to fetch the user when no api token is present.
        if (!apiToken.getToken() || apiToken.getToken() === 'undefined') {
            return next();
        }

        let me = new Me();

        if (!me.id) {
            // Try to authenticate the current user to the API.
            return User.find('me')
                .then(({data}) => {
                    me.fill(data);
                }).catch(() => {
                    // Delete the api token to prevent further failing
                    // requests.
                    ApiToken.deleteToken();
                }).finally(() => {
                    // Always continue, even if it fails.
                    return next();
                });
        }

        return next();
    }
}
