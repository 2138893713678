
export default class ApiToken {
    /**
     * ApiToken constructor.
     */
    constructor() {
        this.token = undefined;
    }

    /**
     * Adds the token to a url.
     *
     * @param {string} url
     */
    addToUrl(url) {
        if (url === undefined) {
            return url;
        }

        let splitted = url.split('?');

        if (splitted.length <= 1) {
            return url;
        }

        let parameters = splitted[1].split('&');

        parameters.push(`token=${this.getToken()}`);

        return [splitted[0], parameters.join('&')].join('?');
    }

    /**
     * Retrieves the token from local storage.
     */
    getToken() {
        return localStorage.getItem('api_token');
    }

    /**
     * Stores the token in local storage.
     */
    setToken(token) {
        if (!token) {
            return false;
        }

        this.token = token;

        localStorage.setItem('api_token', token);
    }

    /**
     * Removes the token from local storage.
     */
    static deleteToken() {
        localStorage.removeItem('api_token');
    }
}
