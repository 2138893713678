<script>
import {Line} from 'vue-chartjs';

export default {
    extends: Line,
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        labels: {
            type: Array,
            default: () => [],
        },
        model: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        width: {
            type: Number,
            default: 850,
        },
    },
    mounted() {
        // Overwriting base render method with actual data.
        this.renderChart({
            labels: this.labels,
            datasets: [
                {
                    label: this.title,
                    backgroundColor: '#007BFF',
                    pointBackgroundColor: 'white',
                    borderWidth: 1,
                    pointBorderColor: '#249EBF',
                    fill: true,
                    data: this.data,
                },
            ],
        }, {
            legend: {
                // Prevent deselecting the chart line
                onClick: undefined,
            },
            responsive: true,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            tooltips: {
                callbacks: {
                    label: ((toolTip) => {
                        return `${this.$t(this.model)}: ${toolTip.yLabel}`;
                    }),
                },
                displayColors: false,
                mode: 'single',
            },
        });
    },
};
</script>
