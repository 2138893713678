import moment from 'moment';
import Model from '@/models/Model';
import Round from '@/models/Round';
import Request from '@/library/Request';
import Policy from '@/library/policies/Cup';

export default class Cup extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'cups';
    }

    /**
     * The policy of the model.
     */
    static get policy() {
        return Policy;
    }

    /**
     * Model constructor.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        if (data.rounds) {
            data.rounds = Round.collect(data.rounds);
        }

        data.starting_at = moment(data.starting_at).format('YYYY-MM-DD');

        super(data);

        if (this.rounds) {
            this.rounds.map(round => {
                round.cup = this;

                return round;
            });
        }
    }

    /**
     * Starts the cup.
     */
    start() {
        const identifier = `${this.id}/start`;

        // Use find to execute a GET request and return a single instance of
        // Cup.
        return this.constructor.find(identifier);
    }

    /**
     * Sends reminders to all users that have not registered yet.
     */
    remind() {
        const url = `${this.endpoint}/remind`;

        return (new Request).get(url);
    }

    /**
     * Modifies the players array so it can be submitted with a request.
     *
     * @param {array} players
     */
    static modifyPlayers(players) {
        for (let i in players) {
            let player = players[i];

            if (player.id) {
                players[i] = {id: player.id};
            }
        }

        return players;
    }
}
