import Enum from './Enum';

const FOURBBB = 0;

const SINGLE_SINGLE = 1;

const SINGLE_SINGLE_FOURBBB = 2;

const SINGLE = 3;

export default class TeamMatchFormatType extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            FOURBBB,
            SINGLE_SINGLE,
            SINGLE_SINGLE_FOURBBB,
            SINGLE,
        };
    };

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'team_match_format_type';
    }

    /**
     * Returns the amount of users that each team should provide when playing
     * the given team match type.
     *
     * @param {number} type
     * @returns {number}
     */
    static userPerTeamAmountForType(type) {
        switch (type) {
            case SINGLE:
                return 1;

            case FOURBBB:
            case SINGLE_SINGLE:
                return 2;

            case SINGLE_SINGLE_FOURBBB:
                return 4;
        }
    }
};
