<template>
    <div
        v-if="!loading"
    >
        <layout-default
            v-if="!isWhitelabelHost || !isHome"
        />

        <whitelabel-show
            v-else
            :whitelabel="whitelabel"
        />
    </div>
</template>

<script>
import LayoutDefault from '@/components/layouts/Default';
import Redirect from '@/library/Redirect';
import WhitelabelShow from '@/components/whitelabels/Show';
import {Whitelabels} from '@/models/Whitelabel';
import WhitelabelSingleton from '@/library/Whitelabel';

export default {
    name: 'WhitelabelPortal',
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'WAMP',
        // all titles will be injected into this template
        titleTemplate: '%s | We Are Match Play',
    },
    components: {LayoutDefault, WhitelabelShow},
    data() {
        return {
            knownNonWhitelabelHosts: [
                'app.wearemathcplay.com',
                'staging.app.wearemathcplay.com',
            ],
            loading: true,
            whitelabel: null,
            whitelabels: new Whitelabels,
        };
    },
    computed: {
        currentHost() {
            return window.location.host;
        },
        isHome() {
            return this.$route.name === 'home';
        },
        isKnownNonWhitelabelHost() {
            return this.knownNonWhitelabelHosts.includes(this.currentHost);
        },
        isWhitelabelHost() {
            return !this.isKnownNonWhitelabelHost
                && !!this.whitelabel;
        },
    },
    watch: {
        '$route.name': function() {
            if (this.isHome && !this.isWhitelabelHost) {
                Redirect.to('competitions.index');
            }
        },
    },
    async mounted() {
        if (!this.isKnownNonWhitelabelHost) {
            this.whitelabels.set('domain', this.currentHost);

            await this.whitelabels.fetch();

            if (this.whitelabels.length > 0) {
                this.whitelabel = this.whitelabels.first();

                new WhitelabelSingleton(this.whitelabel);
            }
        }

        if (this.isHome && !this.isWhitelabelHost) {
            await Redirect.to('competitions.index');
        }

        this.loading = false;
    },
};
</script>
