import Enum from './Enum';

// Usually the enum key should be in uppercase, but this is an exception because
// WAMP API use lowercase for the roles.
const admin = 1;

export default class Role extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            admin,
        };
    };

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'role';
    }

    static admin = admin;
};
