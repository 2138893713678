<template>
    <collapse
        :collapsed="collapsed"
        class="cups-rounds-collapse"
        @collapseChanged="collapseChanged"
    >
        <div
            slot="header"
            class="row text-uppercase fs-1 fs-sm-normal"
        >
            <div class="col-5 text-truncate">
                {{ round.name }}
            </div>

            <div class="col-7 text-muted text-right">
                {{ $t('ends') }} {{ round.ending_at | moment('YYYY-MM-DD HH:mm') }}
            </div>
        </div>

        <div slot="body">
            <loader
                v-if="d_loading"
                :loading="true"
                class="w-100 text-center"
            />

            <div v-else>
                <list :matches="d_cupMatches" />
            </div>
        </div>
    </collapse>
</template>

<script>
import Round from '@/models/Round';
import CupMatch from '@/models/CupMatch';
import List from '@/components/cups/matches/List';
import Collapse from '@/components/common/Collapse';

export default {
    name: 'CupsRoundsCollapse',
    components: {Collapse, List},
    props: {
        collapsed: {
            type: Boolean,
            default: true,
        },
        round: {
            type: Round,
            required: true,
        },
    },
    data() {
        return {
            d_cupMatches: [],
            d_loading: false,
        };
    },
    created() {
        this.collapseChanged(this.collapsed);
    },
    methods: {
        collapseChanged(collapsed) {
            if (collapsed === true) return;

            this.d_loading = true;

            const url = `${Round.endpoint}/${this.round.id}/${CupMatch.endpoint}`;

            CupMatch.get(url).then(({data}) => {
                this.d_cupMatches = data.map(cupMatch => {
                    cupMatch.round = this.round;

                    return cupMatch;
                });

                this.d_loading = false;
            });
        },
    },
};
</script>
