import OwRouterView from '@/components/common/OwRouterView';

/**
 * All routes that contain league details, in the perspective of a team user.
 * These routes have black header (see `LeaguesRouterView.vue`).
 *
 * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ IMPORTANT NOTE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *
 * The names of these routes are used by the API to define the navigation items
 * that should be shown per league. Changing them might also require a change
 * in the API.
 *
 * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ IMPORTANT NOTE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 */
export const leagueDetailsRoutes = [
    {
        path: 'news',
        name: 'leagues.news.index',
        component: () => import('@/components/leagues/news/Index'),
        meta: {
            configurable: true,
        },
    },
    {
        path: 'news/:post',
        name: 'leagues.news.show',
        component: () => import('@/components/posts/Show'),
        meta: {
            noNav: true,
        },
    },
    {
        path: 'schedule',
        name: 'leagues.schedule.index',
        component: () => import('@/components/leagues/schedule/Index'),
        meta: {
            configurable: true,
        },
    },
    {
        path: 'leaderboard',
        name: 'leagues.leaderboard',
        component: () => import('@/components/leagues/Leaderboard'),
        meta: {
            configurable: true,
        },
    },
    {
        path: 'players',
        name: 'leagues.players',
        component: () => import('@/components/leagues/Players'),
        meta: {
            configurable: true,
        },
    },
    {
        path: 'my-team',
        name: 'leagues.my-team',
        component: () => import('@/components/leagues/MyTeam'),
        meta: {
            middleware: ['Auth'],
        },
    },
    // TODO: League's all teams page is hidden because nothing is there yet.
    // Will be done in issue https://github.com/owowagency/wamp-ui/issues/68
    // {
    //     path: 'teams',
    //     name: 'leagues.teams.index',
    //     component: () => import('@/components/leagues/teams/Index'),
    // },
];

export const redirectToSchedule = (to, from, next) => {
    if (to.name === 'leagues.routerView') {
        return next({
            name: 'leagues.schedule.index',
            params: {slug: to.params.slug},
        });
    }

    return next();
};

const routes = [
    {
        path: '/leagues',
        component: OwRouterView,
        meta: {
            middleware: ['AuthOrGuest'],
        },
        children: [
            {
                path: '',
                name: 'leagues.index',
                beforeEnter: (to, from, next) => {
                    return next({name: 'home'});
                },
            },
            {
                path: ':slug',
                props: true,
                name: 'leagues.routerView',
                component: () => import('@/components/leagues/LeaguesRouterView'),
                beforeEnter: redirectToSchedule,
                children: [
                    ...leagueDetailsRoutes,
                    {
                        path: 'teams/edit',
                        name: 'leagues.teams.edit',
                        component: () => import('@/components/leagues/teams/Edit'),
                        meta: {
                            middleware: ['Auth'],
                        },
                    },
                    {
                        path: 'teams/:teamSlug',
                        props: true,
                        name: 'leagues.teams.show',
                        component: () => import('@/components/leagues/teams/Show'),
                        meta: {
                            middleware: ['Auth'],
                            showLeagueHeader: true,
                        },
                    },
                    {
                        path: 'schedule/edit',
                        name: 'leagues.schedule.edit',
                        component: () => import('@/components/leagues/schedule/Edit'),
                        meta: {
                            middleware: ['Auth'],
                        },
                    },
                ],
            },
        ],
    },
];

export default routes;
