import vueI18n from '@/lang/vueI18n';

const directive = {
    inserted: (el) => {
        const text = el.innerText.toLowerCase().replace(/\s/g, '');

        el.innerText = vueI18n.t(text);
    },
};

export default directive;
