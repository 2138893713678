import common from '@/lang/en/common';
import enums from '@/lang/en/enums';
import home from '@/lang/en/home';
import leagues from '@/lang/en/leagues';
import main from '@/lang/en/main';
import posts from '@/lang/en/posts';
import teams from '@/lang/en/teams';
import users from '@/lang/en/users';
import whitelabels from '@/lang/en/whitelabels';

export default {
    common,
    enums,
    home,
    leagues,
    ...main,
    posts,
    teams,
    users,
    whitelabels,
};
