// Docs: https://bootstrap-vue.js.org/docs/misc/settings/#configuring-defaults

import Vue from 'vue';
import {
    BVConfigPlugin,
    AlertPlugin,
    BadgePlugin,
    ButtonPlugin,
    ButtonGroupPlugin,
    CardPlugin,
    CollapsePlugin,
    DropdownPlugin,
    FormPlugin,
    FormCheckboxPlugin,
    FormFilePlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    FormTextareaPlugin,
    ImagePlugin,
    InputGroupPlugin,
    LayoutPlugin,
    LinkPlugin,
    ListGroupPlugin,
    ModalPlugin,
    NavPlugin,
    NavbarPlugin,
    PaginationPlugin,
    PopoverPlugin,
    ProgressPlugin,
    SpinnerPlugin,
    TablePlugin,
    TooltipPlugin,
    VBScrollspyPlugin,
} from 'bootstrap-vue';

export default {
    init() {
        // Supply complete config to the BVConfigPlugin helper plugin.
        Vue.use(BVConfigPlugin, {
            BButton: {variant: 'primary'},
        });

        // Then use component plugins.
        Vue.use(AlertPlugin);
        Vue.use(BadgePlugin);
        Vue.use(ButtonPlugin);
        Vue.use(ButtonGroupPlugin);
        Vue.use(CardPlugin);
        Vue.use(CollapsePlugin);
        Vue.use(DropdownPlugin);
        Vue.use(FormPlugin);
        Vue.use(FormCheckboxPlugin);
        Vue.use(FormFilePlugin);
        Vue.use(FormGroupPlugin);
        Vue.use(FormInputPlugin);
        Vue.use(FormRadioPlugin);
        Vue.use(FormSelectPlugin);
        Vue.use(FormTextareaPlugin);
        Vue.use(ImagePlugin);
        Vue.use(InputGroupPlugin);
        Vue.use(LayoutPlugin);
        Vue.use(LinkPlugin);
        Vue.use(ListGroupPlugin);
        Vue.use(ModalPlugin);
        Vue.use(NavPlugin);
        Vue.use(NavbarPlugin);
        Vue.use(PaginationPlugin);
        Vue.use(PopoverPlugin);
        Vue.use(ProgressPlugin);
        Vue.use(SpinnerPlugin);
        Vue.use(TablePlugin);
        Vue.use(TooltipPlugin);
        Vue.use(VBScrollspyPlugin);
    },
};
