<template>
    <div v-if="whitelabel">
        <div>
            <div
                class="d-flex flex-column min-h-80vh bg-cover bg-overlay overlay-harder"
                :style="'background-image: url(' + _.get(whitelabel, 'header_cover.original') + ')'"
            >
                <leagues-nav
                    v-if="leagues"
                    :leagues="leagues"
                />

                <b-container class="d-flex flex-grow-1 text-white">
                    <b-row
                        class="py-3 py-sm-6 w-100"
                        align-v="center"
                    >
                        <b-col
                            sm="10"
                            md="7"
                            lg="5"
                            xl="4"
                        >
                            <h1 class="mb-4">
                                {{ _.get(whitelabel, 'content.header_title') }}
                            </h1>

                            <p class="mb-0 text-pre-line">
                                {{ _.get(whitelabel, 'content.header_text') }}
                            </p>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>

        <b-container class="py-3">
            <b-row
                align-h="center"
                align-v="center"
                class="py-4 py-sm-6"
            >
                <b-col
                    class="mb-4 mb-sm-0"
                    sm="5"
                    lg="5"
                    xl="4"
                >
                    <ow-img
                        class="w-100 rounded-lg"
                        :src="whitelabel"
                        src-key="info_image.original"
                    />
                </b-col>

                <b-col
                    offset-lg="1"
                    sm="7"
                    lg="5"
                    xl="4"
                >
                    <h4 class="mb-4">
                        {{ _.get(whitelabel, 'content.info_title') }}
                    </h4>

                    <div class="mb-4 text-muted text-pre-line">
                        {{ _.get(whitelabel, 'content.info_text') }}
                    </div>
                </b-col>
            </b-row>

            <div
                v-if="locationPosts"
                :class="{'py-4 py-sm-6': !!locationPosts.models.length}"
            >
                <h2
                    v-if="!!locationPosts.models.length"
                    class="mb-3 mb-sm-5"
                >
                    {{ $t('whitelabels.show.locations.title') }}
                </h2>

                <posts-card-deck
                    no-posts-text=""
                    :posts="locationPosts"
                    @postClick="postClick"
                />
            </div>

            <div
                v-if="newsPosts"
                :class="{'py-4 py-sm-6': !!newsPosts.models.length}"
            >
                <h2
                    v-if="!!newsPosts.models.length"
                    class="mb-3 mb-sm-5"
                >
                    {{ $t('whitelabels.show.news.title') }}
                </h2>

                <posts-card-deck
                    no-posts-text=""
                    :posts="newsPosts"
                    @postClick="postClick"
                />
            </div>

            <div
                class="my-4 my-sm-6 py-5 py-sm-6 px-4 py-sm-0 bg-cover bg-overlay overlay-harder rounded-lg overflow-hidden text-white"
                :style="'background-image: url(' + _.get(whitelabel, 'leagues_image.original') + ')'"
            >
                <b-row align-v="center">
                    <b-col
                        class="mb-5 mb-md-0"
                        cols="12"
                        sm="10"
                        offset-sm="1"
                        md="6"
                        lg="5"
                        offset-lg="2"
                    >
                        <h4 class="mb-4">
                            {{ _.get(whitelabel, 'content.leagues_title') }}
                        </h4>

                        <p class="mb-0">
                            {{ _.get(whitelabel, 'content.leagues_text') }}
                        </p>
                    </b-col>

                    <b-col
                        v-if="leagues"
                        class="text-center text-md-right"
                        cols="12"
                        md="4"
                        lg="3"
                    >
                        <div
                            v-for="(league, index) of leagues.models"
                            :key="league.id"
                            :class="{'mt-2': index > 0}"
                        >
                            <b-button
                                v-if="!league.get('data.ui.registration_link')"
                                block
                                :to="{name: 'leagues.routerView', params: {slug: league.slug}}"
                                variant="outline-white"
                            >
                                {{ league.name }}
                            </b-button>

                            <b-button
                                v-else
                                block
                                :href="league.get('data.ui.registration_link')"
                                rel="noopener noreferrer"
                                target="_blank"
                                variant="outline-white"
                            >
                                {{ league.name }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <div
                v-if="winnerPosts"
                :class="{'py-4 py-sm-6': !!winnerPosts.models.length}"
            >
                <h2
                    v-if="!!winnerPosts.models.length"
                    class="mb-3 mb-sm-5"
                >
                    {{ $t('whitelabels.show.winners.title') }}
                </h2>

                <posts-card-deck
                    no-posts-text=""
                    :posts="winnerPosts"
                    @postClick="postClick"
                />
            </div>

            <b-row
                align-h="center"
                align-v="center"
                class="py-4 py-sm-6"
            >
                <b-col
                    class="mb-3 mb-sm-0"
                    sm="5"
                    lg="5"
                    xl="4"
                >
                    <img
                        class="w-100"
                        src="@/assets/images/wamp-phone-3d.png"
                    >
                </b-col>

                <b-col
                    offset-lg="1"
                    sm="7"
                    lg="5"
                    xl="4"
                >
                    <h4 class="mb-4">
                        {{ $t('whitelabels.show.wamp.title') }}
                    </h4>

                    <p class="mb-4 text-muted">
                        {{ $t('whitelabels.show.wamp.text') }}
                    </p>

                    <div class="d-flex justify-content-center justify-content-sm-start">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.wearmatchplay.wamp.wamp"
                            rel="noopener"
                            target="_blank"
                        >
                            <img src="@/assets/images/play_store_dark.svg">
                        </a>

                        <a
                            class="ml-3"
                            href="https://itunes.apple.com/nl/app/we-are-match-play-wamp/id1343935072"
                            rel="noopener"
                            target="_blank"
                        >
                            <img src="@/assets/images/app_store_dark.svg">
                        </a>
                    </div>
                </b-col>
            </b-row>

            <sponsors
                v-if="!!whitelabel.sponsors.length"
                class="py-3 py-sm-6"
                :sponsors="whitelabel.sponsors"
            />
        </b-container>

        <div class="py-7 bg-dark text-white">
            <b-container>
                <b-row>
                    <b-col>
                        <ul
                            v-if="firstLeagueRouteParams"
                            class="list-unstyled mb-0"
                        >
                            <li>
                                <router-link
                                    class="text-muted"
                                    :to="{name: 'leagues.news.index', params: firstLeagueRouteParams}"
                                >
                                    {{ $t('whitelabels.leaguesNav.news') }}
                                </router-link>
                            </li>

                            <li>
                                <router-link
                                    class="text-muted"
                                    :to="{name: 'leagues.schedule.index', params: firstLeagueRouteParams}"
                                >
                                    {{ $t('whitelabels.leaguesNav.schedule') }}
                                </router-link>
                            </li>

                            <li>
                                <router-link
                                    class="text-muted"
                                    :to="{name: 'leagues.leaderboard', params: firstLeagueRouteParams}"
                                >
                                    {{ $t('whitelabels.leaguesNav.clubRanking') }}
                                </router-link>
                            </li>

                            <li>
                                <router-link
                                    class="text-muted"
                                    :to="{name: 'leagues.players', params: firstLeagueRouteParams}"
                                >
                                    {{ $t('whitelabels.leaguesNav.playerRanking') }}
                                </router-link>
                            </li>

                            <li
                                v-if="whitelabel.domain === 'pepssgolf.be'"
                            >
                                <a
                                    class="text-muted"
                                    href="https://api.wearematchplay.com/files/pepss_general_rules_2024_bxl.pdf"
                                    target="_blank"
                                >
                                    {{ $t('whitelabels.leaguesNav.rules') }}
                                </a>
                            </li>
                        </ul>
                    </b-col>

                    <b-col class="text-right">
                        <div class="mb-5">
                            <b-button
                                :to="{name: 'competitions.index'}"
                                variant="white"
                            >
                                {{ $t('whitelabels.show.footer.login') }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

                <div class="mt-6 small text-muted">
                    © {{ new Date | moment('YYYY') }} {{ whitelabel.title }}
                </div>
            </b-container>
        </div>
    </div>
</template>

<script>
import {Leagues} from '@/models/League';
import LeaguesNav from '@/components/whitelabels/LeaguesNav';
import {Posts} from '@/models/Post';
import PostsCardDeck from '@/components/posts/CardDeck';
import PostType from '@/library/enums/PostType';
import Redirect from '@/library/Redirect';
import Sponsors from '@/components/whitelabels/Sponsors';
import {Whitelabel} from '@/models/Whitelabel';

export default {
    name: 'WhitelabelShow',
    components: {LeaguesNav, PostsCardDeck, Sponsors},
    props: {
        whitelabel: {
            required: true,
            type: Whitelabel,
        },
    },
    data() {
        return {
            leagues: this.whitelabel.leagues,
            locationPosts: null,
            newsPosts: null,
            winnerPosts: null,
        };
    },
    computed: {
        firstLeague() {
            return this.leagues ? this.leagues.first() : null;
        },
        firstLeagueRouteParams() {
            return this.firstLeague
                ? {slug: this.leagues.first().slug}
                : null;
        },
        headerCover() {
            return this._.get(this.whitelabel, 'header_cover.original');
        },
        routePrefix() {
            return `whitelabels/${this.whitelabel.id}`;
        },
    },
    mounted() {
        this.fetch();
    },
    metaInfo() {
        return {
            title: 'Home',
            titleTemplate: `%s | ${this.whitelabel.title}`,
        };
    },
    methods: {
        async fetch() {
            if (!this.leagues) {
                this.fetchLeagues();
            }

            this.setLocationPosts();

            this.setNewsPosts();

            this.setWinnerPosts();
        },
        async fetchLeagues() {
            this.leagues = new Leagues(
                [],
                {routePrefix: this.routePrefix},
            );

            await this.leagues.fetch();

            this.whitelabel.leagues = this.leagues;
        },
        postClick(post) {
            Redirect.to({
                name: 'leagues.news.show',
                params: {post: post.slug, slug: post.leagues[0].slug},
            });
        },
        async setLocationPosts() {
            this.locationPosts = new Posts(
                [],
                {routePrefix: this.routePrefix},
                {type: PostType.LOCATION, perPage: 6},
            );
        },
        async setNewsPosts() {
            this.newsPosts = new Posts(
                [],
                {routePrefix: this.routePrefix},
                {type: PostType.NEWS, perPage: 6},
            );
        },
        async setWinnerPosts() {
            this.winnerPosts = new Posts(
                [],
                {routePrefix: this.routePrefix},
                {type: PostType.PAST_WINNER, perPage: 6},
            );
        },
    },
};
</script>
