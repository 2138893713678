<template>
    <b-card>
        <b-card-title>
            Progress
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for using Bootstrap custom progress bars featuring support for stacked bars, animated backgrounds, and text labels.
        </p>

        <div class="mb-4">
            <h5>
                Example
            </h5>

            <p>
                Progress components are built with two HTML elements, some CSS to set the width, and a few attributes. We don’t use <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/progress">the HTML5 <code class="highlighter-rouge">&lt;progress&gt;</code> element</a>, ensuring you can stack progress bars, animate them, and place text labels over them.
            </p>

            <preview>
                <b-progress
                    v-for="(progress, index) in 5"
                    :key="index"
                    class="mb-1"
                    :value="index * 25"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Labels
            </h5>

            <p>
                Add labels to your progress bars by placing text within the <code class="highlighter-rouge">.progress-bar</code>.
            </p>

            <preview>
                <b-progress
                    v-for="(progress, index) in 5"
                    :key="index"
                    class="mb-1"
                    :value="index * 25"
                    show-value
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Backgrounds
            </h5>

            <p>
                Use background utility classes to change the appearance of individual progress bars.
            </p>

            <preview>
                <b-progress
                    v-for="color in themeColors"
                    :key="color"
                    class="mb-1"
                    :value="50"
                    :variant="color"
                />
            </preview>
        </div>

        <div>
            <h5>
                Animated stripes
            </h5>

            <p>
                The striped gradient can also be animated. Add <code class="highlighter-rouge">.progress-bar-animated</code> to <code class="highlighter-rouge">.progress-bar</code> to animate the stripes right to left via CSS3 animations.
            </p>

            <preview>
                <b-progress
                    v-for="color in themeColors"
                    :key="color"
                    animated
                    class="mb-1"
                    striped
                    :value="50"
                    :variant="color"
                />
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Progress',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
};
</script>
