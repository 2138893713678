<template>
    <input
        v-model="d_input"
        :placeholder="`${$t('search')}`"
        class="form-control"
        type="search"
    >
</template>

<script>
export default {
    name: 'FormSearch',
    props: {
        eventDelay: {
            type: Number,
            default: 400,
        },
        input: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            d_eventTimeout: undefined,
            d_input: this.input,
        };
    },
    watch: {
        d_input() {
            clearTimeout(this.d_eventTimeout);

            this.d_eventTimeout = setTimeout(() => {
                this.$emit('setSearch', this.d_input.trim());

                this.d_eventTimeout = undefined;
            }, this.eventDelay);
        },
    },
};
</script>
