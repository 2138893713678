<template>
    <div v-if="d_data">
        <div class="row text-fields">
            <form-group
                v-model="d_data.name"
                :disabled="disabled"
                :error="errors.first('name')"
                :label="$t('courses.form.labels.name')"
                :placeholder="$t('courses.form.placeholders.name')"
                class="col-sm-6 col-lg-3 mb-4 pb-3"
            />

            <form-group
                v-model="d_data.city"
                :disabled="disabled"
                :error="errors.first('city')"
                :label="$t('courses.form.labels.city')"
                :placeholder="$t('courses.form.placeholders.city')"
                class="col-sm-6 col-lg-3 mb-4 pb-3"
            />

            <form-group
                v-model="d_data.country"
                :disabled="disabled"
                :error="errors.first('country')"
                :label="$t('courses.form.labels.country')"
                :placeholder="$t('courses.form.placeholders.country')"
                class="col-sm-6 col-lg-3 mb-4 pb-3"
            />

            <form-group
                v-model="d_data.lat"
                :disabled="disabled"
                :error="errors.first('lat')"
                :label="$t('courses.form.labels.lat')"
                :placeholder="$t('courses.form.placeholders.lat')"
                class="col-sm-6 col-lg-3 mb-4 pb-3"
            />

            <form-group
                v-model="d_data.lng"
                :disabled="disabled"
                :error="errors.first('lng')"
                :label="$t('courses.form.labels.lng')"
                :placeholder="$t('courses.form.placeholders.lng')"
                class="col-sm-6 col-lg-3 mb-4 pb-3"
            />
        </div>

        <div class="row">
            <div class="col-md-12">
                <courses-tees-table
                    v-model="d_data.tees"
                    :disabled="disabled"
                    :errors="errors.getAsErrors('tees')"
                    :hole-start="0"
                    :title="$t('courses.front_nine')"
                />

                <courses-tees-table
                    v-if="d_data.backNine"
                    v-model="d_data.tees"
                    :disabled="disabled"
                    :errors="errors.getAsErrors('tees')"
                    :hole-start="9"
                    :title="$t('courses.back_nine')"
                />

                <div v-if="!disabled">
                    <div
                        class="d-inline-block cursor-pointer text-primary mr-5"
                        @click="addTee"
                    >
                        <btn class="btn-primary btn-circle btn-sm mr-3">
                            <i class="fa fa-plus" />
                        </btn>

                        <span class="text-uppercase">
                            {{ $t('courses.action.add_tee') }}
                        </span>
                    </div>

                    <div
                        :class="[ d_data.backNine ? 'text-danger' : 'text-primary' ]"
                        class="d-inline-block cursor-pointer"
                        @click="toggleBackNine"
                    >
                        <btn
                            :class="[ d_data.backNine ? 'btn-danger' : 'btn-primary' ]"
                            class="btn-circle btn-sm mr-3"
                        >
                            <i
                                :class="[ d_data.backNine ? 'fa-times' : 'fa-plus' ]"
                                class="fa"
                            />
                        </btn>

                        <span class="text-uppercase">
                            {{ d_data.backNine ? $t('disable') : $t('enable') }} {{ $t('courses.back_nine') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formProps from '@/library/mixins/formProps';
import FormGroup from '@/components/common/form/Group';
import confirmFormDiscard from '@/mixins/confirmFormDiscard';
import CoursesTeesTable from '@/components/courses/tees/Table';

export default {
    name: 'CoursesForm',
    components: {CoursesTeesTable, FormGroup},
    mixins: [confirmFormDiscard, formProps],
    props: {
        value: {
            type: Object,
            default() {
                return {
                    backNine: false,
                    tees: [],
                };
            }},
    },
    data() {
        return {
            d_data: this.value,
        };
    },
    watch: {
        'd_data.tees': {
            handler() {
                this.addKeys();
            },
            deep: true,
        },
        d_data: {
            handler() {
                this.$emit('input', this.d_data);
            },
            deep: true,
        },
        value() {
            this.d_data = this.value;
        },
    },
    created() {
        let data = Object.assign({}, this.d_data);

        this.d_data.backNine = data.tees.length && data.tees[0].holes.length > 9;

        if (!data.tees.length) {
            data.tees = [this.createEmptyTee()];
        } else {
            data.tees = this.addMissingHoles(data.tees);
        }

        this.d_data = data;

        this.addKeys();
    },
    methods: {
        addKeys() {
            let i = 0;
            for (let tee of this.d_data.tees) {
                tee.key = i;
                i++;
            }
        },
        addMissingHoles(tees) {
            for (let tee of tees) {
                for (let i = 0; i < 18; i++) {
                    if (!tee.holes[i]) {
                        this.$set(tee.holes, [i], {});
                    }
                }
            }
            return tees;
        },
        addTee() {
            const newTee = this.createEmptyTee();

            this.d_data.tees.push(this.copyLastTee(newTee));
        },
        createEmptyTee() {
            let tee = {holes: []};

            for (let i = 0; i < 18; i++) {
                tee.holes.push({});
            }

            return tee;
        },
        copyLastTee(tee) {
            // Copy Par and SI hole values from previous tee
            if (this.d_data && this.d_data.tees.length) {
                const length = this.d_data.tees.length;

                let previousTee = this.d_data.tees[length - 1];
                let holeIndex = 0;

                for (let previousTeeHole of previousTee.holes) {
                    if (previousTeeHole.par) {
                        tee.holes[holeIndex].par = previousTeeHole.par;
                        tee.holes[holeIndex].stroke_index = previousTeeHole.stroke_index;
                    }
                    holeIndex++;
                }
            }

            return tee;
        },
        toggleBackNine() {
            this.d_data.backNine = !this.d_data.backNine;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 992px){
    .text-fields .col-lg-3{
        flex: 0 0 20%;
        max-width: 20%;
    }
}
</style>
