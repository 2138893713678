<template>
    <modal
        :modal="d_modal"
        class="cup-users-details-modal"
    >
        <form
            slot="modal-body"
            @submit.prevent="submit"
        >
            <form-group
                v-model="d_modal.options.data.email"
                :autofocus="true"
                :form-text="$t('cup_users.email_modal.email.explanation')"
                :label="$t('email')"
                :placeholder="$t('email')"
                :required="true"
                type="email"
            />

            <form-group
                v-model="d_modal.options.data.first_name"
                :label="$t('first_name')"
                :placeholder="$t('first_name')"
                :required="true"
                type="text"
            />

            <form-group
                v-model="d_modal.options.data.last_name"
                :label="$t('last_name')"
                :placeholder="$t('last_name')"
                :required="true"
                class="mb-0"
                type="text"
            />

            <button
                ref="formButton"
                class="d-none"
            />
        </form>

        <template slot="modal-footer">
            <btn
                class="btn-light"
                element="div"
                @click="d_modal.close()"
            >
                {{ $t('form.cancel') }}
            </btn>

            <btn
                class="btn-primary"
                element="div"
                @click="$refs.formButton.click()"
            >
                {{ $t('form.submit') }}
            </btn>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import FormGroup from '@/components/common/form/Group';

export default {
    name: 'CupUsersDetailsModal',
    components: {FormGroup, Modal},
    data() {
        return {
            d_modal: undefined,
        };
    },
    methods: {
        submit() {
            this.d_modal.resolve(this.d_modal.options.data);
        },
    },
};
</script>
