<template>
    <tr class="cups-table-row cursor-pointer">
        <td
            v-if="columns.includes('name')"
            class="font-weight-bold text-primary"
        >
            {{ row.name }}
        </td>

        <td
            v-if="columns.includes('course')"
            class="w-auto"
        >
            {{ row.course.name }}
        </td>

        <td
            v-if="columns.includes('status')"
            class="w-auto"
        >
            {{ row.status }}
        </td>

        <td
            v-if="columns.includes('starting_at')"
            class="w-auto"
        >
            {{ formatDate(row.starting_at) }}
        </td>

        <td
            v-if="columns.includes('ending_at')"
            class="w-auto"
        >
            {{ formatDate(row.ending_at) }}
        </td>

        <td
            v-if="columns.includes('player_count')"
            class="w-auto"
        >
            {{ row.players_count }}
        </td>
    </tr>
</template>

<script>
import moment from 'moment';
import Cup from '@/models/Cup';
import TableRow from '@/components/common/TableRow';

export default {
    name: 'CupsTableRow',
    extends: TableRow,
    props: {
        row: {
            type: Cup,
            required: true,
        },
    },
    methods: {
        formatDate(date) {
            if (!date) {
                return this.$t('tba');
            }

            return moment(date).format('DD-MM-YYYY');
        },
    },
};
</script>
