import Cup from '@/models/Cup';
import Edit from '@/components/cups/Edit';
import Show from '@/components/cups/Show';
import Index from '@/components/cups/Index';
import Create from '@/components/cups/Create';
import OwRouterView from '@/components/common/OwRouterView';

let routes = [
    {
        path: '/cups',
        component: OwRouterView,
        meta: {
            middleware: ['Auth'],
        },
        children: [
            {
                path: '',
                name: 'cups.index',
                component: Index,
            },
            {
                path: 'create',
                name: 'cups.create',
                component: Create,
                meta: {
                    middleware: ['Policy'],
                    action: 'store',
                    model: Cup,
                },
            },
            {
                path: ':id',
                name: 'cups.show',
                component: Show,
            },
            {
                path: ':id/edit',
                name: 'cups.edit',
                component: Edit,
                meta: {
                    middleware: ['Policy'],
                    action: 'update',
                    model: Cup,
                },
            },
        ],
    },
];

export default routes;
