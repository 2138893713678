import Model from '@/models/Model';
import Policy from '@/library/policies/Course';

export default class Course extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'courses';
    }

    /**
     * The url queries of the model.
     */
    static get urlQueries() {
        return {
            orderBy: 'updated_at',
            sortedBy: 'desc',
        };
    }

    /**
     * The search fields of the model to filter on.
     */
    static get searchFields() {
        return ['name', 'city', 'country', 'state'];
    }

    /**
     * The policy of the model.
     */
    static get policy() {
        return Policy;
    }

    /**
     * Modifies the tees array so it can be submitted with a request.
     *
     * @param {array} tees
     * @param {object} data
     */
    static modifyHoles(tees, data) {
        for (let tee of tees) {
            // Do not submit back nine when not enabled.
            if (!data.backNine) {
                tee.holes.splice(9, 9);
            }

            // Filter out empty holes.
            tee.holes = tee.holes.filter((hole) => {
                return (hole.tee !== undefined && hole.tee !== '')
                    && (hole.par !== undefined && hole.par !== '')
                    && (hole.stroke_index !== undefined && hole.stroke_index !== '');
            });
        }

        return tees;
    }
}
