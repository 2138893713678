import Model from '@/models/Model';

export default class CupUser extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'cup-users';
    }
}
