<template>
    <component
        :is="element"
        :class="d_classes"
        class="loader"
    >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        element: {
            type: String,
            default: 'span',
        },
        loading: Boolean,
        error: Boolean,
    },
    data() {
        return {
            d_classes: {
                loading: this.loading,
                error: this.error,
            },
        };
    },
    watch: {
        loading() {
            this.d_classes.loading = this.loading;
        },
        error() {
            this.d_classes.error = this.error;
        },
    },
};
</script>
