import {Collection, Model} from './vue-mc/Model';

export class Whitelabel extends Model {
    /**
     * Default attributes that define the "empty" state.
     */
    defaults() {
        return {
            id: null,
            title: null,
            domain: null,
            type: null,
            leagues: null,
        };
    }

    /**
     * Options of this model.
     */
    options() {
        return {
            endpoint: 'whitelabels',
        };
    }
}

export class Whitelabels extends Collection {
    /**
     * Default attributes that define the "empty" state.
     */
    defaults() {
        return {
            domain: null,
        };
    }

    /**
     * Options of this collection.
     */
    options() {
        return {
            model: Whitelabel,
        };
    }

    /**
     * @returns {Object} Query parameters that will be appended to the `fetch` URL.
     */
    getFetchQuery() {
        let query = super.getFetchQuery();

        if (this.get('domain') !== undefined) {
            query.domain = this.get('domain');
        }

        return query;
    }

};
