export default {
    // Common
    leaguesNav: {
        clubRanking: 'Club ranking',
        news: 'News',
        playerRanking: 'Player ranking',
        schedule: 'Schedule',
        rules: 'Rules',
    },

    // Routes
    show: {
        cta: {
            title: 'Follow the competition 💪',
            text: 'Schedule, Results, Statistics, Teams, Players, ...',
        },
        footer: {
            login: 'Captain login',
        },
        locations: {
            title: 'Locations',
        },
        news: {
            title: 'Top stories',
        },
        wamp: {
            title: 'The league is fully digital',
            text: 'Our players are connected people and digitalization is part of the event. Thanks to our partnership with WAMP, all the league is fully managed through the app with a specific universe for players and captains.',
        },
        winners: {
            title: 'Past winners',
        },
    },
};
