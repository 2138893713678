import Model from '@/models/Model';
import helpers from '@/library/helpers';
import Policy from '@/library/policies/Policy';

class CupMatch extends Policy {
    /**
     * Can update.
     *
     * @param {User} user
     * @param {Model} model
     */
    static update(user, model) {
        if (user.hasRole('admin')) return true;

        const cup = helpers.dataGet(model, 'round.cup');

        if (!cup) return false;

        // A user can edit a cup match if he is a club manager AND the created of the cup belonging to the cup match
        return user.hasRole('club_manager') && (cup instanceof Model ? user.id === cup.creator_id : true);

    }
}

export default CupMatch;
