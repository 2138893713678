/* eslint-disable no-param-reassign */

import helpers from '@/library/helpers';
import Request from '@/library/Request';
import Policy from '@/library/policies/Policy';

export default class Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        throw new Error('Model endpoint is not yet defined.');
    }

    /**
     * The api endpoint of the instanciated model.
     */
    get endpoint() {
        return `${this.constructor.endpoint}/${this[this.constructor.identifier]}`;
    }

    /**
     * The api url with queries of the model.
     */
    static getEndpoint(urlQueries = {}) {
        urlQueries = Object.assign(this.urlQueries, urlQueries);

        return helpers.buildUrl(this.endpoint, urlQueries);
    }

    static getSearchFieldUrlQueries(search = '') {
        if (search) {
            return {
                searchFields: this.getSearchFields(),
                search,
            };
        }

        return {};
    }

    /**
     * The url queries of the model.
     */
    static get urlQueries() {
        return {};
    }

    /**
     * The search fields of the model to filter on.
     */
    static get searchFields() {
        return [];
    }

    /**
     * The identifier of the model.
     */
    static get identifier() {
        return 'id';
    }

    /**
     * The policy of the model.
     */
    static get policy() {
        return Policy;
    }

    /**
     * Model constructor.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        this.fill(data);
    }

    /**
     * Clones current instance into a new one.
     */
    clone() {
        const clone = Object.assign({}, this);

        return new this.constructor(clone);
    }

    /**
     * Fills the model with properties passed through data.
     *
     * @param {Object} data
     */
    fill(data = {}) {
        Object.assign(this, data);
    }

    /**
     * Gets resources from models endpoint.
     *
     * @param {String} url
     */
    static get(url = undefined) {
        if (url === undefined) {
            url = this.endpoint;
        }

        return new Promise((resolve, reject) => {
            this.makeRequest('get', [url]).then((data) => {
                data.data = this.collect(data.data);

                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Paginates resources from models endpoint.
     *
     * @param {Integer} page
     * @param {String} url
     */
    static paginate(page = 1, url = undefined) {
        if (url === undefined) {
            url = this.endpoint;
        }

        let [base, queryString] = url.split('?');

        let queryStrings = [];

        if (queryString) {
            queryStrings = queryString.split('&');
        }

        queryStrings.push(`page=${page}`);

        url = `${base}?${queryStrings.join('&')}`;

        return new Promise((resolve, reject) => {
            this.makeRequest('get', [url]).then((data) => {
                data.data = this.collect(data.data.data);

                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Gets single resource from models endpoint.
     *
     * @param {String} identifier
     * @param {String} endpoint
     */
    static find(identifier, endpoint = '') {
        if (endpoint === '') {
            endpoint = this.endpoint;
        }

        let url = `${endpoint}/${identifier}`;

        return new Promise((resolve, reject) => {
            this.makeRequest('get', [url]).then((data) => {
                data.data = new this(data.data);

                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Posts single resource to models endpoint.
     */
    create(url = undefined) {
        return this.constructor.create(url, this);
    }

    /**
     * Posts single resource to models endpoint.
     */
    static create(url = undefined, data) {
        if (url === undefined) {
            url = this.endpoint;
        }

        return new Promise((resolve, reject) => {
            this.makeRequest('post', [url, data]).then((data) => {
                data.data = new this(data.data);
                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Puts single resource to models endpoint.
     */
    update(url = undefined) {
        if (url === undefined) {
            url = this.constructor.endpoint;
        }

        url += `/${this[this.constructor.identifier]}`;

        return new Promise((resolve, reject) => {
            this.constructor.makeRequest('put', [url, this]).then((data) => {
                this.fill(data.data);

                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Deletes the resource from models endpoint.
     *
     * @param {String} url
     */
    delete(url = undefined) {
        if (url === undefined) {
            url = this.constructor.endpoint;
        }

        url += `/${this[this.constructor.identifier]}`;

        return this.constructor.makeRequest('delete', [url]);
    }

    /**
     * Makes a request.
     *
     * @param {Array} parameters
     */
    static makeRequest(type, parameters) {
        return new Request()[type](...parameters);
    }

    /**
     * Collects an array with values into an array with models.
     *
     * @param {Array} values
     */
    static collect(values) {
        if (!values) {
            values = [];
        }

        return values.map((value) => {
            return new this(value);
        });
    }

    static getSearchFields() {
        let fields = [];
        let searchFields = this.searchFields;

        // Checks whether the search fields is a string, an array or an object with defined filter type
        if (typeof searchFields === 'string') {
            fields.push(`${searchFields}:like`);
        } else if (searchFields instanceof Array) {
            for (let field of searchFields) {
                fields.push(`${field}:like`);
            }
        } else {
            for (let field in searchFields) {
                if (Object.prototype.hasOwnProperty.call(searchFields, field)) {
                    fields.push(`${field}:${searchFields[field]}`);
                }
            }
        }

        return fields.join(';');
    }
}
