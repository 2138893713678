<template>
    <div class="courses-create position-relative">
        <div class="top-edge clearfix">
            <router-link
                :to="{name: 'courses.index'}"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>

            <div class="header-button float-right">
                <btn
                    :loading="d_form.loading"
                    class="btn btn-primary"
                    @click="submit"
                >
                    {{ $t('courses.action.save') }}
                </btn>
            </div>
        </div>

        <div class="row justify-content-center mb-4">
            <div class="col-md-12">
                <courses-form
                    v-model="d_form.data"
                    :errors="d_form.errors"
                    :loading="d_form.loading"
                    @cfd_change="cfd_onChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Form from '@/library/Form';
import Course from '@/models/Course';
import Redirect from '@/library/Redirect';
import CoursesForm from '@/components/courses/Form';
import confirmFormDiscard from '@/mixins/confirmFormDiscard';

export default {
    name: 'CoursesCreate',
    components: {CoursesForm},
    mixins: [confirmFormDiscard],
    data() {
        return {
            d_form: new Form({
                backNine: false,
                tees: [],
            }),
        };
    },
    created() {
        this.d_form.addModifier('tees', Course.modifyHoles);
    },
    methods: {
        submit() {
            this.d_form.post(Course.endpoint).then(() => {
                this.cfd_dataChanged = false;

                Redirect.to({name: 'courses.index'});
            });
        },
    },
};
</script>
