<template>
    <div class="cups-index position-relative">
        <portal
            to="layout-default-header"
            name="layout-default-header-black"
        >
            <b-row
                align-v="end"
                class="pt-5"
            >
                <b-col class="mb-3 mb-sm-0">
                    <h1 class="mb-0">
                        {{ $t('cups.index.title') }}
                    </h1>
                </b-col>

                <b-col
                    v-if="d_me.can('store', Cup)"
                    class="text-right"
                >
                    <router-link
                        :to="{name: 'cups.create'}"
                        class="btn btn-min-w btn-primary float-right"
                    >
                        {{ $t('cups.new') }}
                    </router-link>
                </b-col>
            </b-row>
        </portal>

        <div class="table-responsive">
            <cups-table
                :loading="pos_loading"
                :rows="pos_rows"
                class="table-hover"
                @onRowClick="onRowClick"
            />
        </div>
    </div>
</template>

<script>
import Me from '@/library/Me';
import Cup from '@/models/Cup';
import Redirect from '@/library/Redirect';
import CupsTable from '@/components/cups/Table';
import paginatesOnScroll from '@/library/mixins/paginatesOnScroll';

export default {
    name: 'CupsIndex',
    components: {CupsTable},
    mixins: [paginatesOnScroll],
    data() {
        return {
            Cup: Cup,
            d_me: new Me,
            pos_url: Cup.endpoint,
        };
    },
    methods: {
        onRowClick(data) {
            Redirect.to({name: 'cups.show', params: {id: data.row.id}});
        },
        pos_success({data}) {
            const rows = Cup.collect(data.data);

            this.pos_rows = [...this.pos_rows, ...rows];
        },
    },
};
</script>
