import { render, staticRenderFns } from "./LeaguesNav.vue?vue&type=template&id=27fd6d48&scoped=true&"
import script from "./LeaguesNav.vue?vue&type=script&lang=js&"
export * from "./LeaguesNav.vue?vue&type=script&lang=js&"
import style0 from "./LeaguesNav.vue?vue&type=style&index=0&id=27fd6d48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27fd6d48",
  null
  
)

export default component.exports