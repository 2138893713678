import Me from '@/library/Me';
import User from '@/models/User';
import ApiToken from '@/library/ApiToken';
import Middleware from '@/library/Middleware';

export default class Auth extends Middleware {
    /**
     * Auth constructor.
     */
    constructor() {
        super();

        this.name = 'Auth';
    }

    /**
     * Handles the middleware.
     * Try to get the authenticated user. If failed, redirect to login page.
     *
     * @param {Object} to
     * @param {Object} from
     * @param {Function} next
     */
    handle(to, from, next) {
        if (!this.needsToHandle(to)) {
            return next();
        }

        let me = new Me();

        if (!me.id) {
            let apiToken = new ApiToken();

            if (!apiToken.getToken() || apiToken.getToken() === 'undefined') {
                return Me.logout();
            }

            // Try to authenticate the current user to the API.
            return User.find('me')
                .then(({data}) => {
                    me.fill(data);

                    return next();
                }).catch(() => {
                    // Authentication failed, logout.
                    return Me.logout();
                });
        }

        return next();
    }
}
