import Login from '@/components/auth/Login';
import Logout from '@/components/auth/Logout';
import LayoutAuth from '@/components/layouts/Auth';
import Forgot from '@/components/auth/password/Forgot';
import Reset from '@/components/auth/password/Reset';
import OwRouterView from '@/components/common/OwRouterView';

let routes = [
    {
        path: '/auth',
        component: LayoutAuth,
        children: [
            {
                path: 'login',
                name: 'auth.login',
                component: Login,
                meta: {
                    middleware: ['Guest'],
                },
            },
            {
                path: 'password',
                component: OwRouterView,
                children: [
                    {
                        path: 'forgot',
                        name: 'auth.password.forgot',
                        component: Forgot,
                    },
                    {
                        path: 'reset/:token',
                        name: 'auth.password.reset',
                        component: Reset,
                    },
                ],
            },
            {
                path: 'logout',
                name: 'auth.logout',
                component: Logout,
                meta: {
                    middleware: ['Auth'],
                },
            },
        ],
    },
];

export default routes;
