<template>
    <form
        class="cups-form"
        @submit.prevent="$emit('submit', d_data)"
    >
        <form-group
            v-if="d_me.hasRole('admin')"
            :error="errors.first('is_bcko')"
            :show-label="false"
        >
            <checkbox
                slot="input"
                v-model="d_data.is_bcko"
                :disabled="c_cupStarted || !d_me.can('setBcko', d_cup)"
                :label="$t('is_bcko')"
            />
        </form-group>

        <form-group
            v-model="d_data.name"
            :autofocus="Boolean(!d_data.id)"
            :error="errors.first('name')"
            :label="$t('cups.form.labels.name')"
            :placeholder="$t('cups.form.placeholders.name')"
        />

        <form-group
            :error="errors.first('course_id')"
            :label="$t('select_course')"
        >
            <auto-complete
                slot="input"
                v-model="d_data.course_id"
                :disabled="c_cupStarted"
                :query-placeholder="d_data.course ? d_data.course.name : undefined"
                :url="d_courseEndpoint"
                label-key="name"
                value-key="id"
            />
        </form-group>

        <form-group
            :error="errors.first('starting_at')"
            :form-text="c_startingAtText"
            :label="$t('starting_at')"
        >
            <datepicker
                slot="input"
                :bootstrap-styling="true"
                :disabled="c_cupStarted || d_data.is_bcko"
                :disabled-dates="{ to: d_startingAtTo }"
                :value="d_data.starting_at"
                format="dd-MM-yyyy"
                @selected="startingAtSelected"
            />
        </form-group>

        <cup-users-form-group
            v-model="d_data.players"
            :disabled="c_cupStarted"
            :errors="errors"
            :is-bcko="d_data.is_bcko"
        />

        <div class="form-group text-center">
            <btn
                v-if="d_data.id"
                :loading="loadingDelete"
                class="btn-danger mx-3"
                element="div"
                @click="$emit('delete')"
            >
                {{ $t('form.delete') }}
            </btn>

            <btn
                :loading="loading"
                class="btn-primary mx-3"
            >
                {{ $t('form.submit') }}
            </btn>
        </div>
    </form>
</template>

<script>
import moment from 'moment';
import Me from '@/library/Me';
import Cup from '@/models/Cup';
import Course from '@/models/Course';
import Errors from '@/library/Errors';
import Datepicker from 'vuejs-datepicker';
import FormGroup from '@/components/common/form/Group';
import Checkbox from '@/components/common/form/Checkbox';
import confirmFormDiscard from '@/mixins/confirmFormDiscard';
import AutoComplete from '@/components/common/form/AutoComplete';
import CupUsersFormGroup from '@/components/cups/users/FormGroup';

export default {
    name: 'CupsForm',
    components: {
        AutoComplete, Checkbox, CupUsersFormGroup, Datepicker, FormGroup,
    },
    mixins: [confirmFormDiscard],
    props: {
        data: {
            type: Object,
            default: () => new Object(), // eslint-disable-line no-new-object
        },
        errors: {
            type: Errors,
            default: () => new Errors(),
        },
        loading: Boolean,
        loadingDelete: Boolean,
    },
    data() {
        return {
            d_courseEndpoint: Course.endpoint,
            d_cup: new Cup(this.data),
            d_data: Object.assign({
                starting_at: moment().add(1, 'days').format('YYYY-MM-DD'),
            }, this.data),
            d_errors: this.errors,
            d_me: new Me(),
            d_startingAtTo: new Date(),
        };
    },
    computed: {
        c_cupStarted() {
            return Boolean(this.data.started_at);
        },
        c_startingAtText() {
            if (this.c_cupStarted) {
                return undefined;
            }

            return this.d_data.is_bcko ? this.$t('cups.form.starting_at.bcko_explanation') : this.$t('cups.form.starting_at.explanation');
        },
    },
    watch: {
        'd_data.is_bcko'(value) {
            if (value === true) {
                this.setBckoStartingAt();
            }
        },
        'd_data.players'() {
            this.d_errors.clear('players');
        },
    },
    created() {
        if (this.d_data.is_bcko === true) {
            this.setBckoStartingAt();
        }
    },
    methods: {
        setBckoStartingAt() {
            this.d_data.starting_at = '2019-02-11';
        },
        startingAtSelected(date) {
            this.d_data.starting_at = moment(date).format('YYYY-MM-DD');
        },
    },
};
</script>
