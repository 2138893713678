import Model from '@/models/Model';

export default class Statistic extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'admin/statistics';
    }

    /**
     * Get the statistics
     */
    static get() {
        return new Promise((resolve, reject) => {
            this.makeRequest('get', [this.endpoint]).then((data) => {
                let mappedData = [];

                for (let key in data.data) {
                    if (data.data.hasOwnProperty(key)) {
                        mappedData.push({
                            model: key,
                            count: data.data[key],
                        });
                    }
                }

                data.data = this.collect(mappedData);

                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    static find(model, year = null) {
        let identifier = `${model}`;

        if (year !== null) {
            identifier += `?year=${year}`;
        }

        return Model.find(identifier, this.endpoint);
    }
}
