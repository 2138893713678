import router from '@/router/index';

export default class Redirect {
    static to(to) {
        let method = 'push';
        let currentRoute = router.currentRoute;

        if (typeof to === 'string') {
            to = {
                name: to,
            };
        }

        if (to.name === undefined) {
            throw new Error('No name provided, not redirecting!');
        }

        if (to.preventNamePrefix !== true) {
            if (currentRoute.meta && currentRoute.meta.appliedNamePrefix) {
                let namePrefix = currentRoute.meta.appliedNamePrefix;
                to.name = namePrefix + '_' + to.name;
            }
        }

        if (to.replace) {
            method = 'replace';
        }

        if (!to.withoutQuery) {
            if (typeof to.query === 'object') {
                if (typeof currentRoute.query === 'object') {
                    to.query = Object.assign(to.query, currentRoute.query);
                }
            } else {
                to.query = currentRoute.query;
            }
        }

        return router[method](to);
    }

    static back() {
        router.go(-1);
    }

    static path(path) {
        router.push({path});
    }
}
