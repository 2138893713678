<template>
    <img
        :src="d_src"
        @error="setPlaceholder"
    >
</template>

<script>
export default {
    name: 'OwImg',
    props: {
        src: undefined,
        srcKey: {
            type: String,
            default: '210',
        },
    },
    data() {
        return {
            d_src: undefined,
        };
    },
    watch: {
        src: {
            handler() {
                this.setSrc();
            },
            deep: true,
        },
    },
    created() {
        this.setSrc();
    },
    methods: {
        getSrcByKey() {
            return this._.get(this.src, this.srcKey, '');
        },
        setSrc() {
            let src = '';

            if (typeof this.src === 'string') {
                src = this.src;
            } else if (typeof this.src === 'object' && this.srcKey !== undefined) {
                src = this.getSrcByKey();
            }

            this.d_src = src;
        },
        setPlaceholder() {
            this.d_src = this.var_placeholderSrc;
        },
    },
};
</script>
