<template>
    <b-card>
        <b-card-title>
            Forms
        </b-card-title>

        <p class="mb-4">
            Examples and usage guidelines for form control styles, layout options, and custom components for creating a wide variety of forms.
        </p>

        <div class="mb-4">
            <h5>
                In action
            </h5>

            <p>
                An example of elements combined in action.
            </p>

            <preview>
                <b-form>
                    <b-form-group
                        label="Email address:"
                        label-for="input-1"
                        description="We'll never share your email with anyone else."
                    >
                        <b-form-input
                            id="input-1"
                            type="email"
                            required
                            placeholder="Enter email"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Your Name"
                        label-for="input-2"
                    >
                        <b-form-input
                            id="input-2"
                            required
                            placeholder="Enter name"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Food:"
                        label-for="input-3"
                    >
                        <b-form-select
                            id="input-3"
                            :options="foods"
                            required
                            :value="null"
                        />
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox-group>
                            <b-form-checkbox value="me">
                                Check me out
                            </b-form-checkbox>

                            <b-form-checkbox value="that">
                                Check that out
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>

                    <b-button
                        class="mr-1"
                        type="submit"
                        variant="primary"
                    >
                        Submit
                    </b-button>

                    <b-button
                        type="reset"
                        variant="danger"
                    >
                        Reset
                    </b-button>
                </b-form>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Sizing
            </h5>

            <p>
                Set heights using classes like <code class="highlighter-rouge">.form-control-lg</code> and <code class="highlighter-rouge">.form-control-sm</code>.
            </p>

            <preview>
                <b-form-input
                    class="mb-1"
                    placeholder="Small input"
                    size="sm"
                />

                <b-form-input
                    class="mb-1"
                    placeholder="Normal input"
                />

                <b-form-input
                    placeholder="Large input"
                    size="lg"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Readonly
            </h5>

            <p>
                Add the <code class="highlighter-rouge">readonly</code> boolean attribute on an input to prevent modification of the input’s value. Read-only inputs appear lighter (just like disabled inputs), but retain the standard cursor.
            </p>

            <preview>
                <b-form-input
                    placeholder="Readonly input here..."
                    readonly
                />
            </preview>

            <p>
                If you want to have <code class="highlighter-rouge">&lt;input readonly&gt;</code> elements in your form styled as plain text, use the <code class="highlighter-rouge">.form-control-plaintext</code> class to remove the default form field styling and preserve the correct margin and padding.
            </p>

            <preview>
                <b-form-input
                    placeholder="Readonly plain text input here..."
                    plaintext
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Range
            </h5>

            <p>
                Set horizontally scrollable range inputs using <code class="highlighter-rouge">.form-control-range</code>.
            </p>

            <preview>
                <b-form-group label="Example range input">
                    <b-form-input
                        name="radio-inline"
                        :options="foods"
                        type="range"
                    />
                </b-form-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Checkboxes and radios
            </h5>

            <p>
                Each checkbox and radio <code class="highlighter-rouge">&lt;input&gt;</code> and <code class="highlighter-rouge">&lt;label&gt;</code> pairing is wrapped in a <code class="highlighter-rouge">&lt;div&gt;</code> to create our custom control. Structurally, this is the same approach as our default <code class="highlighter-rouge">.form-check</code>.
            </p>

            <preview>
                <b-form-group label="Checkboxes">
                    <b-form-checkbox-group
                        :options="foods"
                        name="radio-inline"
                    />
                </b-form-group>

                <b-form-group label="Radios">
                    <b-form-radio-group
                        :options="foods"
                        name="radio-inline"
                    />
                </b-form-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Help text
            </h5>

            <p>
                Help text below inputs can be styled with <code class="highlighter-rouge">.form-text</code>. This class includes <code class="highlighter-rouge">display: block</code> and adds some top margin for easy spacing from the inputs above.
            </p>

            <preview>
                <b-form-group
                    description="Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji."
                    label="Password"
                >
                    <b-form-input type="password" />
                </b-form-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Disabled forms
            </h5>

            <p>
                Add the <code class="highlighter-rouge">disabled</code> boolean attribute on an input to prevent user interactions and make it appear lighter.
            </p>

            <preview>
                <b-form>
                    <b-form-group
                        label="Email address:"
                        label-for="input-5"
                        description="We'll never share your email with anyone else."
                    >
                        <b-form-input
                            id="input-5"
                            disabled
                            type="email"
                            required
                            placeholder="Enter email"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Your Name"
                        label-for="input-6"
                    >
                        <b-form-input
                            id="input-6"
                            disabled
                            required
                            placeholder="Enter name"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Food:"
                        label-for="input-7"
                    >
                        <b-form-select
                            id="input-7"
                            disabled
                            :options="foods"
                            required
                            :value="null"
                        />
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox-group>
                            <b-form-checkbox
                                disabled
                                value="me"
                            >
                                Check me out
                            </b-form-checkbox>

                            <b-form-checkbox
                                disabled
                                value="that"
                            >
                                Check that out
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>

                    <b-button
                        class="mr-1"
                        disabled
                        type="submit"
                        variant="primary"
                    >
                        Submit
                    </b-button>

                    <b-button
                        disabled
                        type="reset"
                        variant="danger"
                    >
                        Reset
                    </b-button>
                </b-form>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Validation
            </h5>

            <p>
                Display feedback to users.
            </p>

            <preview>
                <b-form>
                    <b-form-group
                        label="Email address:"
                        label-for="input-8"
                        description="We'll never share your email with anyone else."
                        :state="true"
                        valid-feedback="Best email address in the world!"
                    >
                        <b-form-input
                            id="input-8"
                            type="email"
                            placeholder="Enter email"
                            required
                            :state="true"
                            value="thomas@owow.io"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Your Name"
                        label-for="input-9"
                        :state="false"
                        invalid-feedback="The name field is required."
                    >
                        <b-form-input
                            id="input-9"
                            placeholder="Enter name"
                            required
                            :state="false"
                        />
                    </b-form-group>

                    <b-form-group
                        label="Food:"
                        label-for="input-10"
                        :state="false"
                        invalid-feedback="The food field is required."
                    >
                        <b-form-select
                            id="input-10"
                            :options="foods"
                            required
                            :state="false"
                            :value="null"
                        />
                    </b-form-group>

                    <b-form-group
                        :state="false"
                        invalid-feedback="Please select one."
                    >
                        <b-form-checkbox-group :state="false">
                            <b-form-checkbox value="me">
                                Check me out
                            </b-form-checkbox>

                            <b-form-checkbox value="that">
                                Check that out
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>

                    <b-button
                        class="mr-1"
                        type="submit"
                        variant="primary"
                    >
                        Submit
                    </b-button>

                    <b-button
                        type="reset"
                        variant="danger"
                    >
                        Reset
                    </b-button>
                </b-form>
            </preview>
        </div>

        <div>
            <h5>
                File browser
            </h5>

            <p>
                The file input is the most gnarly of the bunch and requires additional JavaScript if you’d like to hook them up with functional Choose file… and selected file name text.
            </p>

            <preview>
                <b-form-file />
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Forms',
    components: {Preview},
    data() {
        return {
            foods: [
                {text: 'Select One', value: null},
                'Carrots',
                'Beans',
                'Tomatoes',
                'Corn',
            ],
        };
    },
};
</script>
