<template>
    <table class="courses-tees-table table table-responsive-md mb-4 shadow rounded bg-white border-grid">
        <thead>
            <tr>
                <th
                    class="border-0 table-title text-uppercase"
                    colspan="14"
                >
                    {{ title }}
                </th>
            </tr>

            <tr>
                <th class="text-gray">
                    {{ $t('hole') }}
                </th>

                <th
                    v-for="index in holeCount"
                    :key="'hole_header_' + index"
                >
                    {{ index + holeStart }}
                </th>

                <th>
                    {{ $t('in') }}
                </th>

                <th>
                    {{ $t('slope') }}
                </th>

                <th>
                    {{ $t('rating') }}
                </th>

                <th class="p-0 border-0" />
            </tr>
        </thead>

        <table-body
            v-for="(tee, index) of d_tees"
            :key="'tee_table_body_' + tee.key"
            v-model="d_tees[index]"
            :disabled="disabled"
            :errors="errors.getAsErrors(index)"
            :hole-count="holeCount"
            :hole-start="holeStart"
            :class="{ 'show-tees-delete-button' : d_tees.length > 1 }"
            @removeTee="removeTee(index)"
        />
    </table>
</template>

<script>
import formProps from '@/library/mixins/formProps';
import courseProps from '@/library/mixins/courseProps';
import TableBody from '@/components/courses/tees/TableBody';

export default {
    name: 'CoursesTeesTable',
    components: {TableBody},
    mixins: [courseProps, formProps],
    props: {
        title: {
            type: String,
            default: '',
        },
        value: {
            type: Array,
            default() {
                return [];
            }},
    },
    data() {
        return {
            d_tees: this.value.slice(),
        };
    },
    watch: {
        d_tees: {
            handler() {
                this.$emit('input', this.d_tees);
            },
            deep: true,
        },
        value() {
            this.d_tees = this.value;
        },
    },
    methods: {
        removeTee(index) {
            if (this.d_tees.length === 1) {
                return false;
            }

            this.d_tees.splice(index, 1);
        },
    },
};
</script>
