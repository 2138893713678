<template>
    <b-row
        align-h="around"
        align-v="center"
        no-gutters
    >
        <b-col
            v-for="(sponsor, index) of sponsors"
            :key="index"
            class="sponsors-col text-center p-1"
        >
            <ow-img
                class="img-fluid"
                src-key="original"
                :src="sponsor"
            />
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'WhitelabelSponsors',

    props: {
        sponsors: {
            required: true,
            type: Array,
        },
    },
};
</script>

<style lang="scss" scoped>
.sponsors-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    margin-bottom: 1.5rem;

    .img-fluid {
        max-width: 6rem;
    }
}
</style>
