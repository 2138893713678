<template>
    <div class="auto-complete">
        <input
            ref="input"
            v-model="d_query"
            :placeholder="placeholder"
            :disabled="disabled"
            type="search"
            class="form-control"
            :class="inputClass"
        >

        <div
            v-if="d_showDropdown"
            class="dropdown-menu"
        >
            <slot
                v-if="d_loading"
                name="loading"
            >
                <span class="dropdown-item loading-placeholder text-center">
                    <loader :loading="d_loading" />
                </span>
            </slot>

            <slot
                v-if="!d_filteredOptions.length && !d_loading"
                :query="d_query"
                name="no_results"
            >
                <span class="no-results-placeholder">
                    No results
                </span>
            </slot>

            <div
                v-for="(option, index) of d_filteredOptions"
                :key="index"
                @click="onClickOption(option)"
            >
                <!-- The default slot, to allow custom dropdown item markup. -->
                <slot :option="option">
                    <div class="dropdown-item">
                        {{ option.label }}
                    </div>
                </slot>
            </div>

            <!-- The dropdown bottom slot, only shown when not loading. -->
            <slot
                v-if="!d_loading"
                name="dropdown-bottom"
            />
        </div>
    </div>
</template>

<script>
import Request from '@/library/Request';

export default {
    name: 'AutoComplete',
    props: {
        queryPlaceholder: {
            type: String,
            default: undefined,
        },
        labelKey: {
            type: String,
            default: 'name',
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Search',
        },
        requestInterval: {
            type: Number,
            default: 500,
        },
        query: {
            type: String,
            default: '',
        },
        value: undefined,
        valueKey: {
            type: String,
            default: 'id',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: undefined,
        },

        /**
         * Indicates if the dropdown should reset query after an option is clicked.
         * Default is `false`, which means query will be set to the label of clicked option.
         */
        resetQuery: {
            type: Boolean,
            default: false,
        },

        /**
         * Dynamic class(es) that are given to the <input>.
         */
        inputClass: {
            type: [String, Array, Object],
            default: '',
        },
    },
    data() {
        return {
            d_dropdownOptions: [],
            d_filteredOptions: [],
            d_loading: false,
            d_requestTimeout: undefined,
            d_showDropdown: false,
            d_skipFilter: false,
            d_preventSearch: false,
            d_request: new Request,
            d_query: '',
        };
    },
    watch: {
        d_query: function() {
            if (this.d_preventSearch) {
                this.d_preventSearch = false;
                return;
            }
            this.d_showDropdown = false;
            if (this.d_skipFilter) {
                this.d_skipFilter = false;
                return false;
            }

            if (this.url && this.d_query !== '') {
                this.getOptionsByUrl();
            } else {
                this.filterOptions();
            }
        },
        value: function() {
            this.selectOption(this.value);
        },
        query: function() {
            this.d_query = this.query;

            this.d_skipFilter = true;
        },

        /**
         * When disabled, immediately hides the dropdown and clear the query.
         */
        disabled: function() {
            this.d_showDropdown = false;

            this.d_query = '';
        },
    },
    created() {
        if (this.queryPlaceholder) {
            this.d_preventSearch = true;
            this.d_query = this.queryPlaceholder;
        }

        this.d_dropdownOptions = this.generateOptionsStructure(this.options);

        if (!!this.value) {
            let value = this.generateOptionStructure(this.value);

            if (!!value.value) {
                this.selectOption(value);
            }
        }

        document.addEventListener('click', this.onClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    },
    methods: {
        filterOptions: function() {
            if (this.d_query === undefined || this.d_query === '') {
                this.d_filteredOptions = [];
                return false;
            }

            this.d_filteredOptions = this.d_dropdownOptions.filter((option) => {
                return option.label.indexOf(this.d_query) > -1;
            });
        },
        generateOptionStructure: function(option) {
            let structure = {};

            if (this.labelKey) {
                structure.label = String(this.getProperty(option, this.labelKey));
            } else {
                structure.label = String(option);
            }

            if (this.valueKey) {
                structure.value = this.getProperty(option, this.valueKey);
            } else {
                structure.value = option;
            }

            structure.original = option;

            return structure;
        },
        generateOptionsStructure: function(options) {
            let structures = [];

            for (let index in options) {
                let structure = this.generateOptionStructure(options[index]);
                structure.index = index;

                structures.push(structure);
            }

            return structures;
        },
        generateQueryUrl: function() {
            let url = this.d_request.buildUrl(this.url);
            let parts = url.split('/');
            let last = parts[parts.length - 1];

            if (/\?/g.test(last)) {
                url += '&';
            } else {
                url += '?';
            }

            url += 'search=' + this.d_query;

            return url;
        },
        getOptionsByUrl: function() {
            let url = this.generateQueryUrl();
            let headers = this.d_request.getHeaders();

            this.d_loading = false;
            clearTimeout(this.d_requestTimeout);

            this.d_requestTimeout = setTimeout(() => {
                this.d_loading = true;
                this.d_filteredOptions = [];
                this.d_showDropdown = true;

                this.$http.get(url, headers).then(({data}) => {
                    this.d_filteredOptions = this.generateOptionsStructure(data.data);
                    this.d_loading = false;
                });
            }, this.requestInterval);
        },
        getProperty: function(data, property) {
            if (!data) {
                return data;
            }

            let value = data;
            let keys = property.split('.');

            for (let key of keys) {
                value = value[key];
            }

            return value;
        },
        onClickOption: function(option) {
            this.selectOption(option);

            this.d_showDropdown = false;

            this.$refs['input'].focus();

            this.$emit('input', option.value);

            this.$emit('onClickOption', option);
        },
        onClickOutside: function(e) {
            let node = e.target;
            while (node) {
                if (node.className && node.className.indexOf('auto-complete') > -1) {
                    return true;
                }

                node = node.parentNode;
            }

            this.d_filteredOptions = [];
            this.d_showDropdown = false;
        },
        selectOption: function(option) {
            if (!option || this.resetQuery) {
                return this.d_query = '';
            }

            this.d_skipFilter = true;

            if (option instanceof Object) {
                this.d_query = option.label;
            } else if (this.labelKey && this.valueKey) {
                for (let filteredOption of this.d_filteredOptions) {
                    if (filteredOption[this.valueKey] === option) {
                        this.d_query = filteredOption[this.labelKey];

                        break;
                    }
                }
            } else {
                this.d_query = String(option);
            }

            this.d_filteredOptions = [];
        },
    },
};
</script>
