import {Model} from './Model';

export class User extends Model {
    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'users',
        };
    }
}
