import Response from './Response';
import $http from '@/library/http/client';
import {Request as BaseRequest} from 'vue-mc';

export default class Request extends BaseRequest {
    /**
     * Overrides vue-mc to use our own Response class.
     */
    createResponse(axiosResponse) {
        return new Response(axiosResponse);
    }

    /**
     * Overrides vue-mc's Request to use our own HTTP client.
     *
     * @returns {Promise}
     */
    send() {
        return $http
            .request(this.config)
            .then(this.createResponse)
            .catch((error) => {
                throw this.createError(error);
            });
    }
}
