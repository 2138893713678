export default class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string|number} field
     */
    has(field) {
        // Parse integers to string
        field = field.toString();

        if (!field || !this.errors) {
            return false;
        }

        return Boolean(this.first(field));
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string|number} field
     */
    get(field) {
        // Parse integers to string
        field = field.toString();

        if (!field || !this.errors) {
            return;
        }

        let errors = Object.assign({}, this.errors);

        if (field.indexOf('.') > -1) {
            let splitted = field.split('.');

            for (let key of splitted) {
                if (errors.hasOwnProperty(key)) {
                    errors = errors[key];
                } else {
                    return;
                }
            }

            return errors;
        } else {
            return this.errors[field];
        }
    }

    /**
     * Retrieve the first error messag for a field.
     *
     * @param {string} field
     */
    first(field) {
        let errors = this.get(field);

        while (typeof errors === 'object' && !Array.isArray(errors)) {
            errors = errors[Object.keys(errors)[0]];
        }

        if (!errors || !errors.length) {
            return;
        }

        return errors[0];
    }

    /**
     * Gets the errors and returns them in a new Errors instance.
     *
     * @param {string} field
     */
    getAsErrors(field) {
        let errors = new Errors();

        if (this.has(field)) {
            errors.record(this.get(field));
        }

        return errors;
    }

    pluck(fields) {
        let field = fields.find(field => {
            return this.has(field);
        });

        return this.get(field);
    }

    /**
     * Record the new errors.
     *
     * @param {object|string} errors
     * @param {string} key
     * @param {boolean} add
     */
    record(errors, key, add = true) {
        let parsableErrors = {};

        if (key) {
            let copy = Object.assign({}, this.errors);

            if (typeof errors === 'string') {
                errors = [errors];
            }

            if (add) {
                if (!copy.hasOwnProperty(key)) {
                    copy[key] = [];
                }

                copy[key] = copy[key].concat(errors);
            } else {
                copy[key] = errors;
            }

            parsableErrors = copy;
        } else {
            parsableErrors = errors;
        }

        this.errors = this.parse(parsableErrors);
    }

    /**
     * Parses errors
     *
     * @param {array} original
     * @returns {Object}
     */
    parse(original) {
        let errors = {};

        for (let key in original) {
            let error = original[key];

            if (key.indexOf('.') > -1) {
                errors = this.parseSingle(errors, key, error);
            } else {
                errors[key] = error;
            }
        }

        return errors;
    }

    /**
     * Parses a single error
     *
     * @param {Object} object
     * @param {array} path
     * @param {*} value
     * @returns {Object}
     */
    parseSingle(object, path, value) {
        if (typeof object !== 'object') {
            return object;
        }

        path = path.split('.');

        const length = path.length;
        const lastIndex = length - 1;

        let index = -1;
        let nested = object;

        while (nested != null && ++index < length) {
            const key = path[index];
            let newValue = value;

            if (index !== lastIndex) {
                const objValue = nested[key];
                newValue = typeof objValue === 'object' ? objValue : {};
            }

            nested[key] = newValue;
            nested = nested[key];
        }

        return object;
    }

    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }
}
