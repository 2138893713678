import Enum from './Enum';

/**
 * The league has been created and is waiting for someone to add league
 * groups with teams before league matchdays can be generated.
 */
const WAITING_FOR_TEAMS = 0;

/**
 * League matchdays are generated but need to be updated by a user with
 * dates and locations.
 */
const WAITING_FOR_MATCHDAYS = 1;

/**
 * League matchdays have been updated and the team match schedules are
 * ready to receive players, matches can be played.
 */
const PLAYABLE = 2;

/**
 * The league is over and closed. Matches cannot be played anymore.
 */
const ENDED = 3;

export default class LeagueStatus extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            WAITING_FOR_TEAMS,
            WAITING_FOR_MATCHDAYS,
            PLAYABLE,
            ENDED,
        };
    };

    /**
     * Unfortunately we cannot make a magic getter method yet, so we have to
     * specify the enumeration values again as static properties.
     */
    static WAITING_FOR_TEAMS = WAITING_FOR_TEAMS;
    static WAITING_FOR_MATCHDAYS = WAITING_FOR_MATCHDAYS;
    static PLAYABLE = PLAYABLE;
    static ENDED = ENDED;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'league_status';
    }
};
