<template>
    <div class="course-index position-relative">
        <portal
            to="layout-default-header"
            name="layout-default-header-black"
        >
            <b-row
                align-v="end"
                class="pt-5"
            >
                <b-col class="mb-3 mb-sm-0">
                    <h1 class="mb-0">
                        {{ $t('courses.index.title') }}
                    </h1>
                </b-col>

                <b-col
                    v-if="d_me.can('store', Course)"
                    class="text-right"
                >
                    <router-link
                        :to="{name: 'courses.create'}"
                        class="btn btn-min-w btn-primary float-right"
                    >
                        {{ $t('courses.new') }}
                    </router-link>
                </b-col>
            </b-row>
        </portal>

        <form-search
            class="mb-4 w-50"
            @setSearch="setCourseSearch"
        />

        <div class="table-responsive">
            <courses-table
                :loading="pos_loading"
                :rows="pos_rows"
                class="table-hover"
                @onRowClick="onRowClick"
            />
        </div>
    </div>
</template>

<script>
import Me from '@/library/Me';
import Course from '@/models/Course';
import Redirect from '@/library/Redirect';
import CoursesTable from '@/components/courses/Table';
import FormSearch from '@/components/common/form/Search';
import paginatesOnScroll from '@/library/mixins/paginatesOnScroll';

export default {
    name: 'CoursesIndex',
    components: {CoursesTable, FormSearch},
    mixins: [paginatesOnScroll],
    data() {
        return {
            Course: Course,
            d_me: new Me,
            pos_url: Course.getEndpoint(),
        };
    },
    methods: {
        onRowClick(data) {
            Redirect.to({name: 'courses.show', params: {id: data.row.id}});
        },
        pos_success({data}) {
            const rows = Course.collect(data.data);

            this.pos_rows = [...this.pos_rows, ...rows];
        },
        setCourseSearch(query) {
            this.pos_url = Course.getEndpoint(Course.getSearchFieldUrlQueries(query));
        },
    },
};
</script>
