<template>
    <tbody class="tees-table-body">
        <tr>
            <td class="text-gray">
                {{ $t('name') }}
            </td>

            <form-table-group
                v-model="d_tee.name"
                :disabled="disabled"
                :error="errors.first('name')"
                :placeholder="$t('courses.form.placeholders.tee_name')"
                colspan="10"
            />

            <form-table-group
                v-model="d_tee.slope_rating"
                :disabled="disabled"
                :error="errors.first('slope_rating')"
                :placeholder="$t('courses.form.placeholders.slope_rating')"
            />

            <form-table-group
                v-model="d_tee.course_rating"
                :disabled="disabled"
                :error="errors.first('course_rating')"
                :placeholder="$t('courses.form.placeholders.course_rating')"
            />

            <td class="p-0 border-0">
                <btn-icon
                    v-if="!disabled"
                    class="icon-delete mr-3"
                    @click="$emit('removeTee')"
                />
            </td>
        </tr>

        <table-row
            v-model="d_tee"
            :disabled="disabled"
            :errors="errors.getAsErrors('holes')"
            :hole-count="holeCount"
            :hole-start="holeStart"
            :title="$t('meters')"
            category="tee"
        />

        <table-row
            v-model="d_tee"
            :disabled="disabled"
            :errors="errors.getAsErrors('holes')"
            :hole-count="holeCount"
            :hole-start="holeStart"
            :title="$t('par')"
            category="par"
        />

        <table-row
            v-model="d_tee"
            :disabled="disabled"
            :errors="errors.getAsErrors('holes')"
            :hole-count="holeCount"
            :hole-start="holeStart"
            :title="$t('si')"
            category="stroke_index"
        />
    </tbody>
</template>

<script>
import formProps from '@/library/mixins/formProps';
import courseProps from '@/library/mixins/courseProps';
import TableRow from '@/components/courses/tees/TableRow';
import courseDefault from '@/library/mixins/courseDefault';
import FormTableGroup from '@/components/common/form/TableGroup';

export default {
    name: 'TeesTableBody',
    components: {FormTableGroup, TableRow},
    mixins: [courseDefault, courseProps, formProps],
    props: {
        value: {
            type: Object,
            default() {
                return {
                    holes: [],
                };
            }},
    },
};
</script>

<style lang="scss" scoped>
.icon-delete{
    position: absolute;
    left: -2rem;
    display: none;
    margin-top: .2rem;
}

.show-tees-delete-button{
    .icon-delete{
        display: block;
    }
}
</style>
