<template>
    <div
        :class="{ 'is-invalid': error }"
        class="form-group"
    >
        <label
            v-if="showLabel"
            :for="d_for"
        >
            {{ label }}
        </label>

        <slot name="formText">
            <div
                v-if="formText"
                class="form-text"
            >
                {{ formText }}
            </div>
        </slot>

        <slot
            name="input"
            :classes="{ 'form-control': !readonly, 'form-control-plaintext': readonly, 'is-invalid': error }"
        >
            <input
                :id="d_for"
                ref="input"
                v-model="d_value"
                :autofocus="autofocus"
                :class="{ 'form-control': !readonly, 'form-control-plaintext': readonly, 'is-invalid': error }"
                :disabled="disabled"
                :max="max"
                :min="min"
                :placeholder="placeholder"
                :readonly="readonly"
                :required="required"
                :step="step"
                :type="type"
                @blur="$emit('blur')"
            >
        </slot>

        <div
            v-if="error"
            class="invalid-feedback"
        >
            {{ c_error }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormGroup',
    props: {
        autofocus: Boolean,
        disabled: Boolean,
        error: {
            type: [String, Array],
            default: undefined,
        },
        for: {
            type: String,
            default: undefined,
        },
        formText: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        max: undefined,
        min: undefined,
        readonly: Boolean,
        required: Boolean,
        showLabel: {
            type: Boolean,
            default: true,
        },
        step: {
            default: 0,
            type: Number,
        },
        type: {
            type: String,
            default: 'text',
            validator: (value) => {
                return [
                    'text', 'email', 'password', 'date', 'hidden', 'number',
                ].indexOf(value) !== -1;
            },
        },
        value: {
            type: [String, Number],
            default: undefined,
        },
    },
    data() {
        return {
            d_for: this.for === undefined ? this.label : this.for,
            d_value: this.value,
        };
    },
    computed: {
        /**
         * @return {string|undefined} First error message.
         */
        c_error() {
            return Array.isArray(this.error) ? this.error[0] : this.error;
        },
    },
    watch: {
        d_value() {
            this.$emit('input', this.d_value);
        },
        value(value) {
            this.d_value = value;
        },
    },
    mounted() {
        if (this.autofocus) this.focusInput();
    },
    methods: {
        focusInput() {
            this.$refs.input.focus();
        },
    },
};
</script>
