<template>
    <b-card>
        <b-card-title>
            Colors
        </b-card-title>

        <p class="mb-4">
            The following theme colors are available.
        </p>

        <b-row class="m-0">
            <b-col
                class="p-3"
                cols="4"
            >
                Background
            </b-col>

            <b-col
                class="p-3"
                cols="4"
            >
                Text
            </b-col>

            <b-col
                class="p-3"
                cols="4"
            >
                Link
            </b-col>
        </b-row>

        <b-row
            v-for="color in themeColors"
            :key="color"
            class="m-0"
        >
            <b-col
                class="mb-1 p-3"
                :class="getThemeBgClasses(color)"
                cols="4"
            >
                {{ color }}
            </b-col>

            <b-col
                class="mb-1 mx-1 p-3"
                :class="getThemeTextClasses(color)"
                cols="4"
            >
                {{ color }}
            </b-col>

            <b-col class="mb-1 p-0">
                <a
                    class="d-block p-3"
                    :class="getThemeTextClasses(color)"
                    cols="4"
                    href="#"
                >
                    {{ color }}
                </a>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Colors',
    data() {
        return {
            themeColors,
        };
    },
    methods: {
        getThemeTextClasses(color) {
            let classes = [`bg-${color}`];

            if (['warning', 'light'].includes(color)) {
                classes.push('text-dark');
            } else {
                classes.push('text-white');
            }

            return classes;
        },
        getThemeBgClasses(color) {
            let classes = [`text-${color}`];

            if (['light'].includes(color)) {
                classes.push('bg-dark');
            } else {
                classes.push('bg-white');
            }

            return classes;
        },
    },
};
</script>
