import {
    Model as BaseModel,
    Collection as BaseCollection,
} from 'vue-mc';
import $t from '@/lang/vueI18n';
import {find, get, isEmpty, merge, some} from 'lodash';
import Request from '@/library/http/Request';
import Vue from 'vue';

export class Model extends BaseModel {
    /**
     * @returns {string|undefined} Endpoint of this model.
     */
    static get endpoint() {
        return (new this).getOption('endpoint') || undefined;
    }

    /**
     * @returns {boolean} `true` if this model has errors, `false` otherwise.
     */
    get hasErrors() {
        return !isEmpty(this.errors);
    }

    /**
     * @returns {string} First error message that this model has.
     */
    get firstErrorMessage() {
        let message = '';

        if (this.hasErrors) {
            const firstError = find(this.errors, error => typeof error[0] === 'string');

            if (firstError) message = firstError[0];
        };

        return message;
    }

    /**
     * Overrides vue-mc's Model to use our own Request class.
     *
     * @returns {Request} A new `Request` using the given configuration.
     */
    createRequest(config) {
        return new Request(config);
    }

    /**
     * Called when a fetch request failed.
     *
     * @param {Error}  error
     */
    onFetchFailure(error) {
        super.onFetchFailure(error);

        const message = error.getResponse().getErrorMessage();

        if (message) {
            this.setErrors({message});
        }
    }

    /**
     * Called when a fetch request was successful.
     *
     * @param {Response} response
     */
    onFetchSuccess(response) {
        // For some reason the original code would try to get the lowest level
        // `data` property in the response. This would not work when the model
        // has a `data` property by itself. The original calls
        // `response.getData()`
        const attributes = response.response.data;

        this.assign(attributes);

        Vue.set(this, 'fatal', false);
        Vue.set(this, 'loading', false);

        this.emit('fetch', {error: null});
    }

    /**
     * Returns the default options for this model.
     *
     * @returns {Object}
     */
    getDefaultOptions() {
        return merge(super.getDefaultOptions(), {
            patch: true,
            saveUnchanged: false,
            validateOnChange: true,
            validateRecursively: false,
            mutateBeforeSave: false,
        });
    }

    /**
     * Tries to construct our API route based on endpoint and identifier.
     *
     * @return {string|undefined} Route value by key.
     */
    getRoute(key, fallback) {
        let route;

        try {
            route = super.getRoute(key, fallback);
        } catch (error) {
            route = this.getOption('endpoint');

            if (!route) {
                throw new Error('Model endpoint is not yet defined.');
            }

            if (key !== 'create') {
                route += `/{${this.getOption('identifier')}}`;
            }

            const prefix = this.getOption('routePrefix');

            if (prefix) {
                route = `${prefix}/${route}`;
            }
        }

        return route;
    }

    /**
     * Returns the translated label string for the given attribute.
     *
     * @param {string} attribute
     * @returns {string}
     */
    getLabel(attribute) {
        // The convention for the label's translation key:
        // {API endpoint}.model.{attribute}.label
        // E.g.: 'leagues.model.name.label'
        return $t.t(`${this.constructor.endpoint}.model.${attribute}.label`);
    }

    /**
     * Returns a specific vue-router Location of this instance.
     * By default, the show Location will be returned.
     *
     * E.g.: `league.getLocation()` => {
     *     name: 'leagues.show',
     *     params: {slug: 'league-slug'},
     * }
     *
     * @param {string} name
     * @returns {Location} vue-router Location.
     */
    getLocation(name = 'show') {
        // The location name should start with this model's API endpoint,
        // followed by the specificly requested route name.
        let location = {
            name: `${this.getOption('endpoint')}.${name}`,
        };

        // If the requested location is not `index` and not `create` ...
        if (name != 'index' && name != 'create') {
            // add this model's identifier as location params.
            location.params = {
                [this.getOption('identifier')]: this.identifier(),
            };
        }

        return location;
    }

    /**
     * Called before a save request is made.
     *
     * @returns {boolean} `false` if the request should not be made.
     */
    onSave() {
        this.clearErrors();

        return super.onSave();
    }

    /**
     * Mapping of model attributes to the class it should be transformed into.
     */
    transformations() {
        return {};
    }

    /**
     * Assigns all given model data to the model's attributes and reference.
     * This method is called on instantiation, fetch, and save success.
     */
    assign(attrs) {
        // Transform each given attribute into the desired class instance.
        Object.entries(this.transformations()).forEach(([key, constructor]) => {
            const attribute = attrs[key] || this.defaults()[key];

            if (attribute) {
                attrs[key] = attribute instanceof constructor
                    ? attribute
                    : new constructor(attribute);
            }
        });

        super.assign(attrs);
    }
}

export class Collection extends BaseCollection {
    /**
     * Overrides vue-mc's Collection to use our own Request class.
     *
     * @returns {Request} A new `Request` using the given configuration.
     */
    createRequest(config) {
        return new Request(config);
    }

    /**
     * Tries to construct our API route based on endpoint of this collection's model.
     * It's possible to prefix the route with some string, just set `routePrefix` in
     * the collection's `options()`.
     *
     * @return {string|undefined} Route value by key.
     */
    getRoute(key, fallback) {
        let route;

        try {
            route = super.getRoute(key, fallback);
        } catch (error) {
            // If this part is reached, that means `routes()` do not contain specified key.
            // We'll construct the route based on the `options()`.

            route = this.model().endpoint;

            if (!route) {
                throw new Error('Collection\'s model endpoint is not yet defined.');
            }

            const prefix = this.getOption('routePrefix');

            if (prefix) {
                route = `${prefix}/${route}`;
            }
        }

        return route;
    }

    /**
     * @returns {boolean} `true` if this collection has errors, `false` otherwise.
     */
    get hasErrors() {
        return !isEmpty(this.getErrors());
    }

    /**
     * @returns {string} First error message that this collection has.
     */
    get firstErrorMessage() {
        let message = '';

        if (this.hasErrors) {
            const firstError = find(this.errors, error => typeof error[0] === 'string');

            if (firstError) message = firstError[0];
        };

        return message;
    }

    /**
     * Returns true if any model in this collection passes the predicate check, else false.
     *
     * @param {string|function|Object} predicate
     * @return {boolean}
     *
     * @see {@link https://lodash.com/docs/#some}
     */
    some(predicate) {
        return some(this.models, predicate);
    }

    /**
     * Returns true if any model in this collection has changed.
     *
     * @return {boolean}
     */
    changed() {
        return this.some(model => !!model.changed());
    }

    /**
     * Add `index` to each model based on its order in this collection. The first
     * model will have index 0.
     */
    indexModels() {
        this.models.forEach((model, index) => {
            Vue.set(model, 'index', index);
        });
    }

    /**
     * Called when a fetch request was successful.
     *
     * @param {Object} response
     */
    onFetchSuccess(response) {
        if (this.isPaginated()) {
            // Check for the `last_page` attribute returned in the API paginated resource.
            const lastPage = get(response, 'response.data.last_page', null);

            // If already at the last page, set `_page` to -1 to prevent further
            // unnecessary fetching.
            if (lastPage === this.getPage()) {
                Vue.set(this, '_page', -1);
            }
        }

        super.onFetchSuccess(response);
    }

    /**
     * @returns {Object} Query parameters that will be appended to the `fetch` URL.
     */
    getFetchQuery() {
        let query = super.getFetchQuery();

        if (this.get('type') !== undefined) {
            query.type = this.get('type');
        }

        if (this.get('perPage') !== undefined) {
            query.per_page = this.get('perPage');
        }

        return query;
    }
}
