<template>
    <b-card>
        <b-card-title>
            Buttons
        </b-card-title>

        <p class="mb-4">
            Use Bootstrap’s custom button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more.
        </p>

        <div class="mb-4">
            <h5>
                Contextual
            </h5>

            <p>
                Bootstrap includes several predefined button styles, each serving its own semantic purpose, with a few extras thrown in for more control.
            </p>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    :variant="color"
                >
                    {{ color }}
                </b-button>

                <b-button variant="link">
                    link
                </b-button>
            </preview>

            <h6>
                Active
            </h6>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    class="active mr-1"
                    :variant="color"
                >
                    {{ color }}
                </b-button>

                <b-button
                    class="active"
                    variant="link"
                >
                    link
                </b-button>
            </preview>

            <h6>
                Disabled
            </h6>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    disabled
                    class="mr-1"
                    :variant="color"
                >
                    {{ color }}
                </b-button>

                <b-button
                    disabled
                    variant="link"
                >
                    link
                </b-button>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Outline
            </h5>

            <p>
                In need of a button, but not the hefty background colors they bring? Replace the default modifier classes with the <code class="highlighter-rouge">.btn-outline-*</code> ones to remove all background images and colors on any button.
            </p>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    :variant="`outline-${color}`"
                >
                    {{ color }}
                </b-button>
            </preview>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    class="active mr-1"
                    :variant="`outline-${color}`"
                >
                    {{ color }}
                </b-button>
            </preview>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    disabled
                    class="mr-1"
                    :variant="`outline-${color}`"
                >
                    {{ color }}
                </b-button>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Together
            </h5>

            <p>
                Comparison between normal and outline
            </p>

            <preview>
                <b-button
                    class="mr-1"
                    variant="primary"
                >
                    Normal
                </b-button>

                <b-button variant="outline-primary">
                    Outline
                </b-button>
            </preview>
        </div>

        <div>
            <h5>
                Sizes
            </h5>

            <p>
                Fancy larger or smaller buttons? Add <code class="highlighter-rouge">.btn-lg</code> or <code class="highlighter-rouge">.btn-sm</code> for additional sizes.
            </p>

            <preview>
                <b-button
                    class="mr-1"
                    size="lg"
                    variant="primary"
                >
                    Large button
                </b-button>

                <b-button
                    class="mr-1"
                    size="lg"
                >
                    Large button
                </b-button>
            </preview>

            <preview>
                <b-button
                    class="mr-1"
                    size="sm"
                    variant="primary"
                >
                    Small button
                </b-button>

                <b-button
                    class="mr-1"
                    size="sm"
                >
                    Small button
                </b-button>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Buttons',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
};
</script>
