<template>
    <div class="cups-matches-collapse">
        <collapse class="no-indicator card overflow-hidden shadow-sm">
            <div
                slot="header"
                class="row mx-0"
            >
                <div
                    v-for="i in 2"
                    :key="`users-${i}`"
                    :class="{ 'bg-primary text-white': userIsWinner(i - 1) }"
                    class="col-6 p-3 border-right"
                >
                    <div
                        v-if="d_match.users[i - 1]"
                        class="row h-100 align-items-center"
                    >
                        <div
                            :class="{ 'pr-0': i === 1, 'pl-0 order-2': i === 2 }"
                            class="col-auto"
                        >
                            <ow-img
                                v-if="d_match.users[i - 1].id"
                                :src="d_match.users[i - 1]"
                                class="img-table rounded-circle border border-white"
                                src-key="images.0.140"
                            />

                            <img
                                v-else
                                :src="var_placeholderSrc"
                                class="img-table rounded-circle border border-white"
                            >
                        </div>

                        <div
                            :class="{ 'text-right order-1': i === 2 }"
                            class="col text-truncate"
                        >
                            {{ User.displayName(d_match.users[i - 1]) }}
                        </div>

                        <div
                            v-if="userIsWinner(i - 1)"
                            v-trans
                            :class="[ i === 1 ? 'win-left' : 'win-right' ]"
                            class="text-uppercase position-relative font-weight-bold"
                        >
                            win
                        </div>
                    </div>

                    <div
                        v-else
                        class="tba text-center text-uppercase"
                    >
                        {{ $t('tba') }}
                    </div>
                </div>
            </div>

            <div slot="body" />
        </collapse>

        <div
            v-if="c_showControls"
            class="controls row pb-3"
        >
            <template v-if="c_winnerCanBeSet">
                <div
                    v-for="i in 2"
                    :key="`setWinner-${i}`"
                    class="col-6 pt-3"
                >
                    <btn
                        class="btn-success btn-block"
                        @click="setUserAsWinner(i - 1)"
                    >
                        {{ $t('cups.matches.collapse.set_as_winner') }}
                    </btn>
                </div>
            </template>

            <div
                v-if="d_match.is_resetable"
                class="col-12 pt-3"
            >
                <btn
                    :loading="d_loadingReset"
                    class="btn-danger btn-block"
                    @click="resetMatch"
                >
                    {{ $t('cups.matches.collapse.reset') }}
                </btn>
            </div>
        </div>
    </div>
</template>

<script>
import Me from '@/library/Me';
import User from '@/models/User';
import CupMatch from '@/models/CupMatch';
import Collapse from '@/components/common/Collapse';

export default {
    name: 'CupsMatchesCollapse',
    components: {Collapse},
    props: {
        match: {
            type: CupMatch,
            required: true,
        },
    },
    data() {
        return {
            d_addUserListenerKey: undefined,
            d_loadingReset: false,
            d_me: new Me,
            d_match: this.match,
            d_resetListenerKey: undefined,
            d_setResetableListenerKey: undefined,
            User: User,
        };
    },
    computed: {
        c_showControls() {
            return this.d_me.can('update', this.match) &&
                (
                    this.c_winnerCanBeSet ||
                    this.d_match.is_resetable
                );
        },
        c_winnerCanBeSet() {
            return !this.d_match.match &&
                this.d_match.status === 3 &&
                this.d_match.users.length === 2;
        },
    },
    watch: {
        match(value) {
            this.d_match = value;
        },
    },
    mounted() {
        this.d_resetListenerKey = `cups.matches.${this.match.id}.resetParent`;
        this.d_addUserListenerKey = `cups.matches.${this.match.id}.addUserToParent`;
        this.d_setResetableListenerKey = `cups.matches.${this.match.next_id}.setChildResetable`;

        this.$store.$on(this.d_resetListenerKey, this.emptyMatch);
        this.$store.$on(this.d_addUserListenerKey, this.addUser);
        this.$store.$on(this.d_setResetableListenerKey, this.setResetable);
    },
    destroyed() {
        this.$store.$off(this.d_resetListenerKey, this.emptyMatch);
        this.$store.$off(this.d_addUserListenerKey, this.addUser);
        this.$store.$off(this.d_setResetableListenerKey, this.setResetable);
    },
    methods: {
        addUser(user) {
            let clone = user.clone();
            clone.team = null;

            this.d_match.users.push(clone);

            if (this.d_match.users.length === 2) {
                this.d_match.status = 3;
            }
        },
        emptyMatch(prevMatch) {
            this.d_match.status = 1;

            // Only remove the user that is in the match that has been resetted
            // (a.k.a. the previouse match).
            this.d_match.users = this.d_match.users.filter((user) => {
                const found = prevMatch.users.find((prevUser) => {
                    return prevUser.id === user.id;
                });

                return !found;
            });
        },
        setResetable(isResetable) {
            if (!this.match.next_id) return false;

            this.d_match.is_resetable = isResetable;
        },
        resetMatch() {
            const confirm = this.$helpers.confirm().show();

            confirm.on('resolve', () => {
                this.d_loadingReset = confirm.loading = true;

                this.d_match.reset().then(({data}) => {
                    // Emit event so the next match can remove the winning
                    // user and change the status.
                    let key = `cups.matches.${this.match.next_id}.resetParent`;
                    this.$store.$emit(key, this.d_match);

                    // Emit event to the children can be resetted again.
                    key = `cups.matches.${this.match.id}.setChildResetable`;
                    this.$store.$emit(key, true);

                    // Reset the match.
                    this.d_match = new CupMatch(data);

                    this.d_loadingReset = confirm.loading = false;
                    confirm.close();
                });
            });
        },
        setUserAsWinner(index) {
            const user = this.d_match.users[index];

            const confirm = this.$helpers.confirm().show();

            confirm.on('resolve', () => {
                confirm.loading = true;

                this.d_match.setCupUserAsWinner(user.cup_user_id).then(({data}) => {
                    // Emit event so the next match can add the winner to its
                    // users array.
                    let key = `cups.matches.${this.match.next_id}.addUserToParent`;
                    this.$store.$emit(key, user);

                    // Emit event to the children cannot be resetted again.
                    key = `cups.matches.${this.match.id}.setChildResetable`;
                    this.$store.$emit(key, false);

                    // Update the match.
                    this.d_match.status = data.status;
                    this.d_match.is_resetable = data.is_resetable;
                    this.d_match.match = {
                        winning_team: 1,
                    };
                    user.team = 1;

                    confirm.loading = false;
                    confirm.close();
                });
            });
        },
        userIsWinner(userIndex) {
            if (
                !this.d_match.match ||
                !this.d_match.users.length ||
                !this.d_match.users[userIndex]
            ) return false;

            const user = this.d_match.users[userIndex];

            return user.team === this.d_match.match.winning_team;
        },
    },
};
</script>

<style lang="scss" scoped>
.cups-matches-collapse {
    & /deep/ .common-collapse {
        .header {
            border-bottom: 0;
        }

        .header,
        .body {
            padding: 0;
        }

        &.show + .controls {
            display: flex;
        }
    }

    .controls {
        display: none;
    }

    .tba {
        line-height: 2rem;
    }

    .win-left {
        left: -.7rem;
    }

    .win-right {
        right: -.7rem;
    }
}
</style>
