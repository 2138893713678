<template>
    <tr class="tees-table-row">
        <td class="text-gray">
            {{ title }}
        </td>

        <form-table-group
            v-for="index in holeCount"
            :key="`tee_table_body_hole_${category}_` + (index + holeStart)"
            v-model="d_tee.holes[index + holeStart - 1][category]"
            :disabled="disabled"
            :error="errors.first((index + holeStart - 1)+`.${category}`)"
            type="text"
        />

        <td class="text-body">
            {{ getTotal() }}
        </td>

        <td colspan="3" />
    </tr>
</template>

<script>
import formProps from '@/library/mixins/formProps';
import courseProps from '@/library/mixins/courseProps';
import courseDefault from '@/library/mixins/courseDefault';
import FormTableGroup from '@/components/common/form/TableGroup';

export default {
    name: 'TeesTableRow',
    components: {FormTableGroup},
    mixins: [courseDefault, courseProps, formProps],
    props: {
        category: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        value: {
            type: Object,
            default() {
                return {holes: []};
            },
        },
    },
    methods: {
        getTotal() {
            let total = 0;

            for (let i = 0; i < this.holeCount; i++) {
                let hole = this.d_tee.holes[i + this.holeStart];
                let value = parseInt(hole[this.category]);

                total += isNaN(value) ? 0 : value;
            }

            return total;
        },
    },
};
</script>
