export default {
    data() {
        return {
            d_tee: Object.assign({}, this.value),
        };
    },
    watch: {
        d_tee: {
            handler() {
                this.$emit('input', this.d_tee);
            },
            deep: true,
        },
        value() {
            this.d_tee = this.value;
        },
    },
};
