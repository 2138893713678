/**
 * A Vue directive that can be used by an element to trigger a callback when
 * the bottom of that element is (almost) visible in the viewport. In other
 * words, the element can have infinite scrolling behavior.
 *
 * Example usage: <element v-infinite-scroll:100="scrollHandler" />
 * - 100 is an optional `offset`, defaulted to 50. See explanation below.
 */
const directive = {
    inserted: function(el, binding) {
        const onScroll = async function() {
            // The element's bounding (position relative to the viewport).
            const element = el.getBoundingClientRect();

            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            // The callback function that will be called when the element's
            // bottom is (almost) visible in the viewport.
            const scrollHandler = binding.value;

            // The amount of pixel before the element's bottom is visible in
            // the viewport. If the element has reached this offset point, the
            // `scrollHandler` will be called.
            const offset = parseInt(binding.arg) || 50;

            if (element.bottom <= viewportHeight + offset) {
                // Call the `scrollHandler`.
                const continueInfiniteScroll = await scrollHandler();

                // If `scrollHandler` decides to stop infinite scroll (by returning false),
                // we will remove the scroll event listener.
                if (continueInfiniteScroll === false) {
                    window.removeEventListener('scroll', onScroll);
                }
            }
        };

        window.addEventListener('scroll', onScroll);
    },
};

export default directive;
