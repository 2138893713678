<template>
    <b-card>
        <b-card-title>
            Navbar
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for Bootstrap’s powerful, responsive navigation header, the navbar. Includes support for branding, navigation, and more, including support for our collapse plugin.
        </p>

        <div>
            <h5>
                Example
            </h5>

            <p>
                An example of a navbar.
            </p>

            <preview>
                <b-navbar
                    toggleable="lg"
                    variant="light"
                >
                    <b-navbar-brand>
                        Brand
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse" />

                    <b-collapse
                        id="nav-collapse"
                        is-nav
                    >
                        <b-navbar-nav>
                            <b-nav-item active>
                                Home
                            </b-nav-item>

                            <b-nav-item>
                                Link
                            </b-nav-item>

                            <b-nav-item-dropdown text="Dropdown">
                                <b-dropdown-item>
                                    Action
                                </b-dropdown-item>

                                <b-dropdown-item>
                                    Another action
                                </b-dropdown-item>

                                <b-dropdown-divider />

                                <b-dropdown-item>
                                    Something else here
                                </b-dropdown-item>
                            </b-nav-item-dropdown>

                            <b-nav-item disabled>
                                Disabled
                            </b-nav-item>
                        </b-navbar-nav>

                        <b-navbar-nav class="ml-auto">
                            <b-nav-form>
                                <b-form-input
                                    class="mr-sm-2"
                                    placeholder="Search"
                                />

                                <b-button class="my-2 my-sm-0">
                                    Search
                                </b-button>
                            </b-nav-form>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </preview>

            <p>
                Dark
            </p>

            <preview>
                <b-navbar
                    toggleable="lg"
                    type="dark"
                    variant="dark"
                >
                    <b-navbar-brand>
                        Brand
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse" />

                    <b-collapse
                        id="nav-collapse"
                        is-nav
                    >
                        <b-navbar-nav>
                            <b-nav-item active>
                                Home
                            </b-nav-item>

                            <b-nav-item>
                                Link
                            </b-nav-item>

                            <b-nav-item-dropdown text="Dropdown">
                                <b-dropdown-item>
                                    Action
                                </b-dropdown-item>

                                <b-dropdown-item>
                                    Another action
                                </b-dropdown-item>

                                <b-dropdown-divider />

                                <b-dropdown-item>
                                    Something else here
                                </b-dropdown-item>
                            </b-nav-item-dropdown>

                            <b-nav-item disabled>
                                Disabled
                            </b-nav-item>
                        </b-navbar-nav>

                        <b-navbar-nav class="ml-auto">
                            <b-nav-form>
                                <b-form-input
                                    class="mr-sm-2"
                                    placeholder="Search"
                                />

                                <b-button class="my-2 my-sm-0">
                                    Search
                                </b-button>
                            </b-nav-form>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Navbar',
    components: {Preview},
};
</script>
