import * as moment from 'moment';

moment.defaultFormat = 'DD-MM-YYYY';

export const date = {
    dateFormat(value) {
        if (!value) return value;
        return moment(value).format();
    },
    dateFrom(value) {
        if (!value) return value;
        return moment(value).from();
    },
};