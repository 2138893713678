<template>
    <div class="form-checkbox custom-control custom-checkbox">
        <input
            :id="d_for"
            v-model="d_value"
            :disabled="disabled"
            :value="name"
            class="custom-control-input"
            type="checkbox"
        >

        <label
            :for="d_for"
            class="custom-control-label"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {
        disabled: Boolean,
        for: {
            default: '',
            type: String,
        },
        label: {
            default: '',
            type: String,
        },
        name: {
            default: '',
            type: String,
        },
        value: {
            default: false,
            type: [Boolean, Array],
        },
    },
    data() {
        return {
            d_for: this.for,
            d_value: this.value,
        };
    },
    watch: {
        value(value) {
            this.d_value = value;
        },
        d_value(value) {
            this.$emit('input', value);
        },
    },
    created() {
        this.d_for = this.for ? this.for : '_' + Math.random().toString(36).substr(2, 9);
    },
};
</script>
