<template>
    <tr class="users-table-row cursor-pointer">
        <td v-if="columns.includes('image')">
            <ow-img
                v-if="row.id"
                :src="row"
                class="img-table rounded-circle"
                src-key="image.140"
            />

            <img
                v-else
                :src="var_placeholderSrc"
                class="rounded-circle"
            >
        </td>

        <td
            v-if="columns.includes('username')"
            class="font-weight-bold text-primary"
        >
            {{ row.username }}

            <i
                v-if="row.pro_at"
                class="fas fa-check-circle text-success fa-xs"
            />
        </td>

        <td v-if="columns.includes('name')">
            {{ User.displayName(row) }}
        </td>

        <td v-if="columns.includes('federation_number')">
            #{{ row.federation_number }}
        </td>

        <td v-if="columns.includes('created_at')">
            {{ row.created_at | dateFrom }}
        </td>

        <td v-if="columns.includes('updated_at')">
            {{ row.updated_at | dateFrom }}
        </td>
    </tr>
</template>

<script>
import User from '@/models/User';
import TableRow from '@/components/common/TableRow';

export default {
    name: 'UsersTableRow',
    extends: TableRow,
    props: {
        row: {
            type: User,
            required: true,
        },
    },
    data() {
        return {
            User: User,
        };
    },
};
</script>
