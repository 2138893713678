import {date} from '@/library/filters/date';
import {number} from '@/library/filters/number';
import capitalize from '@/library/filters/capitalize';

export default {
    install(Vue) {
        Vue.filter('digitGroup', number.digitGroup);
        Vue.filter('capitalize', capitalize);
        Vue.filter('dateFrom', date.dateFrom);
        Vue.filter('dateFormat', date.dateFormat);
    },
};