<template>
    <b-card>
        <b-card-title>
            Tooltips
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for adding custom Bootstrap tooltips with CSS and JavaScript using CSS3 for animations and data-attributes for local title storage.
        </p>

        <div class="mb-4">
            <h5>
                Basic
            </h5>

            <p>
                This is what a tooltip looks like.
            </p>

            <preview>
                <b-button
                    id="something-existing"
                    variant="primary"
                >
                    Click me
                </b-button>

                <b-tooltip
                    class="display-block"
                    target="something-existing"
                    :show="true"
                    :triggers="['click']"
                >
                    I am a tooltip!
                </b-tooltip>
            </preview>

            <p>
                Hover over the button below to see the tooltip.
            </p>

            <preview>
                <b-button
                    v-b-tooltip
                    title="I am a tooltip!"
                    variant="primary"
                >
                    Hover me
                </b-button>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Contextual
            </h5>

            <p>
                Tooltips are available in all theme colors
            </p>

            <preview>
                <div
                    v-for="color in themeColors"
                    :key="color"
                    class="d-inline-block mr-1"
                >
                    <b-button
                        :id="`tooltip-button-${color}`"
                        :variant="color"
                    >
                        {{ color }}
                    </b-button>

                    <b-tooltip
                        class="display-block"
                        :target="`tooltip-button-${color}`"
                        :variant="color"
                    >
                        {{ color }} I am a tooltip!
                    </b-tooltip>
                </div>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Tooltips',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
};
</script>
