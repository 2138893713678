
import Vue from 'vue';
import vueI18n from '@/lang/vueI18n';
import ModalComponent from '@/components/common/Modal';

class Modal {
    /**
     * Modal constructor.
     * @param {Object} options
     */
    constructor(options = {}) {
        this.setProperties(options);
    }

    /**
     * Sets default properties.
     */
    setProperties(options = {}) {
        this.triggers = {};

        this.$root = undefined;

        this.loading = false;
        this.error = false;
        this.success = false;

        this.options = Object.assign({
            modalComponent: ModalComponent,
        }, options);
    }

    /**
     * Mount modal.
     */
    mount() {
        let ModalConstructor = Vue.extend({
            ...this.options.modalComponent,
            i18n: vueI18n,
        });

        let node = document.createElement('div');
        document.querySelector('body').appendChild(node);

        let mc = new ModalConstructor({
            data: {
                d_modal: this,
            },
        });

        return mc.$mount(node);
    }

    /**
     * Close modal.
     */
    close() {
        this.destroy();
    }

    /**
     * Destroy modal.
     */
    destroy() {
        let el = this.$root.$el;

        this.$root.$destroy();
        this.$root.$off();

        el.parentNode.removeChild(el);

        this.setProperties();
    }

    /**
     * Checks if specified event is in triggers object. If so call the callback.
     * @param {String} event
     * @param {Array} params
     */
    emit(event, ...params) {
        if (!(event in this.triggers)) return;

        for (let callback of this.triggers[event]) {
            callback(...params);
        }
    }

    /**
     * Adds a callback to the listeners array at the specified event as key.
     * @param {String} event
     * @param {Function} callback
     */
    on(event, callback) {
        if (!(event in this.triggers)) this.triggers[event] = [];

        this.triggers[event].push(callback);
    }

    /*
     * Emits an resolve event.
     * @param {mixed} data
     */
    resolve(data) {
        this.emit('resolve', data);
    }

    /**
     * Show modal.
     */
    show() {
        this.$root = this.mount();

        return this;
    }
}


const modal = {
    install(Vue) {
        Vue.modal = (options = {}) => {
            return new Modal(options);
        };
    },
};

export default modal;
