import User from '@/models/User';
import Show from '@/components/users/Show';
import Index from '@/components/users/Index';
import OwRouterView from '@/components/common/OwRouterView';

let routes = [
    {
        path: '/users',
        component: OwRouterView,
        meta: {
            middleware: ['Auth', 'Policy'],
        },
        children: [
            {
                path: '',
                name: 'users.index',
                component: Index,
                meta: {
                    action: 'view',
                    model: User,
                },
            },
            {
                path: ':id',
                name: 'users.show',
                component: Show,
                meta: {
                    action: 'view',
                    model: User,
                },
            },
        ],
    },
];

export default routes;
