import Model from '@/models/Model';
import vueI18n from '@/lang/vueI18n';
import Policy from '@/library/policies/User';

export default class User extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'users';
    }

    /**
     * The policy of the model.
     */
    static get policy() {
        return Policy;
    }

    /**
     * The url queries of the model.
     */
    static get urlQueries() {
        return {
            orderBy: 'updated_at',
            sortedBy: 'desc',
        };
    }

    /**
     * The search fields of the model to filter on.
     */
    static get searchFields() {
        return ['username', 'first_name', 'last_name', 'federation_number'];
    }

    /**
     * Gets the display name.
     * If present combine first letter of first name and add last name.
     * Else return federation number.
     */
    get displayName() {
        if (this.first_name && this.last_name) {
            const firstName = this.first_name.charAt(0).toUpperCase() + this.first_name.slice(1);
            const lastName = this.last_name.charAt(0).toUpperCase() + this.last_name.slice(1);

            return `${firstName} ${lastName}`;
        } else if (this.federation_number) {
            return `#${this.federation_number}`;
        }

        return vueI18n.t('unknown_user');
    }

    static displayName(user) {
        if (user instanceof User) {
            return user.displayName;
        } else {
            return (new User(user)).displayName;
        }
    }

    /**
     * Gets the roles of a user.
     *
     * @param {Integer|String} id
     */
    static getRoles(id) {
        let url = `${this.endpoint}/${id}/roles`;

        return new Promise((resolve, reject) => {
            this.makeRequest('get', [url]).then((data) => {
                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Update pro status of user.
     *
     * @param {Integer|String} id
     * @param {String} method
     */
    static updatePro(id, method) {
        let url = `${this.endpoint}/${id}/pro`;

        return new Promise((resolve, reject) => {
            this.makeRequest(method, [url]).then((data) => {
                resolve(data);
            }).catch((data) => {
                reject(data);
            });
        });
    }

    /**
     * Validates policy.
     *
     * @param {String} action
     * @param {mixed} subject
     */
    can(action, subject) {
        const Policy = subject instanceof Model ? subject.constructor.policy : subject.policy;

        if (!Policy.before(this, subject)) {
            return false;
        }

        return Policy[action](this, subject);
    }

    /**
     * Validates if user has role.
     *
     * @param {String|Array} role
     */
    hasRole(role) {
        if (!this.roles || this.roles.length === 0) {
            return false;
        }

        if (Array.isArray(role)) {
            for (let value of role) {
                if (this.hasRole(value)) return true;
            }

            return false;
        }

        return this.roles.indexOf(role) > -1;
    }
}
