import Me from '@/library/Me';
import Middleware from '@/library/Middleware';

export default class Policy extends Middleware {
    /**
     * Policy constructor.
     */
    constructor() {
        super();

        this.name = 'Policy';
    }

    /**
     * Handles the middleware.
     * Validates if a policy allows the specified action. Return to the
     * previous page when the user is not allowed.
     *
     * @param {Object} to
     * @param {Object} from
     * @param {Function} next
     */
    handle(to, from, next) {
        if (!this.needsToHandle(to)) {
            return next();
        }

        const me = new Me;

        if (me.can(to.meta.action, to.meta.model)) {
            return next();
        }

        next({
            name: from.name,
            params: from.params,
            query: from.query,
            path: from.path,
        });
    }
}
