import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/concatter';
import Auth from '@/router/middleware/Auth';
import Guest from '@/router/middleware/Guest';
import Policy from '@/router/middleware/Policy';
import AuthOrGuest from '@/router/middleware/AuthOrGuest';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes,
});

router.beforeEach((to, from, next) => new Auth().handle(to, from, next));
router.beforeEach((to, from, next) => new Guest().handle(to, from, next));
router.beforeEach((to, from, next) => new AuthOrGuest().handle(to, from, next));
router.beforeEach((to, from, next) => new Policy().handle(to, from, next));

export default router;
