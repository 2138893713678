<template>
    <b-card>
        <b-card-title>
            Card
        </b-card-title>

        <p class="mb-4">
            Bootstrap’s cards provide a flexible and extensible content container with multiple variants and options.
        </p>

        <div class="mb-4">
            <h5>
                Example
            </h5>

            <p>
                Cards are built with as little markup and styles as possible, but still manage to deliver a ton of control and customization. Built with flexbox, they offer easy alignment and mix well with other Bootstrap components. They have no <code class="highlighter-rouge">margin</code> by default, so use <a href="https://getbootstrap.com/docs/4.4/utilities/spacing/">spacing utilities</a> as needed.
            </p>

            <preview class="bg-light">
                <b-card
                    title="Card Title"
                    img-src="https://picsum.photos/600/300/"
                    img-top
                    style="max-width: 20rem;"
                >
                    <b-card-text>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </b-card-text>

                    <b-button variant="primary">
                        Go somewhere
                    </b-button>
                </b-card>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Header and footer
            </h5>

            <p>
                Add an optional header and/or footer within a card.
            </p>

            <preview class="bg-light">
                <b-card
                    footer="Card footer"
                    header="Card header"
                    title="Card Title"
                    style="max-width: 20rem;"
                >
                    <b-card-text>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </b-card-text>

                    <b-button variant="primary">
                        Go somewhere
                    </b-button>
                </b-card>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Navigation
            </h5>

            <p>
                Add some navigation to a card’s header (or block) with Bootstrap’s <a href="https://getbootstrap.com/docs/4.4/components/navs/">nav components</a>.
            </p>

            <preview class="bg-light">
                <b-card no-body>
                    <b-card-header header-tag="nav">
                        <b-nav
                            card-header
                            tabs
                        >
                            <b-nav-item
                                v-for="item in items"
                                :key="item"
                                :active="item === 'Active'"
                                :disabled="item === 'Disabled'"
                            >
                                {{ item }}
                            </b-nav-item>
                        </b-nav>
                    </b-card-header>

                    <b-card-body>
                        <b-card-title>
                            Card title
                        </b-card-title>

                        <b-card-text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </b-card-text>

                        <b-button variant="primary">
                            Go somewhere
                        </b-button>
                    </b-card-body>
                </b-card>
            </preview>

            <preview class="bg-light">
                <b-card no-body>
                    <b-card-header header-tag="nav">
                        <b-nav
                            card-header
                            pills
                        >
                            <b-nav-item
                                v-for="item in items"
                                :key="item"
                                :active="item === 'Active'"
                                :disabled="item === 'Disabled'"
                            >
                                {{ item }}
                            </b-nav-item>
                        </b-nav>
                    </b-card-header>

                    <b-card-body>
                        <b-card-title>
                            Card title
                        </b-card-title>

                        <b-card-text>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </b-card-text>

                        <b-button variant="primary">
                            Go somewhere
                        </b-button>
                    </b-card-body>
                </b-card>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Contextual
            </h5>

            <p>
                Cards are available in all theme colors
            </p>

            <preview class="bg-light">
                <b-row
                    v-for="color in themeColors"
                    :key="color"
                    class="mb-1"
                >
                    <b-col>
                        <b-card
                            :bg-variant="color"
                            :text-variant="['light'].includes(color) ? '' : 'white'"
                            header="Header"
                            :title="`${color} card title`"
                        >
                            <b-card-text>
                                Some quick example text to build on the card title and make up the bulk of the card's content.
                            </b-card-text>
                        </b-card>
                    </b-col>

                    <b-col>
                        <b-card
                            :border-variant="color"
                            header="Header"
                            :header-border-variant="color"
                            :text-variant="['light'].includes(color) ? '' : color"
                            :title="`${color} card title`"
                        >
                            <b-card-text>
                                Some quick example text to build on the card title and make up the bulk of the card's content.
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Card',
    components: {Preview},
    data() {
        return {
            themeColors,
            items: [
                'Active',
                'Inactive',
                'Disabled',
            ],
        };
    },
};
</script>
