<template>
    <b-card>
        <b-card-title>
            Toasts
        </b-card-title>

        <p class="mb-4">
            Push notifications to your visitors with a toast, a lightweight and easily customizable alert message.
        </p>

        <div class="mb-4">
            <h5>
                Basic
            </h5>

            <p>
                To encourage extensible and predictable toasts, we recommend a header and body. Toast headers use <code class="highlighter-rouge">display: flex</code>, allowing easy alignment of content thanks to our margin and flexbox utilities.
            </p>

            <preview>
                <div
                    v-for="i in 2"
                    :key="i"
                    class="toast show"
                >
                    <div class="toast-header">
                        <b-img
                            class="mr-2 rounded"
                            blank-color="#007aff"
                            v-bind="{ blank: true, widht: 20, height: 20 }"
                        />

                        <strong class="mr-auto">
                            Bootstrap
                        </strong>

                        <small>
                            11 mins ago
                        </small>

                        <button class="ml-2 mb-1 close">
                            <span aria-hidden="true">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div class="toast-body">
                        Hello, world! This is a toast message.
                    </div>
                </div>
            </preview>
        </div>

        <div>
            <h5>
                Contextual
            </h5>

            <p>
                Display toast messages in all theme colors.
            </p>

            <preview>
                <b-button
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    :variant="color"
                    @click="makeToast(color)"
                >
                    {{ color }}
                </b-button>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Toasts',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
    methods: {
        makeToast(color) {
            this.$bvToast.toast(
                `This is ${color} toast body.`,
                {
                    solid: true,
                    title: `${color} toast title`,
                    variant: color,
                },
            );
        },
    },
};
</script>
