export default {
    handicap_type: {
        ZERO: '0% ',
        QUARTER: '25%',
        HALF: '50%',
        THREE_QUARTER: '75%',
        FULL: '100% ',
    },
    league_status: {
        WAITING_FOR_TEAMS: 'waiting for teams',
        WAITING_FOR_MATCHDAYS: 'waiting for matchdays',
        PLAYABLE: 'playable',
        ENDED: 'ended',
    },
    match_type: {
        SINGLE: 'Single',
        FOURBBB: '4BBB',
    },
    // Usually the enum key should be in uppercase, but this is an exception
    // because WAMP API use lowercase for the roles.
    role: {
        admin: 'Club captain',
        user: 'Golfer',
    },
    team_match_format_type: {
        FOURBBB: '2 Players (4BBB)',
        SINGLE: '1 Players (Single)',
        SINGLE_SINGLE: '2 Players (Single, Single)',
        SINGLE_SINGLE_FOURBBB: '4 Players (Single, Single, 4BBB)',
    },
};
