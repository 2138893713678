<template>
    <div class="auth-password-forgot positon-relative">
        <form
            v-if="!d_form.success"
            class="auth-password-forgot-form"
            @submit.prevent="submit"
        >
            <h1
                v-trans
                class="h3"
            >
                auth.password.forgot.title
            </h1>

            <p
                v-trans
                class="mb-0"
            >
                auth.password.forgot.text
            </p>

            <fieldset class="py-5">
                <form-group
                    v-model="d_form.data.email"
                    :error="d_form.errors.first('email')"
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    type="email"
                />
            </fieldset>

            <div class="text-center mb-3">
                <btn
                    :error="d_form.error"
                    :loading="d_form.loading"
                    :success="d_form.success"
                    class="btn-primary"
                >
                    {{ $t('form.submit') }}
                </btn>
            </div>
        </form>

        <div v-else>
            <h1
                v-trans
                class="h3"
            >
                auth.password.forgot.success.title
            </h1>

            <p
                v-trans
                class="py-5 mb-0"
            >
                auth.password.forgot.success.text
            </p>
        </div>

        <div class="text-center">
            <router-link
                :to="{name: 'auth.login'}"
                class="btn btn-link"
            >
                {{ $t('auth.password.back_to_login') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import Form from '@/library/Form';
import FormGroup from '@/components/common/form/Group';

export default {
    name: 'AuthPasswordForgot',
    components: {FormGroup},
    data() {
        return {
            d_form: new Form({
                email: '',
            }),
        };
    },
    methods: {
        submit() {
            this.d_form.post('/auth/password/email');
        },
    },
};
</script>
