<template>
    <div class="cups-edit position-relative">
        <div class="top-edge clearfix">
            <router-link
                v-if="d_form.data.id"
                :to="{name: 'cups.show', params: { id: d_form.data.id }}"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>
        </div>

        <div class="row justify-content-center py-5">
            <loader
                v-if="d_loading"
                :loading="true"
                class="w-100 text-center"
            />

            <div
                v-else-if="d_form.data.id"
                class="col-md-8 col-lg-6"
            >
                <div class="card shadow-sm">
                    <div class="card-body">
                        <cups-form
                            :data="d_form.data"
                            :errors="d_form.errors"
                            :loading="d_form.loading"
                            :loading-delete="d_loadingDelete"
                            class="form-groups-divided"
                            @cfd_change="cfd_onChange"
                            @delete="onDelete"
                            @submit="submit"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cup from '@/models/Cup';
import User from '@/models/User';
import Form from '@/library/Form';
import CupUser from '@/models/CupUser';
import Redirect from '@/library/Redirect';
import CupsForm from '@/components/cups/Form';
import confirmFormDiscard from '@/mixins/confirmFormDiscard';

export default {
    name: 'CupsEdit',
    components: {CupsForm},
    mixins: [confirmFormDiscard],
    data() {
        return {
            d_form: new Form,
            d_loading: false,
            d_loadingDelete: false,
        };
    },
    created() {
        this.d_form.addModifier('players', Cup.modifyPlayers);
    },
    mounted() {
        const id = this.$route.params.id;

        this.d_loading = true;

        // First get cup.
        Cup.find(id).then(({data}) => {
            this.d_form.data = data;

            const url = `${Cup.endpoint}/${data.id}/users`;

            // Then get the cup users for the cup.
            CupUser.get(url).then(({data}) => {
                // Map the cup users to users.
                this.d_form.data.players = data.map((data) => {
                    let newData = data;

                    if (!data.user) {
                        newData = new User({
                            federation_number: data.federation_number,
                            email: data.email,
                            first_name: data.first_name,
                            last_name: data.last_name,
                        });
                    } else {
                        newData = data.user;
                    }

                    return newData;
                });

                this.d_loading = false;
            });
        });
    },
    methods: {
        submit(data) {
            this.d_form.data = data;

            const url = `${Cup.endpoint}/${data.id}`;

            this.d_form.put(url).then(({data}) => {
                this.cfd_dataChanged = false;

                Redirect.to({name: 'cups.show', params: {id: data.id}});
            });
        },
        onDelete() {
            const confirm = this.$helpers.confirm().show();

            confirm.on('resolve', () => {
                const cup = new Cup({
                    id: this.d_form.data.id,
                });

                this.d_loadingDelete = confirm.loading = true;

                cup.delete().then(() => {
                    this.d_loadingDelete = confirm.loading = false;

                    confirm.close();

                    Redirect.to({name: 'cups.index'});
                });
            });
        },
    },
};
</script>
