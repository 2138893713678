<template>
    <b-card>
        <b-card-title>
            Alerts
        </b-card-title>

        <p class="mb-4">
            Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
        </p>

        <div class="mb-4">
            <h5>
                Contextual
            </h5>

            <p>
                Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the eight <strong>required</strong> contextual classes (e.g., <code class="highlighter-rouge">.alert-success</code>).
            </p>

            <preview>
                <b-alert
                    v-for="color in themeColors"
                    :key="color"
                    show
                    :variant="color"
                >
                    A simple {{ color }} alert - check it out!
                </b-alert>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Link color
            </h5>

            <p>
                Use the <code class="highlighter-rouge">.alert-link</code> utility class to quickly provide matching colored links within any alert.
            </p>

            <preview>
                <b-alert
                    v-for="color in themeColors"
                    :key="color"
                    show
                    :variant="color"
                >
                    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                    A simple {{ color }} alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
                </b-alert>
            </preview>
        </div>

        <div>
            <h5>
                Additional content
            </h5>

            <p>
                Alerts can also contain additional HTML elements like headings, paragraphs and dividers.
            </p>

            <preview>
                <b-alert
                    show
                    variant="primary"
                >
                    <h4 class="alert-heading">
                        Well done!
                    </h4>

                    <p>
                        Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of
                        content.
                    </p>

                    <hr>

                    <p class="mb-0">
                        Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
                    </p>
                </b-alert>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Alerts',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
};
</script>
