import Enum from './Enum';

const NEWS = 0;

const PAST_WINNER = 1;

const LOCATION = 2;

export default class PostType extends Enum {
    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            NEWS,
            PAST_WINNER,
        };
    };

    static NEWS = NEWS;
    static PAST_WINNER = PAST_WINNER;
    static LOCATION = LOCATION;

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'post_type';
    }
};
