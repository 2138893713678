<template>
    <b-card>
        <b-card-title>
            Popover
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for adding Bootstrap popovers, like those found in iOS, to any element on your site.
        </p>

        <div>
            <h5>
                Example
            </h5>

            <p>
                Not much to say.
            </p>

            <preview>
                <b-button
                    v-b-popover.click.right="'And here\'s some amazing content. It\'s very engaging. Right?'"
                    title="Popover title"
                    variant="primary"
                >
                    Click to toggle popover
                </b-button>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Popover',
    components: {Preview},
};
</script>
