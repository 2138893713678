export default {
    // Common
    model: {
        draw_points: {
            label: 'AS',
        },
        handicap_type: {
            label: 'Handicap rule',
        },
        league_groups: {
            label: 'Groups',
        },
        league_group_teams: {
            label: 'Teams',
        },
        lose_points: {
            label: 'Lose',
        },
        team_match_format_type: {
            label: 'Playing formula',
        },
        win_points: {
            label: 'Wins',
        },
    },
    actions: {
        generate_matchdays: 'Generate matchdays',
        submit_teamsheet: 'Submit teamsheet',
        input_teams: 'Input teams',
    },
    messages: {
        can_generate_matchdays: 'If the league\'s teams have been inputted, you can click the "Generate matchdays" button above and the league\'s match schedules will be generated.',
        input_teams_then_generate_matchdays: 'Input all teams and then schedule can be generated.',
        league_not_started: 'The league has not started yet.',
        no_league_team_user: 'Sorry, you are not a member of any team in this league.',
        no_upcoming_matchday: 'No upcoming matchday is found for this league.',
        schedule_not_ready: 'The league\'s schedule is still being created.',
        should_be_status: 'This page or part of the page could not be shown because the league does not have the "{status}" status.',
        update_schedule_time_location: 'Update schedule with time and location information.',
        verify_match_scores: 'View and verify match scores.',
        verify_match_scores_instruction: 'If a match is colored red, that means it might have incorrect scores. Please verify them.',
    },

    // Routes
    leaderboard: {
        title: 'Club Ranking',
    },
    'my-team': {
        title: 'My Team',
        teamsheet_title: 'Select your team members',
        table_title: 'Team members',
        already_played: 'Match has been played',
    },
    news: {
        index: {
            title: 'News',
            noPostsText: 'Currently there are no news items for this league.',
        },
    },
    players: {
        title: 'Player Ranking',
    },
    schedule: {
        index: {
            title: 'Match Results',
            league_matchday_at: 'Play at {0}',
            league_matchday_between: 'Play between {0} and {1}',
            league_matchday_ends_at: 'Ends at {0}',
            league_matchday_starts_at: 'Starts at {0}',
        },
        edit: {
            title: 'League Matchdays',
        },
    },
    teams: {
        index: {
            title: 'All Teams',
        },
        edit: {
            title: 'Edit Teams',
            no_teams_added: 'No teams have been added.',
        },
    },
};
