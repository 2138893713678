<template>
    <div class="not-found text-white">
        <div class="row justify-content-center mb-4">
            <div class="col-md-8 text-center">
                <div class="row justify-content-center">
                    <div
                        v-trans
                        class="col-md-10 h2 font-weight-light mt-5 mb-3"
                    >
                        not_found.info.head
                    </div>

                    <div
                        v-trans
                        class="col-md-5 mb-5"
                    >
                        not_found.info.body
                    </div>
                </div>

                <btn
                    v-trans
                    class="btn-primary"
                    @click="goBack"
                >
                    not_found.action.back
                </btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    watch: {
        '$route'() {
            this.$store.$emit('showNotFound', false);
        },
    },
    mounted() {
        if (!document.body.classList.contains('bg-secondary')) {
            document.body.classList.add('bg-secondary');
        }
    },
    beforeDestroy() {
        if (document.body.classList.contains('bg-secondary')) {
            document.body.classList.remove('bg-secondary');
        }
    },
    methods: {
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/');
        },
    },
};
</script>
