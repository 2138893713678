<template>
    <form
        v-if="d_data"
        class="fieldsets-divided"
        @submit.prevent="$emit('submit', d_data)"
    >
        <fieldset>
            <div class="row">
                <div class="col-12 my-2">
                    <ow-img
                        v-if="d_data"
                        :src="d_data"
                        class="user-image rounded-circle"
                        src-key="image.140"
                    />

                    <img
                        v-else
                        :src="var_placeholderSrc"
                        class="user-image rounded-circle"
                    >
                </div>

                <form-group
                    v-model="d_data.username"
                    :disabled="disabled"
                    :error="errors.first('username')"
                    :label="$t('users.form.labels.username')"
                    :placeholder="$t('users.form.placeholders.username')"
                    class="col-12"
                />

                <form-group
                    v-model="d_data.first_name"
                    :disabled="disabled"
                    :error="errors.first('first_name')"
                    :label="$t('users.form.labels.first_name')"
                    :placeholder="$t('users.form.placeholders.first_name')"
                    class="col-6"
                />

                <form-group
                    v-model="d_data.last_name"
                    :disabled="disabled"
                    :error="errors.first('last_name')"
                    :label="$t('users.form.labels.last_name')"
                    :placeholder="$t('users.form.placeholders.last_name')"
                    class="col-6"
                />

                <form-group
                    v-model="d_data.email"
                    :disabled="disabled"
                    :error="errors.first('email')"
                    :label="$t('users.form.labels.email')"
                    :placeholder="$t('users.form.placeholders.email')"
                    class="col-12"
                    type="email"
                />

                <form-group
                    v-model="d_data.federation_number"
                    :disabled="disabled"
                    :error="errors.first('federation_number')"
                    :label="$t('users.form.labels.federation_number')"
                    :placeholder="$t('users.form.placeholders.federation_number')"
                    class="col-6"
                />

                <form-group
                    v-model="d_data.handicap"
                    :disabled="disabled"
                    :error="errors.first('handicap')"
                    :label="$t('users.form.labels.handicap')"
                    :placeholder="$t('users.form.placeholders.handicap')"
                    :step="0.1"
                    class="col-6"
                    type="number"
                />

                <form-group
                    :error="errors.first('course_id')"
                    :label="$t('home_course')"
                    class="col-12"
                >
                    <loader
                        v-if="d_loading"
                        slot="input"
                        :loading="true"
                        class="w-100 text-center"
                    />

                    <auto-complete
                        v-else
                        slot="input"
                        v-model="d_data.course_id"
                        :query-placeholder="d_course ? d_course.name : undefined"
                        :url="d_course.constructor.endpoint"
                        label-key="name"
                        value-key="id"
                    />
                </form-group>
            </div>
        </fieldset>

        <fieldset>
            <div class="row">
                <form-group
                    :error="errors.first('pro_at')"
                    :show-label="false"
                    class="col-6"
                >
                    <checkbox
                        slot="input"
                        v-model="d_proAt"
                        :label="$t('users.is_pro')"
                        class="form-control border-0"
                    />
                </form-group>

                <form-group
                    :show-label="false"
                    class="col-6"
                >
                    <div
                        slot="input"
                        class="d-flex align-items-center clearfix form-control"
                    >
                        <span
                            v-trans
                            class="font-weight-bold text-uppercase"
                        >
                            users.is_verified
                        </span>

                        <i
                            :class="[ d_data.verified_at ? 'fa-check-circle text-success' : 'fa-times-circle text-danger']"
                            class="fa fa-lg ml-auto"
                        />
                    </div>
                </form-group>

                <form-group
                    :disabled="true"
                    :label="$t('users.form.labels.created_at')"
                    :value="d_data.created_at | moment('DD-MM-YYYY')"
                    class="col-6"
                />

                <form-group
                    :disabled="true"
                    :label="$t('users.form.labels.updated_at')"
                    :value="d_data.updated_at | moment('DD-MM-YYYY')"
                    class="col-6"
                />

                <form-group
                    :disabled="true"
                    :label="$t('users.form.labels.matches')"
                    :value="d_data.match_count"
                    class="col-6"
                />

                <form-group
                    :disabled="true"
                    :label="$t('users.form.labels.followers')"
                    :value="d_data.follower_count"
                    class="col-3"
                />

                <form-group
                    :disabled="true"
                    :label="$t('users.form.labels.following')"
                    :value="d_data.following_count"
                    class="col-3"
                />

                <div class="col">
                    <span
                        v-trans
                        class="font-weight-bold text-uppercase pb-3 d-block"
                    >
                        users.roles
                    </span>

                    <div
                        v-for="role in d_roles"
                        :key="role"
                        class="mb-2"
                    >
                        <form-group
                            :error="errors.first('roles')"
                            :show-label="false"
                        >
                            <checkbox
                                slot="input"
                                v-model="d_data.roles"
                                :label="role | capitalize"
                                :name="role"
                            />
                        </form-group>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset v-if="d_data.id && d_me.can('update', User)">
            <div class="form-group">
                <btn
                    :loading="loading"
                    class="btn btn-primary"
                >
                    {{ $t('users.action.save') }}
                </btn>
            </div>
        </fieldset>
    </form>
</template>

<script>
import moment from 'moment';
import Me from '@/library/Me';
import User from '@/models/User';
import Course from '@/models/Course';
import formProps from '@/library/mixins/formProps';
import FormGroup from '@/components/common/form/Group';
import Checkbox from '@/components/common/form/Checkbox';
import AutoComplete from '@/components/common/form/AutoComplete';

export default {
    name: 'UsersForm',
    components: {AutoComplete, Checkbox, FormGroup},
    mixins: [formProps],
    props: {
        data: {
            type: Object,
            default: () => new Object(), // eslint-disable-line no-new-object
        },
    },
    data() {
        return {
            User: User,
            d_course: new Course,
            d_data: this.data,
            d_loading: false,
            d_me: new Me,
            d_proAt: !!this.data.pro_at,
            d_roles: ['admin', 'editor', 'club_manager'],
        };
    },
    watch: {
        d_data: {
            handler() {
                this.$emit('input', this.d_data);
            },
            deep: true,
        },
        d_proAt() {
            this.d_data.pro_at = this.d_proAt ? moment().format('DD-MM-YYYY') : null;
        },
        value() {
            this.d_data = this.value;
        },
    },
    mounted() {
        if (this.d_data.course_id) {
            this.findCourse();
        }
    },
    methods: {
        findCourse() {
            this.d_loading = true;

            Course.find(this.d_data.course_id)
                .then(({data}) => {
                    this.d_course = data;

                    this.d_loading = false;
                });
        },
        hasRole(role) {
            return (this.d_data.roles && this.d_data.roles.includes(role));
        },
    },
};
</script>

<style lang="scss" scoped>
.user-image {
    width: 5rem;
    height: 5rem;
}
</style>
