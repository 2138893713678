<template>
    <div class="courses-create position-relative">
        <div class="top-edge clearfix">
            <router-link
                :to="{name: 'courses.index'}"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>

            <div
                v-if="d_form.data.id && d_me.can('update', Course)"
                class="header-button float-right"
            >
                <btn
                    :loading="d_form.loading"
                    class="btn btn-primary"
                    @click="submit"
                >
                    {{ $t('courses.action.save') }}
                </btn>
            </div>

            <div
                v-if="d_form.data.id && d_me.can('delete', Course)"
                class="header-button float-right mx-3"
            >
                <btn
                    :loading="d_loadingDelete"
                    class="btn btn-danger"
                    @click="onDelete"
                >
                    {{ $t('courses.action.delete') }}
                </btn>
            </div>
        </div>

        <div class="row justify-content-center mb-4">
            <div class="col-md-12">
                <loader
                    v-if="d_loading"
                    :loading="true"
                    class="w-100 text-center"
                />

                <courses-form
                    v-else-if="d_form.data.id"
                    v-model="d_form.data"
                    :disabled="!d_me.can('update', Course)"
                    :errors="d_form.errors"
                    :loading="d_form.loading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Me from '@/library/Me';
import Form from '@/library/Form';
import Course from '@/models/Course';
import Redirect from '@/library/Redirect';
import CoursesForm from '@/components/courses/Form';
import navbarTitle from '@/library/mixins/navbarTitle';

export default {
    name: 'CoursesShow',
    components: {CoursesForm},
    mixins: [navbarTitle],
    data() {
        return {
            Course: Course,
            d_form: new Form({
                backNine: false,
                tees: [],
            }),
            d_loading: false,
            d_loadingDelete: false,
            d_me: new Me,
        };
    },
    mounted() {
        this.d_navbarTitle = this.$t('courses');

        const id = this.$route.params.id;

        this.d_loading = true;

        Course.find(id).then(({data}) => {
            this.d_form.data = data;

            const backNine = data.tees.length && data.tees[0].holes.length > 9;
            this.$set(this.d_form.data, 'backNine', backNine);

            this.d_navbarTitle = data.name;

            this.d_loading = false;
        }).catch(() => {
            this.$store.$emit('showNotFound', true);
        });
    },
    created() {
        this.d_form.addModifier('tees', Course.modifyHoles);
    },
    methods: {
        onDelete() {
            const confirm = this.$helpers.confirm().show();

            confirm.on('resolve', () => {
                const course = new Course({
                    id: this.d_form.data.id,
                });

                this.d_loadingDelete = confirm.loading = true;

                course.delete().then(() => {
                    this.d_loadingDelete = confirm.loading = false;

                    confirm.close();

                    Redirect.to({name: 'courses.index'});
                });
            });
        },
        submit() {
            const url = `${Course.endpoint}/${this.d_form.data.id}`;

            this.d_form.put(url).then(() => {
                Redirect.to({name: 'courses.index'});
            }).catch((e) => {});
        },
    },
};
</script>
