import Vue from 'vue';
import Errors from '@/library/Errors';
import ApiToken from '@/library/ApiToken';

export default class Request {
    /**
     * Request constructor.
     */
    constructor() {
        this.error = false;
        this.errors = new Errors();
        this.loading = false;
        this.success = false;
        this.modifiers = [];
    }

    /**
     * Send a GET request to the given URL.
     *
     * @param {string} url
     */
    get(url) {
        return this.submit('get', url);
    }

    /**
     * Send a POST request to the given URL.
     *
     * @param {string} url
     * @param {object} data
     */
    post(url, data = {}) {
        return this.submit('post', url, data);
    }

    /**
     * Send a PUT request to the given URL.
     *
     * @param {string} url
     * @param {object} data
     */
    put(url, data = {}) {
        return this.submit('put', url, data);
    }

    /**
     * Send a PATCH request to the given URL.
     *
     * @param {string} url
     * @param {object} data
     */
    patch(url, data = {}) {
        return this.submit('patch', url, data);
    }

    /**
     * Send a DELETE request to the given URL.
     *
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }

    /**
     * Builds the url.
     *
     * @param {string} url
     */
    buildUrl(url) {
        return process.env.VUE_APP_API_URL + '/' + url.replace(/^\//, ''); // eslint-disable-line no-undef
    }

    /**
     * Submit the request.
     *
     * @param {string} type
     * @param {string} url
     * @param {object} data
     */
    submit(type, url, data) {
        this.error = false;
        this.loading = true;
        this.success = false;

        let parameters = [
            this.buildUrl(url),
            this.getHeaders(),
        ];

        if (['get', 'delete'].indexOf(type) === -1) {
            let copy = JSON.parse(JSON.stringify(data));

            copy = this.applyModifiers(copy);

            parameters.splice(1, 0, copy);
        }

        return new Promise((resolve, reject) => {
            Vue.http[type](...parameters)
                .then(response => {
                    this.onSuccess(response.data);

                    resolve(response);
                }).catch(response => {
                    response.data = this.onError(response.data);

                    reject(response);
                });
        });
    }

    /**
     * Adds a modifier to the modifiers array.
     *
     * @param {string} key
     * @param {closure} callback
     */
    addModifier(key, callback) {
        this.modifiers.push({
            key,
            callback,
        });
    }

    /**
     * Applies the available modifiers.
     *
     * @param {object} data
     */
    applyModifiers(data) {
        if (!this.modifiers.length) {
            return data;
        }

        for (let modifier of this.modifiers) {
            const key = modifier.key;

            data[key] = modifier.callback(data[key], data);
        }

        return data;
    }

    /**
     * When a request has been successfully.
     *
     * @param {mixed} data
     * @returns {Object}
     */
    onSuccess() {
        this.errors = new Errors();
        this.loading = false;
        this.success = true;
    }

    /**
     * When a request has received an error.
     *
     * @param {mixed} data
     */
    onError(data) {
        if (!data) return;

        if (data.hasOwnProperty('errors')) {
            this.errors.record(data.errors);
        } else {
            this.errors.record(data);
        }

        data.errors = this.errors;

        this.loading = false;
        this.error = true;

        return data;
    }

    /**
     * Get headers.
     */
    getHeaders() {
        let headers = {};

        let apiToken = new ApiToken;

        if (apiToken.getToken()) {
            headers.Authorization = `Bearer ${apiToken.getToken()}`;
        }

        return {headers};
    }
}
