<template>
    <div class="statistics-show position-relative">
        <div class="top-edge clearfix">
            <router-link
                :to="{ name: 'statistics.index' }"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>
        </div>

        <div class="row justify-content-center py-5">
            <chart
                v-if="d_statistics"
                :data="d_statistics.count"
                :labels="d_statistics.months"
                :model="d_model"
                :title="d_title"
                :width="1000"
                class="col-10"
            />
        </div>

        <div class="row justify-content-center">
            <statistics-users-table
                v-if="d_showTable"
                :loading="pos_loading"
                :rows="pos_rows"
                class="table-hover"
            />
        </div>
    </div>
</template>

<script>
import Statistics from '@/models/Statistic';
import Chart from '@/components/statistics/Chart';
import paginatesOnScroll from '@/library/mixins/paginatesOnScroll';
import StatisticsUsersTable from '@/components/statistics/users/Table';

export default {
    name: 'StatisticsShow',
    components: {Chart, StatisticsUsersTable},
    mixins: [paginatesOnScroll],
    data() {
        return {
            d_model: this.$route.params.model,
            d_showTable: false,
            d_statistics: undefined,
            d_title: this.$t('tpm'),
            pos_url: undefined,
            pos_disabled: true,
        };
    },
    created() {
        this.d_title = this.d_title.replace('$model', this.d_model);

        Statistics.find(this.d_model)
            .then(({data}) => {
                this.d_statistics = data;
            });

        if (this.d_model == 'users') {
            this.pos_disabled = false;
            this.pos_url = 'admin/statistics/user_match?orderBy=matches_count&sortBy=desc';
            this.d_showTable = true;
        }
    },
    methods: {
        pos_success({data}) {
            this.pos_rows = [...this.pos_rows, ...data.data];
        },
    },
};
</script>
