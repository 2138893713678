<template>
    <div class="border mb-3 p-3">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Preview',
};
</script>
