<template>
    <tr class="statistics-table-row cursor-pointer">
        <td v-if="columns.includes('model')">
            {{ row.model | capitalize }}
        </td>

        <td v-if="columns.includes('count')">
            {{ row.count | digitGroup }}
        </td>
    </tr>
</template>

<script>
import Statistic from '@/models/Statistic';
import TableRow from '@/components/common/TableRow';

export default {
    name: 'StatisticsTableRow',
    extends: TableRow,
    props: {
        row: {
            type: Statistic,
            required: true,
        },
    },
};
</script>
