<template>
    <b-card>
        <b-card-title>
            Input groups
        </b-card-title>

        <p class="mb-4">
            Easily extend form controls by adding text, buttons, or button groups on either side of textual inputs, custom selects, and custom file inputs.
        </p>

        <div class="mb-4">
            <h5>
                Basic example
            </h5>

            <p>
                Place one add-on or button on either side of an input. You may also place one on both sides of an input. Remember to place <code class="highlighter-rouge">&lt;label&gt;</code>s outside the input group.
            </p>

            <preview>
                <b-input-group
                    class="mb-1"
                    prepend="@"
                >
                    <b-form-input placeholder="Username" />
                </b-input-group>

                <b-input-group
                    append="@example.com"
                    class="mb-1"
                >
                    <b-form-input placeholder="Recipient's username" />
                </b-input-group>

                <b-input-group
                    append=".00"
                    class="mb-1"
                    prepend="$"
                >
                    <b-form-input />
                </b-input-group>

                <b-input-group prepend="With textarea">
                    <b-form-textarea />
                </b-input-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Sizing
            </h5>

            <p>
                Add the relative form sizing classes to the <code class="highlighter-rouge">.input-group</code> itself and contents within will automatically resize—no need for repeating the form control size classes on each element.
            </p>

            <preview>
                <b-input-group
                    class="mb-1"
                    prepend="Small"
                    size="sm"
                >
                    <b-form-input />
                </b-input-group>

                <b-input-group
                    class="mb-1"
                    prepend="Default"
                >
                    <b-form-input />
                </b-input-group>

                <b-input-group
                    prepend="Large"
                    size="lg"
                >
                    <b-form-input />
                </b-input-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Multiple inputs
            </h5>

            <p>
                While multiple <code class="highlighter-rouge">&lt;input&gt;</code>s are supported visually, validation styles are only available for input groups with a single <code class="highlighter-rouge">&lt;input&gt;</code>.
            </p>

            <preview>
                <b-input-group prepend="First and last name">
                    <b-form-input />

                    <b-form-input />
                </b-input-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Multiple addons
            </h5>

            <p>
                Multiple add-ons are supported and can be mixed with checkbox and radio input versions.
            </p>

            <preview>
                <b-input-group class="mb-1">
                    <b-input-group-prepend is-text>
                        $
                    </b-input-group-prepend>

                    <b-input-group-prepend is-text>
                        0.00
                    </b-input-group-prepend>

                    <b-form-input />
                </b-input-group>

                <b-input-group>
                    <b-form-input />

                    <b-input-group-append is-text>
                        $
                    </b-input-group-append>

                    <b-input-group-append is-text>
                        0.00
                    </b-input-group-append>
                </b-input-group>
            </preview>
        </div>

        <div>
            <h5>
                Button addons
            </h5>

            <preview>
                <b-input-group class="mb-1">
                    <b-input-group-prepend>
                        <b-button variant="outline-secondary">
                            Button
                        </b-button>
                    </b-input-group-prepend>

                    <b-form-input />
                </b-input-group>

                <b-input-group class="mb-1">
                    <b-form-input />

                    <b-input-group-append>
                        <b-button variant="outline-secondary">
                            Button
                        </b-button>
                    </b-input-group-append>
                </b-input-group>

                <b-input-group class="mb-1">
                    <b-input-group-prepend>
                        <b-button variant="outline-secondary">
                            Button
                        </b-button>

                        <b-button variant="outline-secondary">
                            Button
                        </b-button>
                    </b-input-group-prepend>

                    <b-form-input />
                </b-input-group>

                <b-input-group>
                    <b-form-input />

                    <b-input-group-append>
                        <b-button variant="outline-secondary">
                            Button
                        </b-button>

                        <b-button variant="outline-secondary">
                            Button
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'InputGroups',
    components: {Preview},
};
</script>
