export class MatchVersus {
    /**
     * The MatchVersus constructor.
     */
    constructor(teams = [], isDraw = false) {
        this.teams = teams;

        // A boolean to indicate if the match resulted in a draw.
        this.isDraw = isDraw;
    }
};

export class MatchVersusTeam {
    /**
     * The MatchVersusTeam constructor.
     */
    constructor(participants = [], score = null, isWinner = false) {
        this.participants = participants;

        this.score = score;

        // A boolean to indicate if this team is the winner of the match.
        this.isWinner = isWinner;
    }
};

export class MatchVersusParticipant {
    /**
     * The MatchVersusParticipant constructor.
     */
    constructor(name, image) {
        this.name = name;

        this.image = image;
    }
};
