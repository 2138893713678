<template>
    <div
        :class="{ 'is-invalid': errors.has('players') }"
        class="cup-users-form-group form-group"
    >
        <template v-if="!disabled">
            <label v-trans>
                federation_numbers
            </label>

            <div
                v-trans
                class="form-text"
            >
                cup_users.form_group.explanation
            </div>

            <div class="file-input-group input-group pt-3">
                <file-input
                    ref="fileInput"
                    v-model="d_fileForm.data.file"
                    :class="{ 'is-invalid': d_fileForm.errors.has('file') }"
                />

                <div
                    v-if="d_fileForm.errors.has('file')"
                    class="invalid-feedback order-1"
                >
                    {{ d_fileForm.errors.first('file') }}
                </div>

                <div class="input-group-append">
                    <btn
                        :loading="d_fileForm.loading"
                        class="btn-primary"
                        element="div"
                        @click="upload"
                    >
                        {{ $t('upload') }}
                    </btn>
                </div>
            </div>

            <div class="mb-3">
                <a
                    :href="d_sheetLink"
                    class="small"
                    target="_blank"
                >
                    {{ $t('download') }} wamp_cup_players_sheet.xlsx
                </a>
            </div>

            <div class="input-group py-3">
                <input
                    v-model="d_form.data.federation_number"
                    :class="{ 'is-invalid': d_form.errors.has('federation_number') }"
                    class="form-control"
                    placeholder="0000000000000"
                    type="text"
                    @keydown.enter.prevent="submit"
                >

                <div
                    v-if="d_form.errors.has('federation_number')"
                    class="invalid-feedback order-1"
                >
                    {{ d_form.errors.first('federation_number') }}
                </div>

                <div class="input-group-append">
                    <btn
                        :loading="d_form.loading"
                        class="btn-primary"
                        element="div"
                        @click="submit"
                    >
                        {{ $t('add') }}
                    </btn>
                </div>
            </div>
        </template>

        <div class="clearfix pt-3">
            <label class="small float-right text-right">
                <span
                    v-trans
                    class="text-muted mr-3"
                >
                    player_count
                </span>

                {{ d_value.length }}

                <small
                    v-if="isBcko"
                    class="d-block text-muted"
                >
                    {{ $t('cups.form.players.bcko_min_max') }}
                </small>
            </label>
        </div>

        <users-table
            :headers="c_headers"
            :rows="d_value"
            :show-headers="false"
        >
            <template #noResults>
                {{ $t('cup_users.table.no_results') }}
            </template>

            <template #row="{columns, index, row}">
                <table-row
                    :columns="columns"
                    :error="userError(index)"
                    :row="row"
                    @changeDetails="showDetailsModal(row, index)"
                    @delete="removeCupUser(index)"
                />
            </template>
        </users-table>

        <div
            v-if="errors.has('players')"
            class="invalid-feedback"
        >
            {{ errors.first('players') }}
        </div>
    </div>
</template>

<script>
import Form from '@/library/Form';
import Errors from '@/library/Errors';
import UsersTable from '@/components/cups/users/Table';
import TableRow from '@/components/cups/users/TableRow';
import FileInput from '@/components/common/form/FileInput';
import DetailsModal from '@/components/cups/users/DetailsModal';

export default {
    name: 'CupsUsersFormGroup',
    components: {FileInput, UsersTable, TableRow},
    props: {
        disabled: Boolean,
        errors: {
            type: Errors,
            default: () => new Errors(),
        },
        isBcko: Boolean,
        value: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            d_value: this.value,
            d_form: new Form({
                federation_number: '',
            }),
            d_fileForm: new Form({
                file: undefined,
            }),
            d_sheetLink: '',
        };
    },
    computed: {
        c_headers() {
            const headers = ['image', 'name'];

            if (!this.disabled) headers.push('controls');

            return headers;
        },
    },
    watch: {
        d_value: {
            handler(value) {
                this.$emit('input', value);
            },
            deep: true,
        },
    },
    created() {
        const apiUrl = process.env.VUE_APP_API_URL.replace('/v2', ''); // eslint-disable-line no-undef

        this.d_sheetLink = `${apiUrl}/files/wamp_cup_players_sheet.xlsx`;
    },
    methods: {
        addCupUser(cupUser) {
            this.d_value.push(cupUser);

            this.d_form.data.federation_number = '';
        },
        canAddMoreUsers() {
            return !this.isBcko || this.d_value.length < 32;
        },
        hasBeenAdded(data) {
            const found = this.d_value.find((element) => {
                return (element.id && element.id == data.id) ||
                    (element.federation_number && element.federation_number == data.federation_number) ||
                    (element.email && element.email == data.email);
            });

            return Boolean(found);
        },
        removeCupUser(index) {
            this.d_value.splice(index, 1);
        },
        showDetailsModal(row, index) {
            let modal = this.$helpers.modal({
                modalComponent: DetailsModal,
                data: {
                    email: row.email,
                    first_name: row.first_name,
                    last_name: row.last_name,
                },
            }).show();

            modal.on('resolve', (data) => {
                for (const property of ['email', 'first_name', 'last_name']) {
                    this.$set(this.d_value[index], property, data[property]);
                }

                modal.close();
            });
        },
        submit() {
            if (!this.canAddMoreUsers()) {
                this.d_form.errors.record({
                    federation_number: [this.$t('cups.form.players.error_bcko_max')],
                });

                return false;
            }

            const federationNumber = this.d_form.data.federation_number;

            const url = '/users/federation-numbers/check';

            this.d_form.post(url).then(({data}) => {
                if (!this.hasBeenAdded(data)) {
                    this.addCupUser(data);
                } else {
                    this.d_form.data.federation_number = '';
                }
            }).catch(({status}) => {
                if (status === 404) {
                    const data = {
                        federation_number: federationNumber,
                        first_name: '',
                        last_name: '',
                    };

                    if (!this.hasBeenAdded(data)) {
                        this.addCupUser(data);

                        this.showDetailsModal(data, this.d_value.length - 1);
                    } else {
                        this.d_form.data.federation_number = '';
                    }
                }
            });
        },
        upload() {
            const url = '/cups/players';

            this.d_fileForm.post(url).then(({data}) => {
                for (let cupUser of data) {
                    if (this.hasBeenAdded(cupUser)) {
                        continue;
                    }


                    if (!this.canAddMoreUsers()) {
                        this.d_fileForm.errors.record({
                            file: [this.$t('cups.form.players.error_bcko_max')],
                        });

                        return false;
                    }

                    if (cupUser.id) {
                        cupUser = {
                            id: cupUser.id,
                            first_name: cupUser.first_name,
                            last_name: cupUser.last_name,
                            federation_number: cupUser.federation_number,
                            image: cupUser.image,
                        };
                    }

                    this.addCupUser(cupUser);
                }
            }).finally(() => {
                this.$refs.fileInput.reset();
            });
        },
        userError(index) {
            const properties = [
                'id', 'federation_number', 'email', 'first_name', 'last_name',
            ];

            for (let property of properties) {
                let key = `players.${index}.${property}`;

                if (this.errors.has(key)) {
                    return this.errors.first(key);
                }
            }
        },
    },
};
</script>

<style scoped>
.file-input-group .form-file-input {
    display: flex;
    flex: 1;
}
</style>
