<template>
    <div class="form-file-input custom-file">
        <input
            ref="input"
            class="custom-file-input"
            type="file"
            @change="change"
        >

        <label class="form-control custom-file-label">
            {{ d_name ? d_name : $t('choose_file') }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'FormFileInput',
    props: {
        value: undefined,
    },
    data() {
        return {
            d_name: undefined,
        };
    },
    methods: {
        change(e) {
            let file = e.target.files[0];

            if (!file) return;

            let path = e.target.value;

            this.d_name = path.split('\\').pop();

            let reader = new FileReader;

            reader.onload = (e) => {
                this.$emit('input', e.target.result);
            };

            reader.readAsDataURL(file);
        },
        reset() {
            this.$refs.input.value = '';

            this.d_name = undefined;

            this.$emit('input', undefined);
        },
    },
};
</script>
