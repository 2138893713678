let mixin = {
    data() {
        return {
            get var_placeholderSrc() {
                return require('@/assets/images/placeholder_image.svg'); // eslint-disable-line no-undef
            },
        };
    },
};

export default mixin;