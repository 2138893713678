<template>
    <modal :modal="d_modal">
        <template slot="modal-body">
            <h5 class="text-danger">
                {{ $t('confirm.warning') }}!
            </h5>

            {{ d_modal.options.body ? $t(d_modal.options.body) : $t('confirm.body') }}
        </template>

        <template slot="modal-footer">
            <btn
                class="btn-light"
                @click="d_modal.close()"
            >
                {{ $t('form.cancel') }}
            </btn>

            <btn
                :error="d_modal.error"
                :loading="d_modal.loading"
                :success="d_modal.success"
                class="btn-danger"
                @click="d_modal.resolve()"
            >
                {{ $t('form.confirm') }}
            </btn>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';

export default {
    name: 'Confirm',
    components: {Modal},
    data() {
        return {
            d_modal: undefined,
        };
    },
};
</script>
