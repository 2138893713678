let mixin = {
    data() {
        return {
            d_navbarTitle: '',
        };
    },
    destroyed() {
        this.$store.navbarContainer.title = undefined;
    },
    watch: {
        d_navbarTitle(value) {
            this.$store.navbarContainer.title = value;
        },
    },
};

export default mixin;
