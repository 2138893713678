<template>
    <div class="cups-starting">
        <p
            v-if="d_me.hasRole('admin')"
            v-trans
        >
            cups.show.text_started_at_admin
        </p>

        <p v-else>
            {{ $t( d_me.id ? 'cups.show.text_started_at_authenticated' : 'cups.show.text_started_at_unauthenticated') }}

            <!-- Span needs to be on one line or else there will be a space between the span and the dot -->
            <span class="font-weight-bold">{{ d_cup.starting_at | dateFormat }}</span>.
        </p>

        <div v-if="! d_cup.is_bcko || d_me.can('start', d_cup)">
            <btn
                :loading="d_loadingStart"
                class="btn-success"
                @click="startCup"
            >
                {{ $t('cups.start') }}
            </btn>
        </div>

        <h5
            v-trans
            class="text-uppercase mt-5 mb-3"
        >
            players
        </h5>

        <div
            v-if="d_me.can('update', d_cup) && d_me.hasRole('admin') && c_showSendReminders"
            class="mb-3"
        >
            <p v-trans>
                cups.show.text_send_reminders
            </p>

            <btn
                :disabled="d_remindersSent"
                :loading="d_loadingRemind"
                class="btn-primary"
                @click="sendReminders"
            >
                {{ $t(d_remindersSent ? 'cups.reminders_sent' : 'cups.send_reminders') }}
            </btn>
        </div>

        <users-table
            :headers="['image', 'name']"
            :loading="d_loading"
            :rows="d_cupUsers"
            :show-headers="false"
        />
    </div>
</template>

<script>
import moment from 'moment';
import Me from '@/library/Me';
import Cup from '@/models/Cup';
import User from '@/models/User';
import UsersTable from '@/components/cups/users/Table';

export default {
    name: 'CupsStarting',
    components: {UsersTable},
    props: {
        cup: {
            type: Cup,
            required: true,
        },
    },
    data() {
        return {
            d_cup: this.cup,
            d_cupUsers: [],
            d_loading: false,
            d_loadingRemind: false,
            d_loadingStart: false,
            d_me: new Me,
            d_remindersSent: false,
        };
    },
    computed: {
        c_showSendReminders() {
            const unknownUser = this.d_cupUsers.find((cupUser) => {
                return !cupUser.id;
            });

            return Boolean(unknownUser);
        },
    },
    mounted() {
        this.d_loading = true;

        const url = `${Cup.endpoint}/${this.d_cup.id}/users`;

        User.get(url).then(({data}) => {
            this.d_cupUsers = data.map((data) => {
                let user = data;

                if (data.user) {
                    user = data.user;
                }

                return new User(user);
            });

            this.d_loading = false;
        });
    },
    methods: {
        startCup() {
            const confirm = this.$helpers.confirm().show();

            confirm.on('resolve', () => {
                this.d_loadingStart = confirm.loading = true;

                this.d_cup.start().then(({data}) => {
                    data.started_at = moment();

                    this.d_cup = data;

                    this.d_loadingStart = confirm.loading = false;

                    this.$emit('cupChanged', this.d_cup);

                    confirm.close();
                });
            });
        },
        sendReminders() {
            const confirm = this.$helpers.confirm().show();

            confirm.on('resolve', () => {
                this.d_loadingRemind = confirm.loading = true;

                this.d_cup.remind().then(() => {
                    this.d_loadingRemind = confirm.loading = false;
                    this.d_remindersSent = true;

                    this.$emit('cupChanged', this.d_cup);

                    confirm.close();
                });
            });
        },
    },
};
</script>
