<template>
    <tr class="table-row">
        <td
            v-for="property in columns"
            :key="property"
        >
            {{ row[property] }}
        </td>
    </tr>
</template>

<script>
export default {
    name: 'TableRow',
    props: {
        row: {
            type: Object,
            required: true,
        },
        columns: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
