import User from '@/models/User';
import ApiToken from '@/library/ApiToken';
import Redirect from '@/library/Redirect';

let instance;

export default class Me {
    /**
     * Me constructor.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        if (instance === undefined) {
            return instance = new User(data);
        }

        return instance;
    }

    /**
     * Logs me out.
     */
    static logout() {
        ApiToken.deleteToken();

        instance = undefined;

        Redirect.to({name: 'auth.login'});
    }
}
