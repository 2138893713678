export default {
    props: {
        holeCount: {
            type: Number,
            default: 9,
        },
        holeStart: {
            type: Number,
            default: 0,
        },
    },
};
