<template>
    <div
        :class="{ show: !d_collapsed }"
        class="common-collapse"
    >
        <div
            :class="{ show: !d_collapsed }"
            class="header"
            @click="toggleCollapsed"
        >
            <slot name="header">
                Collapse header
            </slot>
        </div>

        <div
            :class="{ show: !d_collapsed }"
            class="collapse body"
        >
            <slot name="body">
                Collapse body
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Collapse',
    props: {
        collapsed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            d_collapsed: this.collapsed,
        };
    },
    watch: {
        d_collapsed(value) {
            this.$emit('collapseChanged', value);
        },
    },
    methods: {
        toggleCollapsed() {
            this.d_collapsed = !this.d_collapsed;
        },
    },
};
</script>
