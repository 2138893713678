import {Collection, Model} from './vue-mc/Model';

export class Post extends Model {
    /**
     * Default attributes that define the "empty" state.
     */
    defaults() {
        return {
            id: null,
            title: null,
            sub_title: null,
            body: null,
            cover_image: null,
            created_at: null,
        };
    }

    /**
     * Options of this model.
     */
    options() {
        return {
            endpoint: 'posts',
            identifier: 'slug',
        };
    }
}

export class Posts extends Collection {
    /**
     * Default attributes that define the "empty" state.
     */
    defaults() {
        return {
            type: null,
        };
    }

    /**
     * Options of this collection.
     */
    options() {
        return {
            model: Post,
        };
    }

};
