import OwRouterView from '@/components/common/OwRouterView';

const routes = [
    {
        path: '/teams',
        component: OwRouterView,
        meta: {
            middleware: ['Auth'],
        },
        children: [
            {
                path: ':slug/edit',
                name: 'teams.edit',
                props: true,
                component: () => import('@/components/teams/Edit'),
            },
        ],
    },
];

export default routes;
