<template>
    <div class="layouts-default">
        <portal-target
            name="layout-default-header-container"
        >
            <div>
                <div :class="!hasHeader ? '' : blackHeader ? 'bg-black': 'bg-white'">
                    <b-container class="px-0">
                        <navbar :type="blackHeader ? 'dark' : 'light'" />
                    </b-container>
                </div>

                <div
                    v-if="hasHeader"
                    :class="blackHeader ? 'bg-black text-white' : 'bg-white'"
                >
                    <b-container>
                        <b-row>
                            <b-col class="py-5">
                                <portal-target
                                    name="layout-default-header"
                                    slim
                                />
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </div>
        </portal-target>

        <div
            v-if="hasSecondaryNavbar"
            class="bg-white navbar-light"
        >
            <b-container class="secondary-navbar-container">
                <div class="pt-2 px-0">
                    <portal-target
                        name="layout-default-secondary-navbar"
                        slim
                    />
                </div>
            </b-container>
        </div>

        <b-container class="py-5">
            <b-row>
                <b-col>
                    <not-found v-if="d_showNotFound" />

                    <router-view v-else />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {Wormhole} from 'portal-vue';
import Navbar from '@/components/common/Navbar';
import NotFound from '@/components/common/NotFound';

export default {
    name: 'LayoutsDefault',
    components: {NotFound, Navbar},
    data() {
        return {
            d_showNotFound: false,
        };
    },
    computed: {
        hasHeader() {
            return Wormhole.hasContentFor('layout-default-header');
        },
        hasSecondaryNavbar() {
            return Wormhole.hasContentFor('layout-default-secondary-navbar');
        },
        /**
         * Shows black header if there is a <Portal> requesting a black header
         * via its name.
         *
         * @returns {boolean}
         */
        blackHeader() {
            return Wormhole.hasSource('layout-default-header-black');
        },
    },
    created() {
        this.$store.$on('showNotFound', (showNotFound) => {
            this.d_showNotFound = showNotFound;
        });
    },
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(sm) {
    .secondary-navbar-container {
        padding-right: 0;
        padding-left: 0;
    }
}
</style>
