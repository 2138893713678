<template>
    <div class="layouts-auth position-relative">
        <!-- Blue and red background with image. -->
        <div class="position-absolute bg-full blue" />

        <!-- <div class="position-absolute bg-half red" /> -->

        <!-- <div class="position-absolute bg-image bg-cover" /> -->

        <b-container class="navbar-container px-0">
            <navbar type="dark" />
        </b-container>

        <div class="container">
            <div class="row py-5 min-h-100vh align-items-center">
                <div class="col">
                    <div class="card-container mx-auto mx-md-0 position-relative">
                        <div class="card">
                            <div class="card-body">
                                <router-view />
                            </div>
                        </div>

                        <!-- Hide BKO sponsors for the BGL update. -->
                        <!-- <div class="sponsors">
                                <img
                                    :src="require('@/assets/images/sponsors_bko.svg')"
                                    class="w-100"
                                >
                            </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/common/Navbar';

export default {
    name: 'LayoutsAuth',
    components: {Navbar},
};
</script>

<style lang="scss" scoped>
// stylelint-disable declaration-no-important
.layouts-auth {
    .bg-half {
        right: 0;
        left: 0;
        height: 50%;

        &.blue {
            top: 0;
            background: #0c0788;
        }

        &.red {
            bottom: 0;
            background: #dc293e;
        }
    }

    .bg-full {
        right: 0;
        left: 0;
        height: 100%;

        &.blue {
            background: #0c0788;
        }

        &.red {
            background: #dc293e;
        }
    }

    .bg-image {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('~@/assets/images/auth_background_bko.png');
    }

    .bg-cover {
        background-position-x: right !important;
        background-size: auto 100% !important;

        @media (max-width: 991px) {
            background-position: center !important;
        }
    }

    .card-container {
        width: 350px;
        max-width: 100%;
    }

    .sponsors {
        position: absolute;
        top: 100%;
        right: 2rem;
        left: 2rem;
        margin-top: 2rem;
    }
}
</style>
