import $t from '@/lang/vueI18n';

export default class Enum {
    constructor(value) {
        this.value = value;
    }

    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     * @throws  {Error} If the Enum has not define the enumeration key-value pairs.
     */
    static get enumeration() {
        throw new Error('The enumeration key-value pairs are not yet defined.');
    };

    /**
     * @returns {Array} Keys of the enumeration.
     */
    static get keys() {
        return Object.keys(this.enumeration);
    }

    /**
     * @returns {Object[]} Object structures for every value in the enumeration.
     */
    static get objects() {
        return this.keys.map((k) => {
            return this.object(k);
        });
    }

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        throw new Error('The translation key is not yet defined.');
    }

    /**
     * @returns {number} Value of the specified key.
     */
    static value(key) {
        return this.enumeration[key];
    }

    /**
     * @returns {string} Key of the specified value.
     */
    static key(value) {
        return this.keys.find(k => this.value(k) === value);
    }

    /**
     * @param {string|number} target
     * @returns {Object} Enum object of the specified key or value.
     */
    static object(target) {
        const key = typeof target === 'string'
            ? target
            : this.key(target);

        return {
            key,
            value: this.value(key),
            translation: this.translateKey(key),
        };
    }

    /**
     * @returns {string} Translation for the specified key or value.
     */
    static translate(keyOrValue) {
        return typeof keyOrValue === 'string'
            ? this.translateKey(keyOrValue)
            : this.translateValue(keyOrValue);
    }

    /**
     * Alias for `translate(keyOrValue)`.
     *
     * @returns {string} Translation for the specified key or value.
     */
    static $t(keyOrValue) {
        return this.translate(keyOrValue);
    }

    /**
     * @returns {string} Translation for the specified key.
     */
    static translateKey(key) {
        return $t.t(`enums.${this.translationKey}.${key}`);
    }

    /**
     * @returns {string} Translation for the specified value.
     */
    static translateValue(value) {
        const key = this.key(value) || value;

        return this.translateKey(key);
    }

    /**
     * ========================================================================
     * Instance Properties
     * ========================================================================
     */

    /**
     * @returns {string} Enumeration key of this instance.
     */
    get key() {
        return this.constructor.key(this.value);
    }

    /**
     * @returns {string} Translated string representation of this instance.
     */
    toString() {
        return this.constructor.translateKey(this.key);
    }
};
