<template>
    <b-card>
        <b-card-title>
            Shadows
        </b-card-title>

        <p class="mb-4">
            Add or remove shadows to elements with box-shadow utilities.
        </p>

        <div>
            <h5>
                Examples
            </h5>

            <p>
                While shadows on components are disabled by default in Bootstrap and can be enabled via <code class="highlighter-rouge">$enable-shadows</code>, you can also quickly add or remove a shadow with our <code class="highlighter-rouge">box-shadow</code> utility classes. Includes support for <code class="highlighter-rouge">.shadow-none</code> and three default sizes (which have associated variables to match).
            </p>

            <preview>
                <div class="bg-light rounded mb-5 p-3">
                    No shadow
                </div>

                <div class="shadow-sm rounded mb-5 p-3">
                    Small shadow
                </div>

                <div class="shadow rounded mb-5 p-3">
                    Regular shadow
                </div>

                <div class="shadow-lg rounded mb-5 p-3">
                    Larger shadow
                </div>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Shadows',
    components: {Preview},
};
</script>
