import {Team} from './Team';
import {Model, Collection} from './vue-mc/Model';

export class LeagueGroupTeam extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            team: {},
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            team: (val) => {
                if (!(val instanceof Team)) {
                    return new Team(val);
                }

                return val;
            },
        };
    }
};

export class LeagueGroupTeams extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: LeagueGroupTeam,
        };
    }
};
