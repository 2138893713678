<template>
    <ol class="cups-rounds-list list-unstyled">
        <li
            v-for="round of rounds"
            :key="round.id"
        >
            <collapse
                :collapsed="!(round === d_closestRound)"
                :round="round"
            />
        </li>
    </ol>
</template>

<script>
import moment from 'moment';
import Collapse from '@/components/cups/rounds/Collapse';

export default {
    name: 'CupsRoundsList',
    components: {Collapse},
    props: {
        rounds: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            d_closestRound: {},
        };
    },
    created() {
        const rounds = this.rounds.slice();

        this.d_closestRound = rounds
            // Sorts the rounds from earliest to latest based on 'ending_at'
            .sort((a, b) => {
                return moment(a.ending_at).valueOf() > moment(b.ending_at).valueOf();
            })
            // Finds the first round of which the 'ending_at' is in the future
            .find((round) => {
                return moment(round.ending_at).isAfter();
            });
    },
};
</script>
