import {Model} from './vue-mc/Model';

export class Address extends Model {
    /**
     * Default attributes that define the "empty" state.
     */
    defaults() {
        return {
            id: null,
            street: null,
            number: null,
            zip_code: null,
            city: null,
            country: null,
            lat: null,
            lng: null,
        };
    }

    /**
     * Returns the string representation of this model.
     */
    toString() {
        return `
            ${this.street} ${this.number},
            ${this.zip_code},
            ${this.city},
            ${this.country}
        `;
    }
}
