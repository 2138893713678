<template>
    <div />
</template>

<script>
import Me from '@/library/Me';

export default {
    name: 'AuthLogout',
    created: () => Me.logout(),
};
</script>
