<template>
    <b-card>
        <b-card-title>
            Navs
        </b-card-title>

        <p class="mb-4">
            Documentation and examples for how to use Bootstrap’s included navigation components.
        </p>

        <div class="mb-4">
            <h5>
                Base nav
            </h5>

            <p>
                Navigation available in Bootstrap share general markup and styles, from the base <code class="highlighter-rouge">.nav</code> class to the active and disabled states. Swap modifier classes to switch between each style.
            </p>

            <preview>
                <b-nav>
                    <b-nav-item
                        v-for="item in items"
                        :key="item"
                        :active="item === 'Active'"
                        :disabled="item === 'Disabled'"
                    >
                        {{ item }}
                    </b-nav-item>
                </b-nav>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Veritcal
            </h5>

            <p>
                Stack your navigation by changing the flex item direction with the <code class="highlighter-rouge">.flex-column</code> utility. Need to stack them on some viewports but not others? Use the responsive versions (e.g., <code class="highlighter-rouge">.flex-sm-column</code>).
            </p>

            <preview>
                <b-nav vertical>
                    <b-nav-item
                        v-for="item in items"
                        :key="item"
                        :active="item === 'Active'"
                        :disabled="item === 'Disabled'"
                    >
                        {{ item }}
                    </b-nav-item>
                </b-nav>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Tabs
            </h5>

            <p>
                Takes the basic nav from above and adds the <code class="highlighter-rouge">.nav-tabs</code> class to generate a tabbed interface.
            </p>

            <preview>
                <b-nav tabs>
                    <b-nav-item
                        v-for="item in items"
                        :key="item"
                        :active="item === 'Active'"
                        :disabled="item === 'Disabled'"
                    >
                        {{ item }}
                    </b-nav-item>
                </b-nav>
            </preview>
        </div>

        <div>
            <h5>
                Pills
            </h5>

            <p>
                Take that same HTML, but use <code class="highlighter-rouge">.nav-pills</code> instead:
            </p>

            <preview>
                <b-nav pills>
                    <b-nav-item
                        v-for="item in items"
                        :key="item"
                        :active="item === 'Active'"
                        :disabled="item === 'Disabled'"
                    >
                        {{ item }}
                    </b-nav-item>
                </b-nav>
            </preview>

            <p>
                Vertical.
            </p>

            <preview>
                <b-nav
                    pills
                    vertical
                >
                    <b-nav-item
                        v-for="item in items"
                        :key="item"
                        :active="item === 'Active'"
                        :disabled="item === 'Disabled'"
                    >
                        {{ item }}
                    </b-nav-item>
                </b-nav>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Navs',
    components: {Preview},
    data() {
        return {
            items: [
                'Active',
                'Link',
                'Another link',
                'Disabled',
            ],
        };
    },
};
</script>
