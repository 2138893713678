<template>
    <tr class="courses-table-row cursor-pointer">
        <td
            v-if="columns.includes('id')"
            class="font-weight-bold w-auto"
        >
            {{ row.id }}
        </td>

        <td
            v-if="columns.includes('club_name')"
            class="font-weight-bold text-primary"
        >
            {{ row.name }}
        </td>

        <td
            v-if="columns.includes('country')"
            class="w-auto"
        >
            {{ row.country }}
        </td>

        <td
            v-if="columns.includes('created_at')"
            class="w-auto"
        >
            {{ formatDate(row.created_at) }}
        </td>

        <td
            v-if="columns.includes('updated_at')"
            class="w-auto"
        >
            {{ formatDate(row.updated_at) }}
        </td>
    </tr>
</template>

<script>
import moment from 'moment';
import Course from '@/models/Course';
import TableRow from '@/components/common/TableRow';

export default {
    name: 'CoursesTableRow',
    extends: TableRow,
    props: {
        row: {
            type: Course,
            required: true,
        },
    },
    methods: {
        formatDate(date) {
            if (!date) {
                return this.$t('tba');
            }

            return moment(date).from();
        },
    },
};
</script>
