<template>
    <btn
        v-bind="$attrs"
        :disabled="cDisabled"
        :error="error"
        :loading="loading"
        :success="success"
        v-on="$listeners"
    >
        <i
            v-if="success"
            class="fa fa-check mr-1"
        />

        <i
            v-else-if="error"
            class="fa fa-exclamation-triangle mr-1"
        />

        <slot />
    </btn>
</template>

<script>
import {Collection, Model} from '@/models/vue-mc/Model';

export default {
    name: 'BtnResource',
    props: {
        resource: {
            type: [Collection, Model],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: undefined,
        },
    },
    data() {
        return {
            hasLoaded: false,
        };
    },
    computed: {
        loading() {
            return this.resource.loading || this.resource.saving || this.resource.deleting;
        },
        error() {
            return this.resource.hasErrors || this.resource.fatal;
        },
        success() {
            return this.hasLoaded && !this.loading && !this.error;
        },

        /**
         * If `:disabled` prop is not provided, we will disable this button
         * if the resource is not changed. The disabled state should apply
         * after the success feedback is hidden.
         */
        cDisabled() {
            if (this.disabled !== undefined) {
                return this.disabled;
            }

            return !this.resource.changed() && !this.success;
        },
    },
    watch: {
        'loading': function(newVal, oldVal) {
            this.hasLoaded = Boolean(oldVal === true && newVal === false);

            // Reset `hasLoaded` back to false after 1.5 second. This will also
            // reset `success` and hide the check icon.
            if (this.hasLoaded === true) {
                setTimeout(() => this.hasLoaded = false, 1500);
            }
        },
    },
};
</script>
