<template>
    <b-card>
        <b-card-title>
            Spinners
        </b-card-title>

        <p class="mb-4">
            Indicate the loading state of a component or page with Bootstrap spinners, built entirely with HTML, CSS, and no JavaScript.
        </p>

        <div class="mb-4">
            <h5>
                Border spinner
            </h5>

            <p>
                Use the border spinners for a lightweight loading indicator.
            </p>

            <preview>
                <b-spinner
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    :variant="color"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Growing spinner
            </h5>

            <p>
                If you don’t fancy a border spinner, switch to the grow spinner. While it doesn’t technically spin, it does repeatedly grow!
            </p>

            <preview>
                <b-spinner
                    v-for="color in themeColors"
                    :key="color"
                    class="mr-1"
                    type="grow"
                    :variant="color"
                />
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Size
            </h5>

            <p>
                Add <code class="highlighter-rouge">.spinner-border-sm</code> and <code class="highlighter-rouge">.spinner-grow-sm</code> to make a smaller spinner that can quickly be used within other components.
            </p>

            <preview>
                <div class="mb-1">
                    <b-spinner
                        class="mr-1"
                        small
                    />

                    <b-spinner
                        small
                        type="grow"
                    />
                </div>

                <div class="mb-1">
                    <b-spinner class="mr-1" />

                    <b-spinner type="grow" />
                </div>

                <div>
                    <b-spinner
                        class="mr-1"
                        style="width: 3rem; height: 3rem;"
                    />

                    <b-spinner
                        style="width: 3rem; height: 3rem;"
                        type="grow"
                    />
                </div>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Buttons
            </h5>

            <p>
                Use spinners within buttons to indicate an action is currently processing or taking place. You may also swap the text out of the spinner element and utilize button text as needed.
            </p>

            <preview>
                <div class="mb-1">
                    <b-button
                        class="mr-1"
                        disabled
                        variant="primary"
                    >
                        <b-spinner small />
                    </b-button>

                    <b-button
                        disabled
                        variant="primary"
                    >
                        <b-spinner small />

                        Loading...
                    </b-button>
                </div>

                <div>
                    <b-button
                        class="mr-1"
                        disabled
                        variant="primary"
                    >
                        <b-spinner
                            small
                            type="grow"
                        />
                    </b-button>

                    <b-button
                        disabled
                        variant="primary"
                    >
                        <b-spinner
                            small
                            type="grow"
                        />

                        Loading...
                    </b-button>
                </div>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'Spinners',
    components: {Preview},
    data() {
        return {
            themeColors,
        };
    },
};
</script>
