<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App',
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'WAMP',
        // all titles will be injected into this template
        titleTemplate: '%s | We Are Match Play',
    },
    watch: {
        $route(to) {
            if(to.meta.title) document.title = `${to.meta.title}`;

            if(to.meta.icon) {
                document.querySelector('[rel="icon"]').setAttribute('href', to.meta.icon);
                document.querySelector('[rel="apple-touch-icon-precomposed"]').setAttribute('href', to.meta.icon);
            }
        },
    },
};
</script>
<style lang="scss">
@import 'assets/scss/app';
</style>
