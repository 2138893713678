export default {
    add: 'Add',
    add_details: 'Add details',
    change_details: 'Change details',
    choose_file: 'Choose file',
    club_name: 'Club name',
    comments: 'Comments',
    count: 'Count',
    country: 'Country',
    course: 'Course',
    courses: 'Courses',
    continue: 'Continue',
    created_at: 'Created',
    cups: 'Cups',
    deadline: 'Deadline',
    disable: 'Disable',
    download: 'Download',
    email: 'Email',
    enable: 'Enable',
    ending_at: 'End date',
    ends: 'Ends',
    federation_number: 'Federation number',
    federation_numbers: 'Federation numbers',
    first_name: 'First name',
    general_error: 'Whoops! Something went wrong. Try again later.',
    go_back: 'Go back',
    guests: 'Guests',
    hole: 'Hole',
    home_course: 'Home course',
    id: 'ID',
    image: 'Image',
    in: 'In',
    is_bcko: 'Is Belgian Club Knock Out',
    last_name: 'Last name',
    likes: 'Likes',
    login: 'Login',
    logout: 'Logout',
    losses_count: 'Loss count',
    matches: 'Matches',
    matches_count: 'Match count',
    meters: 'Meters',
    model: 'Model',
    name: 'Name',
    no_results: 'No results',
    par: 'PAR',
    password: 'Password',
    password_confirmation: 'Password confirmation',
    players: 'Players',
    player_count: 'Player count',
    rating: 'Rating',
    save: 'Save',
    search: 'Search',
    select_course: 'Select course',
    si: 'S.I.',
    slope: 'Slope',
    squares_count: 'Square count',
    starting_at: 'Start date',
    statistics: 'Statistics',
    tba: 'T.B.A.',
    team: 'Team',
    tpm: 'Total $model per month',
    unknown_user: 'Unknown user',
    updated_at: 'Updated',
    upload: 'Upload',
    username: 'Username',
    users: 'Users',
    win: 'Win',
    wins_count: 'Win count',

    'auth.login.actions.forgot': 'Forgot',

    'auth.password.back_to_login': 'Back to login',
    'auth.password.forgot.title': 'Forgot password',
    'auth.password.forgot.text': 'Please fill in your email address so we can send you a password reset link.',
    'auth.password.forgot.success.title': 'Success!',
    'auth.password.forgot.success.text': 'An email has been sent to the provided email address. Follow the instructions in this email to reset your password.',
    'auth.password.reset.title': 'Reset password',
    'auth.password.reset.text': 'Please fill in your email address and your new password.',
    'auth.password.reset.success.title': 'Success!',
    'auth.password.reset.success.text': 'Your password has been reset. You will be logged in automatically.',

    'confirm.body': 'You are about to execute an operation that has to be confirmed by a user. Do you confirm this action?',
    'confirm.discard.body': 'Your changes have not been saved. Are you sure you want to leave the page?',
    'confirm.warning': 'Warning!',

    'courses.action.add_tee': 'Add tee',
    'courses.action.delete': 'Delete course',
    'courses.action.save': 'Save course',
    'courses.back_nine': 'Back nine',
    'courses.front_nine': 'Front nine',
    'courses.new': 'New course',
    'courses.create.title': 'Create a new course',
    'courses.form.labels.city': 'City',
    'courses.form.labels.country': 'Country',
    'courses.form.labels.lat': 'Latitude',
    'courses.form.labels.lng': 'Longitude',
    'courses.form.labels.name': 'Name',
    'courses.form.placeholders.city': 'City',
    'courses.form.placeholders.course_rating': 'Course rating',
    'courses.form.placeholders.country': 'Country',
    'courses.form.placeholders.lat': '51.441642',
    'courses.form.placeholders.lng': '5.469722',
    'courses.form.placeholders.name': 'Course name',
    'courses.form.placeholders.slope_rating': 'Slope rating',
    'courses.form.placeholders.tee_name': 'Fill in tee name',
    'courses.index.title': 'Courses',

    'cups.edit': 'Edit cup',
    'cups.new': 'New cup',
    'cups.reminders_sent': 'Reminders sent',
    'cups.send_reminders': 'Send reminders',
    'cups.start': 'Start the cup',
    'cups.edit.title': 'Edit cup',
    'cups.create.title': 'Start a new cup',
    'cups.index.title': 'Cups',
    'cups.matches.no_results': 'There are no matches yet for this round.',
    'cups.matches.collapse.set_as_winner': 'Set as winner',
    'cups.show.text_send_reminders': 'It seems that some of the players have not created an account yet. By clicking on "Send reminders" an email will be sent to remind these players to create an account.',
    'cups.show.text_started_at_admin': 'The cup hasn\'t been started yet. When a cup has been started matches can be played. However, you can\'t edit the players anymore after starting the cup. Are you ready to start the cup?',
    'cups.show.text_started_at_authenticated': 'The cup hasn\'t been started yet. When a cup has been started matches can be played. However, you can\'t edit the players anymore. The cup will start automatically at',
    'cups.show.text_started_at_unauthenticated': 'The cup hasn\'t been started yet. You can already check out the players below, who are currently added to this cup! The cup will start at',
    'cups.form.labels.name': 'Name the cup',
    'cups.form.placeholders.name': 'Example: Rinkven Golf Tournament',
    'cups.form.players.error_bcko_max': 'Cannot add more than 32 players to a BCKO cup.',
    'cups.form.players.bcko_min_max': 'BCKO cups have a minimum of 17 and a maximum of 32 players.',
    'cups.form.starting_at.explanation': 'The end date will be determined by the amount of rounds. The amount of rounds will depend on the amount of players. Each of these rounds will take 3 weeks.',
    'cups.form.starting_at.bcko_explanation': 'The start date of a BCKO cup is fixed to 11-02-2019 and will end on 12-05-2019.',
    'cups.matches.collapse.reset': 'Reset match',

    'cup_users.email_modal.email.explanation': 'Add the email of this user. An invitation will be sent automatically to this email address when the cup is saved.',
    'cup_users.form_group.explanation': 'Add the federation numbers of the players you wish to invite to the cup. Invitations will be automatically sent by email after this cup has been created. You will be able to add an email address if the federation number is not found in our system.',
    'cup_users.table.no_results': 'No players have been added yet.',

    'form.cancel': 'Cancel',
    'form.confirm': 'Confirm',
    'form.delete': 'Delete',
    'form.submit': 'Submit',

    'navbar.default.about': 'What is WAMP?',
    'navbar.default.faq': 'FAQ',

    'not_found.action.back': 'Take me back',
    'not_found.info.head': 'Oops, we can\'t seem to find the page you are looking for.',
    'not_found.info.body': 'But this means you are one of the happy few to find the hidden 404 page, congratulations!',

    'statistics.index.title': 'Statistics',

    'users.action.save': 'Save user',
    'users.form.labels.created_at': 'Created at',
    'users.form.labels.email': 'Email',
    'users.form.labels.federation_number': 'Federation number',
    'users.form.labels.first_name': 'First name',
    'users.form.labels.followers': 'Followers',
    'users.form.labels.following': 'Following',
    'users.form.labels.handicap': 'Handicap',
    'users.form.labels.last_name': 'Last name',
    'users.form.labels.matches': 'Matches',
    'users.form.labels.updated_at': 'Updated at',
    'users.form.labels.username': 'Username',
    'users.form.placeholders.email': 'Email',
    'users.form.placeholders.federation_number': '0000000000',
    'users.form.placeholders.first_name': 'First name',
    'users.form.placeholders.handicap': 'Handicap',
    'users.form.placeholders.home_course': 'Home course',
    'users.form.placeholders.last_name': 'Last name',
    'users.form.placeholders.username': 'Username',
    'users.index.title': 'Users',
    'users.is_pro': 'Pro',
    'users.is_verified': 'Verified',
    'users.roles': 'Roles',
};
