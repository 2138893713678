import Vue from 'vue';
import en from './en';
import VueI18n from 'vue-i18n';

let vueI18n;

if (!vueI18n) {
    Vue.use(VueI18n);

    vueI18n = new VueI18n({
        locale: 'en',
        messages: {
            en,
        },
    });
}

export default vueI18n;
