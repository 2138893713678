export default {
    actions: {
        select: 'Select',
        verify_scores: 'Update scores',
    },
    errors: {
        reload_page: 'Reload this page to try again.',
    },
    inputs: {
        address: {
            city: {
                label: 'City',
                placeholder: 'City',
            },
            country: {
                label: 'Country',
                placeholder: 'Country',
            },
            number: {
                label: 'Number',
                placeholder: 'XX',
            },
            street: {
                label: 'Street',
                placeholder: 'Street',
            },
            zip_code: {
                label: 'ZIP Code',
                placeholder: 'XXXX XX',
            },
        },
        dropdown: {
            placeholder: 'Select',
        },
        image: {
            label: 'Image',
        },
        location: {
            label: 'Location',
            placeholder: 'Location',
        },
        phone: {
            label: 'Phone',
            placeholder: 'Phone',
        },
        starts_at: {
            label: 'Date',
        },
        website: {
            label: 'Website',
            placeholder: 'Website',
        },
        role: {
            label: 'Role',
        },
    },
    messages: {
        players_not_announced: 'Players have not been announced yet.',
    },
    words: {
        draw: 'Draw',
        game_points: 'Game points',
        lose: 'Lose',
        match_points: 'Match points',
        members: 'Members',
        played: 'Played',
        player: 'Player',
        points: 'Points',
        rank: 'Rank',
        ups: 'Ups',
        win: 'Win',
    },
};
