let instance;

export default class Whitelabel {
    constructor(whitelabel) {
        if (instance === undefined) {
            return instance = whitelabel;
        }

        return instance;
    }
}
