<template>
    <b-card>
        <b-card-title>
            Dropdowns
        </b-card-title>

        <p class="mb-4">
            Toggle contextual overlays for displaying lists of links and more with the Bootstrap dropdown plugin.
        </p>

        <div class="mb-4">
            <h5>
                Single button
            </h5>

            <p>
                Any single <code class="highlighter-rouge">.btn</code> can be turned into a dropdown toggle with some markup changes. Here’s how you can put them to work with either <code class="highlighter-rouge">&lt;button&gt;</code> elements:
            </p>

            <preview>
                <b-dropdown text="Dropdown button">
                    <b-dropdown-item
                        v-for="item in items"
                        :key="item"
                    >
                        {{ item }}
                    </b-dropdown-item>
                </b-dropdown>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Sizing
            </h5>

            <p>
                Button dropdowns work with buttons of all sizes, including default and split dropdown buttons.
            </p>

            <preview>
                <b-dropdown
                    size="lg"
                    text="Large"
                >
                    <b-dropdown-item
                        v-for="item in items"
                        :key="item"
                    >
                        {{ item }}
                    </b-dropdown-item>
                </b-dropdown>
            </preview>

            <preview>
                <b-dropdown
                    size="sm"
                    text="Small"
                >
                    <b-dropdown-item
                        v-for="item in items"
                        :key="item"
                    >
                        {{ item }}
                    </b-dropdown-item>
                </b-dropdown>
            </preview>
        </div>

        <div>
            <h5>
                Menu items
            </h5>

            <p>
                Historically dropdown menu contents <em>had</em> to be links, but that’s no longer the case with v4. Now you can optionally use <code class="highlighter-rouge">&lt;button&gt;</code> elements in your dropdowns instead of just <code class="highlighter-rouge">&lt;a&gt;</code>s.
            </p>

            <preview class="clearfix">
                <div class="dropdown-menu show position-relative">
                    <span class="dropdown-item-text">
                        Dropdown item text
                    </span>

                    <b-dropdown-text class="text-muted">
                        Some more dropdown text.
                    </b-dropdown-text>

                    <b-dropdown-item
                        v-for="item in items"
                        :key="item"
                    >
                        {{ item }}
                    </b-dropdown-item>

                    <b-dropdown-group header="Group 1">
                        <b-dropdown-item
                            v-for="item in items"
                            :key="item"
                        >
                            {{ item }}
                        </b-dropdown-item>
                    </b-dropdown-group>

                    <b-dropdown-divider />

                    <b-dropdown-item>
                        Divided item
                    </b-dropdown-item>
                </div>
            </preview>

            <h6>
                Active
            </h6>

            <p>
                Add <code class="highlighter-rouge">.active</code> to items in the dropdown to <strong>style them as active</strong>.
            </p>

            <preview class="clearfix">
                <div class="dropdown-menu show position-relative">
                    <b-dropdown-item>
                        Regular link
                    </b-dropdown-item>

                    <b-dropdown-item active>
                        Active link
                    </b-dropdown-item>

                    <b-dropdown-item>
                        Another link
                    </b-dropdown-item>
                </div>
            </preview>

            <h6>
                Disabled
            </h6>

            <p>
                Add <code class="highlighter-rouge">.disabled</code> to items in the dropdown to <strong>style them as disabled</strong>.
            </p>

            <preview class="clearfix">
                <div class="dropdown-menu show position-relative">
                    <b-dropdown-item>
                        Regular link
                    </b-dropdown-item>

                    <b-dropdown-item disabled>
                        Disabled link
                    </b-dropdown-item>

                    <b-dropdown-item>
                        Another link
                    </b-dropdown-item>
                </div>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Dropdowns',
    components: {Preview},
    data() {
        return {
            items: [
                'Action',
                'Another action',
                'Something else here',
            ],
        };
    },
};
</script>
