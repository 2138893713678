<script>
import OwTable from '@/components/common/OwTable';
import TableRow from '@/components/users/TableRow';

export default {
    name: 'UsersTable',
    extends: OwTable,
    props: {
        headers: {
            type: Array,
            default: () => [
                'image', 'username', 'name', 'federation_number', 'created_at', 'updated_at',
            ],
        },
        tableRowComponent: {
            type: Object,
            default: () => TableRow,
        },
    },
};
</script>
