<template>
    <div>
        <b-row
            v-if="dLeagues"
            class="leagues-nav"
            no-gutters
        >
            <router-link
                v-for="(league, index) of dLeagues.models"
                :key="league.id"
                class="leagues-nav-col text-white col p-3 rounded-0 text-truncate text-center"
                :class="leaguesNavColClasses(index)"
                :style="{'background-color': getNavColor(league)}"
                :to="{name: 'leagues.routerView', params: {slug: league.slug}}"
            >
                {{ league.name }}
            </router-link>
        </b-row>

        <b-container class="my-3">
            <b-navbar
                class="px-0 align-items-start"
                toggleable="lg"
                type="dark"
            >
                <b-navbar-brand
                    :to="{name: 'home'}"
                    exact
                >
                    <ow-img
                        width="60px"
                        :src="whitelabel"
                        src-key="logo.original"
                    />
                </b-navbar-brand>

                <template v-if="(leagues && leagues.length === 1) || (me && me.id)">
                    <b-navbar-toggle target="nav-collapse" />

                    <b-collapse
                        id="nav-collapse"
                        is-nav
                    >
                        <b-navbar-nav
                            v-if="isHome"
                            class="ml-auto nav"
                        >
                            <template
                                v-if="leagues.length === 1"
                            >
                                <b-nav-item
                                    v-for="navItem of navItems"
                                    :key="navItem.text"
                                    :to="navItem.to"
                                >
                                    {{ navItem.text }}
                                </b-nav-item>
                            </template>
                        </b-navbar-nav>

                        <navbar-nav-user
                            v-if="me.id"
                            :class="{'ml-auto': !isHome}"
                            class="align-items-baseline"
                        />
                    </b-collapse>
                </template>
            </b-navbar>
        </b-container>
    </div>
</template>

<script>
import {Leagues} from '@/models/League';
import Me from '@/library/Me';
import NavbarNavUser from '@/components/common/NavbarNavUser';
import Whitelabel from '@/library/Whitelabel';

export default {
    name: 'WhitelabelLeaguesNav',
    components: {NavbarNavUser},
    props: {
        leagues: {
            default: null,
            type: Leagues,
        },
    },
    data() {
        return {
            dLeagues: this.leagues,
            me: new Me,
            whitelabel: new Whitelabel,
        };
    },
    computed: {
        firstLeague() {
            return this.dLeagues ? this.dLeagues.first() : null;
        },
        firstLeagueRouteParams() {
            return this.firstLeague
                ? {slug: this.dLeagues.first().slug}
                : null;
        },
        isHome() {
            return this.$route.name === 'home';
        },
        navItems() {
            return [
                {
                    to: {name: 'leagues.news.index', params: this.firstLeagueRouteParams},
                    text: this.$t('whitelabels.leaguesNav.news'),
                },
                {
                    to: {name: 'leagues.schedule.index', params: this.firstLeagueRouteParams},
                    text: this.$t('whitelabels.leaguesNav.schedule'),
                },
                {
                    to: {name: 'leagues.leaderboard', params: this.firstLeagueRouteParams},
                    text: this.$t('whitelabels.leaguesNav.clubRanking'),
                },
                {
                    to: {name: 'leagues.players', params: this.firstLeagueRouteParams},
                    text: this.$t('whitelabels.leaguesNav.playerRanking'),
                },
            ];
        },
    },
    created() {
        if (!this.dLeagues && this.whitelabel.id) {
            if (this.whitelabel.leagues) {
                this.dLeagues = this.whitelabel.leagues;
            } else {
                this.dLeagues = new Leagues([], {
                    routePrefix: `whitelabels/${this.whitelabel.id}`,
                });

                this.dLeagues.fetch();

                this.whitelabel.leagues = this.dLeagues;
            }
        }
    },
    methods: {
        getNavColor(league) {
            return league.get('data.ui.whitelabel_nav_color', 'inherit') + '70';
        },
        leaguesNavColClasses(index) {
            const length = this.dLeagues.models.length;

            return {
                'second-v-half': index >= length / 2,
                'second-h-half': index % (length / 2) > 0,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
$border-color: rgba(255, 255, 255, .25);

.leagues-nav {
    border-bottom: 1px solid $border-color;

    .leagues-nav-col{
        @media (max-width: 576px) {
            &:not(:first-child) {
                border-top: $border;
            }
        }

        @media (min-width: 576px) and (max-width: 768px) {
            &.second-v-half {
                border-top: $border;
            }

            &.second-h-half {
                border-left: $border;
            }
        }

        @media (min-width: 768px) {
            &:not(:first-child) {
                border-left: $border;
            }
        }
    }
}

.leagues-nav-col {
    min-width: 100%;
    /* stylelint-disable-next-line declaration-no-important */
    border-color: $border-color !important;

    @media (min-width: 576px) {
        min-width: 50%;
    }

    @media (min-width: 768px) {
        min-width: auto;
    }

    &:hover,
    &.router-link-active {
        color: #fff;
    }
}
</style>
