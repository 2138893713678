<template>
    <tr class="statistics-users-table-row">
        <td v-if="columns.includes('id')">
            {{ row.id }}
        </td>

        <td
            v-if="columns.includes('first_name')"
            class="w-auto"
        >
            {{ row.first_name }}
        </td>

        <td
            v-if="columns.includes('last_name')"
            class="w-auto"
        >
            {{ row.last_name }}
        </td>

        <td v-if="columns.includes('matches_count')">
            {{ row.matches_count }}
        </td>

        <td v-if="columns.includes('wins_count')">
            {{ row.wins_count }}
        </td>

        <td v-if="columns.includes('losses_count')">
            {{ row.losses_count }}
        </td>

        <td v-if="columns.includes('squares_count')">
            {{ row.squares_count }}
        </td>
    </tr>
</template>

<script>
import TableRow from '@/components/common/TableRow';

export default {
    name: 'StatisticsUsersTableRow',
    extends: TableRow,
};
</script>
