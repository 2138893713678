import Errors from '@/library/Errors';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Errors,
            default: () => new Errors(),
        },
        loading: Boolean,
        value: {
            type: Object,
            default: () => {},
        },
    },
};
