<template>
    <b-card>
        <b-card-title>
            ButtonGroup
        </b-card-title>

        <p class="mb-4">
            Group a series of buttons together on a single line with the button group, and super-power them with JavaScript.
        </p>

        <div class="mb-4">
            <h5>
                Basic example
            </h5>

            <p>
                Wrap a series of buttons with <code class="highlighter-rouge">.btn</code> in <code class="highlighter-rouge">.btn-group</code>. Add on optional JavaScript radio and checkbox style behavior with <a href="https://getbootstrap.com/docs/4.4/components/buttons/#button-plugin">our buttons plugin</a>.
            </p>

            <preview>
                <b-button-group>
                    <b-button>
                        Left
                    </b-button>

                    <b-button>
                        Middle
                    </b-button>

                    <b-button>
                        Right
                    </b-button>
                </b-button-group>
            </preview>
        </div>

        <div class="mb-4">
            <h5>
                Sizing
            </h5>

            <p>
                Instead of applying button sizing classes to every button in a group, just add <code class="highlighter-rouge">.btn-group-*</code> to each <code class="highlighter-rouge">.btn-group</code>, including each one when nesting multiple groups.
            </p>

            <preview>
                <div
                    v-for="(size, index) in ['sm', '', 'lg']"
                    :key="index"
                    class="mb-1"
                >
                    <b-button-group :size="size">
                        <b-button>
                            Left
                        </b-button>

                        <b-button>
                            Middle
                        </b-button>

                        <b-button>
                            Right
                        </b-button>
                    </b-button-group>
                </div>
            </preview>
        </div>

        <div>
            <h5>
                Vertical variation
            </h5>

            <p>
                Make a set of buttons appear vertically stacked rather than horizontally by setting the <code class="text-nowrap">vertical</code> prop. Split button dropdowns are not supported here.
            </p>

            <preview>
                <b-button-group vertical>
                    <b-button>
                        Top
                    </b-button>

                    <b-button>
                        Middle
                    </b-button>

                    <b-button>
                        Bottom
                    </b-button>
                </b-button-group>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';
import themeColors from '@/components/styleguide/themeColors';

export default {
    name: 'ButtonGroup',
    components: {Preview},
};
</script>
