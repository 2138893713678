import User from '@/models/User';
import Model from '@/models/Model';
import Request from '@/library/Request';
import Policy from '@/library/policies/CupMatch';

export default class CupMatch extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'cup-matches';
    }

    /**
     * The policy of the model.
     */
    static get policy() {
        return Policy;
    }

    /**
     * Model constructor.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        if (data.users) {
            data.users = User.collect(data.users);
        }

        super(data);
    }

    /**
     * Resets the match.
     */
    reset() {
        const url = `${this.endpoint}/reset`;

        return (new Request).get(url);
    }

    /**
     * Set specified cup user as winner.
     *
     * @param int cupUserId
     */
    setCupUserAsWinner(cupUserId) {
        const url = `${this.endpoint}/set-winner/${cupUserId}`;

        return (new Request).post(url);
    }
}
