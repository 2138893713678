import Policy from '@/library/policies/Policy';

class Course extends Policy {
    /**
     * Can store.
     *
     * @param {User} user
     * @param {Model} model
     */
    static store(user, model) {
        return user.hasRole(['admin', 'editor']);
    }

    /**
     * Can update.
     *
     * @param {User} user
     * @param {Model} model
     */
    static update(user, model) {
        return user.hasRole(['admin', 'editor']);
    }

    /**
     * Can delete.
     *
     * @param {User} user
     * @param {Model} model
     */
    static delete(user, model) {
        return user.hasRole(['admin', 'editor']);
    }
}

export default Course;
