<template>
    <div class="cups-show position-relative">
        <div class="top-edge clearfix">
            <router-link
                :to="{name: 'cups.index'}"
                class="btn btn-primary btn-circle float-left"
            >
                <i class="fa fa-arrow-left" />
            </router-link>

            <router-link
                v-if="d_cup && d_me.can('update', d_cup)"
                :to="{name: 'cups.edit', params: { id: d_cup.id }}"
                class="btn btn-primary float-right"
            >
                {{ $t('cups.edit') }}
            </router-link>
        </div>

        <div class="py-5">
            <loader
                v-if="d_loading"
                :loading="true"
                class="w-100 text-center"
            />

            <div
                v-else-if="d_cup"
                class="row justify-content-center"
            >
                <div class="col-md-8 col-xl-6">
                    <component
                        :is="c_component"
                        :cup="d_cup"
                        @cupChanged="cupChanged"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Me from '@/library/Me';
import Cup from '@/models/Cup';
import CupStarted from '@/components/cups/Started';
import CupStarting from '@/components/cups/Starting';
import navbarTitle from '@/library/mixins/navbarTitle';

export default {
    name: 'CupsShow',
    mixins: [navbarTitle],
    data() {
        return {
            d_cup: undefined,
            d_loading: false,
            d_me: new Me,
        };
    },
    computed: {
        c_component() {
            return this.d_cup && this.d_cup.started_at ? CupStarted : CupStarting;
        },
    },
    mounted() {
        this.d_navbarTitle = this.$t('cups');

        const id = this.$route.params.id;

        this.d_loading = true;

        Cup.find(id).then(({data}) => {
            this.d_cup = data;

            this.d_navbarTitle = data.name;

            this.d_loading = false;
        }).catch(() => {
            this.$store.$emit('showNotFound', true);
        });
    },
    methods: {
        cupChanged(cup) {
            this.d_cup = cup;
        },
    },
};
</script>
