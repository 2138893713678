const helpers = {
    buildUrl(url, parameters = {}) {
        let currentParameters = {};

        if (url === undefined) {
            return url;
        }

        let splittedUrl = url.split('?');

        if (splittedUrl.length > 1) {
            let splittedParameters = splittedUrl[1].split('&');

            for (let splittedParameter of splittedParameters) {
                let keyValue = splittedParameter.split('=');

                currentParameters[keyValue[0]] = keyValue[1];
            }
        }

        parameters = Object.assign(currentParameters, parameters);

        let urlQueries = Object.entries(parameters)
            .map(keyValue => keyValue.join('='))
            .join('&');

        return splittedUrl[0] + '?' + urlQueries;
    },

    dataGet(data, key) {
        let value = data;

        let keys = key.split('.');

        for (let key of keys) {
            if (!value || !value.hasOwnProperty(key) || value[key] === undefined) {
                return null;
            }

            value = value[key];
        }

        return value;
    },

    /**
     * Detains a callback for a fixed amount of time. This function is mostly used
     * to extend the time user feedback is visible.
     */
    detainFeedback(callback) {
        setTimeout(callback, 500);
    },
};

export default helpers;
