import Vue from 'vue';

export default Vue.component('btn', {
    name: 'Btn',
    props: {
        disabled: Boolean,
        element: {
            type: String,
            default: 'button',
        },
        error: Boolean,
        idle: {
            type: Boolean,
            default: true,
        },
        loading: Boolean,
        success: Boolean,
    },
    data() {
        return {
            d_disabled: this.disabled,
            d_error: this.error,
            d_success: this.success,
        };
    },
    watch: {
        disabled(value) {
            this.$nextTick(() => {
                this.d_disabled = value;
            });
        },
        error(value) {
            this.d_error = value;
        },
        loading(value) {
            this.d_disabled = value;

            if (value) {
                this.d_error = false;
                this.d_success = false;
            }
        },
        success(value) {
            this.d_success = value;
        },
    },
    render(createElement) {
        return createElement(
            this.element,
            {
                attrs: {
                    disabled: this.d_disabled,
                },
                class: {
                    btn: true,
                    disabled: this.d_disabled,
                    error: this.d_error,
                    idle: this.idle,
                    loading: this.loading,
                    success: this.d_success,
                },
                on: {
                    click: () => {
                        this.$emit('click');
                    },
                },
            },
            [
                createElement('span', {
                    class: {text: true},
                }, this.$slots.default),
            ],
        );
    },
});
