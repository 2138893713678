<template>
    <div>
        <template v-if="resource.loading">
            <div class="text-center">
                <loader :loading="true" />
            </div>
        </template>

        <template v-else-if="resource.fatal">
            <div class="text-center text-danger">
                <i class="fa fa-2x fa-exclamation-triangle" />

                <div
                    v-if="showErrorMessage"
                    class="mt-3"
                >
                    <template v-if="!!resource.firstErrorMessage">
                        {{ resource.firstErrorMessage }} <br>
                    </template>

                    {{ $t('common.errors.reload_page') }}
                </div>
            </div>
        </template>

        <slot v-else />
    </div>
</template>

<script>
import {Collection, Model} from '@/models/vue-mc/Model';

/**
 * A component to show the loading or fatal state of a resource
 * (Model / Collection).
 */
export default {
    name: 'WaitForResource',
    props: {
        resource: {
            type: [Collection, Model],
            required: true,
        },
        showErrorMessage: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
