<template>
    <b-navbar
        v-bind="$attrs"
        toggleable="lg"
        :type="type"
    >
        <b-navbar-brand
            :to="{name: 'home'}"
            exact
        >
            <img
                :src="logo"
                width="30px"
            >
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" />

        <b-collapse
            id="nav-collapse"
            is-nav
        >
            <b-navbar-nav class="nav">
                <!-- Menu for guests. -->
                <template v-if="!me.id">
                    <b-nav-item
                        href="https://wearematchplay.com/"
                        target="_blank"
                    >
                        {{ $t('navbar.default.about') }}
                    </b-nav-item>

                    <b-nav-item
                        href="https://wearematchplay.com/faq"
                        target="_blank"
                    >
                        {{ $t('navbar.default.faq') }}
                    </b-nav-item>
                </template>

                <!-- Menu for authenticated users. -->
                <template v-if="me.id">
                    <b-nav-item
                        :to="{name: 'competitions.index'}"
                        exact
                    >
                        {{ $t('home.title') }}
                    </b-nav-item>

                    <b-nav-item
                        :to="{name: 'cups.index'}"
                    >
                        {{ $t('cups.index.title') }}
                    </b-nav-item>

                    <b-nav-item
                        :to="{name: 'courses.index'}"
                    >
                        {{ $t('courses.index.title') }}
                    </b-nav-item>
                </template>

                <!-- Menu for users with roles. -->
                <b-nav-item
                    v-if="me.can('view', User)"
                    :to="{name: 'users.index'}"
                >
                    {{ $t('users.index.title') }}
                </b-nav-item>

                <b-nav-item
                    v-if="me.hasRole('admin')"
                    :to="{name: 'statistics.index'}"
                >
                    {{ $t('statistics.index.title') }}
                </b-nav-item>
            </b-navbar-nav>

            <navbar-nav-user class="ml-auto align-items-baseline" />
        </b-collapse>
    </b-navbar>
</template>

<script>
import Me from '@/library/Me';
import NavbarNavUser from '@/components/common/NavbarNavUser';
import User from '@/models/User';

export default {
    name: 'Navbar',
    components: {NavbarNavUser},
    props: {
        type: {
            type: String,
            default: 'light',
        },
    },
    data() {
        return {
            me: new Me,
            User,
        };
    },
    computed: {
        logo() {
            return this.type === 'light'
                ? require('@/assets/images/logo.svg')
                : require('@/assets/images/logo_white.svg');
        },
    },
};
</script>
