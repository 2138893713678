import Show from '@/components/statistics/Show';
import Index from '@/components/statistics/Index';
import OwRouterView from '@/components/common/OwRouterView';

let routes = [
    {
        path: '/statistics',
        component: OwRouterView,
        meta: {
            middleware: ['Auth', 'Role'],
            roles: ['admin'],
        },
        children: [
            {
                path: '',
                name: 'statistics.index',
                component: Index,
            },
            {
                path: ':model',
                name: 'statistics.show',
                component: Show,
            },
        ],
    },
];

export default routes;
