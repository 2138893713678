<template>
    <b-card>
        <b-card-title>
            Modal
        </b-card-title>

        <p class="mb-4">
            Use Bootstrap’s JavaScript modal plugin to add dialogs to your site for lightboxes, user notifications, or completely custom content.
        </p>

        <div class="mb-4">
            <h5>
                Example
            </h5>

            <p>
                An example of the modal component.
            </p>

            <preview class="bg-light">
                <div class="modal preview">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    Modal title
                                </h5>

                                <button class="close">
                                    <span>
                                        &times;
                                    </span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <p>
                                    Modal body text goes here.
                                </p>
                            </div>

                            <div class="modal-footer">
                                <b-button
                                    variant="link"
                                    class="text-danger"
                                >
                                    Cancel
                                </b-button>

                                <b-button
                                    variant="primary"
                                    class="btn-min-w"
                                >
                                    Save changes
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </preview>
        </div>

        <div>
            <h5>
                Live demo
            </h5>

            <p>
                Toggle a working modal demo by clicking the button below. It will slide down and fade in from the top of the page.
            </p>

            <preview>
                <b-button v-b-modal.modal-1>
                    Launch demo modal
                </b-button>

                <b-modal
                    id="modal-1"
                    title="Modal title"
                >
                    <p>
                        Woohoo, you're reading this text in a modal!
                    </p>

                    <template #modal-footer>
                        <b-button
                            variant="primary"
                            class="btn-min-w"
                        >
                            Save changes
                        </b-button>
                    </template>
                </b-modal>
            </preview>
        </div>
    </b-card>
</template>

<script>
import Preview from '@/components/styleguide/Preview.vue';

export default {
    name: 'Modal',
    components: {Preview},
};
</script>


<style scoped>
.modal.preview {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}
</style>
