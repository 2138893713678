export default {
    // Common
    autocomplete: {
        placeholder: 'Click here to add teams',
    },
    model: {
        name: {
            label: 'Team name',
            placeholder: 'Team name',
        },
        code: {
            label: 'Team code',
            placeholder: 'XXX',
        },
        club_name: {
            label: 'Club name',
            placeholder: 'Club name',
        },
        logo: {
            label: 'Team logo',
            placeholder: 'Team logo',
        },
        captain: {
            label: 'Captain',
            placeholder: 'Captain',
        },
    },

    // Routes
    create: {
        title: 'Create new team',
    },
    edit: {
        create_title: 'Enter information',
        create_player: 'Create player',
        title: 'Edit team',
    },
};
