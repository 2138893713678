<template>
    <div class="cups-started">
        <rounds-list :rounds="cup.rounds" />
    </div>
</template>

<script>
import Cup from '@/models/Cup';
import RoundsList from '@/components/cups/rounds/List';

export default {
    name: 'CupsStarted',
    components: {RoundsList},
    props: {
        cup: {
            type: Cup,
            required: true,
        },
    },
};
</script>
