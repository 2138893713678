import Me from '@/library/Me';

const httpInterceptors = {
    install(Vue) {
        Vue.http.interceptors.push(() => {
            // return response callback
            return (response) => {
                if (response.status === 401) {
                    Me.logout();
                }
            };
        });
    },
};

export default httpInterceptors;