import {Address} from './Address';
import {TeamUsers} from './TeamUser';
import {Model, Collection} from './vue-mc/Model';
import {MatchVersusParticipant} from './MatchVersus';

export class Team extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            slug: null,
            name: null,
            code: null,
            club_name: null,
            phone: null,
            website: null,
            image: null,
            address: {},
            team_users: [],
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            address: (val) => {
                if (!(val instanceof Address)) {
                    return new Address(val);
                }

                return val;
            },
            team_users: (val) => {
                if (!(val instanceof TeamUsers)) {
                    return new TeamUsers(val);
                }

                return val;
            },
        };
    }

    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'teams',
            identifier: 'slug',
        };
    }

    /**
     * Transforms the Team to a MatchVersusParticipant instance.
     *
     * @return {MatchVersusParticipant}
     */
    toMatchVersusParticipant() {
        return new MatchVersusParticipant(
            this.name,
            this.image[140],
        );
    }
};

export class Teams extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Team,
        };
    }
};
