<template>
    <div class="auth-login">
        <form
            class="auth-login-form"
            @submit.prevent="submit"
        >
            <fieldset class="py-5">
                <form-group
                    v-model="d_form.data.email"
                    :error="d_form.errors.first('email') || d_form.errors.first('login')"
                    label="Email/Username"
                    placeholder="Email/Username"
                />

                <form-group
                    v-model="d_form.data.password"
                    :error="d_form.errors.first('password')"
                    :label="$t('password')"
                    type="password"
                >
                    <div
                        slot="input"
                        class="input-group"
                    >
                        <input
                            v-model="d_form.data.password"
                            :class="{ 'is-invalid': d_form.errors.has('password') }"
                            :placeholder="$t('password')"
                            class="form-control"
                            type="password"
                        >

                        <div
                            :class="{ 'is-invalid': d_form.errors.has('password') }"
                            class="input-group-append"
                        >
                            <b-btn
                                v-t="'auth.login.actions.forgot'"
                                class="px-2"
                                :to="{name: 'auth.password.forgot'}"
                                variant="link"
                            />
                        </div>
                    </div>
                </form-group>
            </fieldset>

            <div class="text-center">
                <btn
                    :error="d_form.error"
                    :loading="d_form.loading"
                    :success="d_form.success"
                    class="btn-primary btn-block"
                >
                    Login
                </btn>
            </div>
        </form>
    </div>
</template>

<script>
import Form from '@/library/Form';
import ApiToken from '@/library/ApiToken';
import Redirect from '@/library/Redirect';
import FormGroup from '@/components/common/form/Group';

export default {
    name: 'AuthLogin',
    components: {FormGroup},
    data() {
        return {
            d_form: new Form({
                email: '',
                password: '',
            }),
        };
    },
    methods: {
        submit() {
            this.d_form.post('/auth/login')
                .then((response) => {
                    this.d_form.loading = true;

                    new ApiToken().setToken(response.data.api_token);

                    Redirect.to({name: 'competitions.index'});
                });
        },
    },
};
</script>
