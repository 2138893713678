import Model from '@/models/Model';
import Policy from '@/library/policies/Policy';

class Cup extends Policy {
    /**
     * Can store.
     *
     * @param {User} user
     * @param {Model} model
     */
    static store(user, model) {
        return user.hasRole(['admin', 'editor', 'club_manager']);
    }

    /**
     * Can update.
     *
     * @param {User} user
     * @param {Model} model
     */
    static update(user, model) {
        // A user can edit a cup if he is either an admin or a club manager AND the created of said cup
        return (user.hasRole('admin') || (user.hasRole('club_manager') && (model instanceof Model ? user.id === model.creator_id : true)));
    }

    /**
     * Can start.
     *
     * @param {User} user
     * @param {Model} model
     */
    static start(user, model) {
        return user.hasRole('admin');
    }

    /**
     * Can set bcko
     *
     * @param {User} user
     * @param {Model} model
     */
    static setBcko(user, model) {
        return user.hasRole('admin');
    }
}

export default Cup;
