import ApiToken from '@/library/ApiToken';
import Middleware from '@/library/Middleware';

export default class Guest extends Middleware {
    /**
     * Guest constructor.
     */
    constructor() {
        super();

        this.name = 'Guest';
    }

    /**
     * Handles the middleware. Redirects to home when an API token is present.
     *
     * @param {Object} to
     * @param {Object} from
     * @param {Function} next
     */
    handle(to, from, next) {
        if (!this.needsToHandle(to)) {
            return next();
        }

        if ((new ApiToken).getToken()) {
            return next({
                name: 'home',
            });
        }

        return next();
    }
}
