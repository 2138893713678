
export default class Middleware {
    constructor() {
        this.name = '';
    }

    needsToHandle(route) {
        for (let i in route.matched) {
            let match = route.matched[i];

            let middleware = match.meta.middleware;

            if (middleware && middleware.length && middleware.indexOf(this.name) > -1) {
                return true;
            }
        }

        return false;
    }
}
