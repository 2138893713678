import Policy from '@/library/policies/Policy';

class User extends Policy {
    /**
     * Can view.
     *
     * @param {User} user
     * @param {Model} model
     */
    static view(user, model) {
        return user.hasRole('admin');
    }

    /**
     * Can store.
     *
     * @param {User} user
     * @param {Model} model
     */
    static update(user, model) {
        return user.hasRole('admin');
    }
}

export default User;
