import {get, has, values, isPlainObject} from 'lodash';
import {Response as BaseResponse} from 'vue-mc';

export default class Response extends BaseResponse {
    /**
     * Overrides vue-mc's Response to be able to handle resource from API.
     *
     * @returns {Promise}
     */
    getData() {
        // If the response's data is a plain object and it contains `data`,
        // then return `data.data`.
        if (
            isPlainObject(this.response.data)
            && has(this.response, 'data.data')
        ) {
            // For some reason, the paginated data returned from API is not an
            // array but an object. We need to transform this object into
            // an array by using `_.values`.
            return values(get(this.response, 'data.data', null));
        }

        return get(this.response, 'data', null);
    }

    /**
     * Overrides vue-mc's Response to return our API validation errors.
     *
     * @returns {Array|null}
     */
    getValidationErrors() {
        return get(this.response, 'data.errors', null);
    }

    /**
     * Returns the error message contained by the response.
     *
     * @returns {string|null}
     */
    getErrorMessage() {
        return get(this.response, 'data.message', null);
    }
}
