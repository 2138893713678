import cupRoutes from '@/router/routes/cups';
import userRoutes from '@/router/routes/users';
import teamRoutes from '@/router/routes/teams';
import courseRoutes from '@/router/routes/courses';
import leagueRoutes from '@/router/routes/leagues';
import NotFound from '@/components/common/NotFound';
import Styleguide from '@/components/styleguide/Index';
import LayoutDefault from '@/components/layouts/Default';
import statisticsRoutes from '@/router/routes/statistics';
import WhitelabelPortal from '@/components/whitelabels/Portal';

const children = [
    ...cupRoutes,
    ...userRoutes,
    ...teamRoutes,
    ...courseRoutes,
    ...leagueRoutes,
    ...statisticsRoutes,
];

const routes = [
    {
        path: '/',
        name: 'home',
        component: WhitelabelPortal,
        meta: {
            middleware: ['AuthOrGuest'],
        },
        children: [
            {
                path: '/competitions',
                name: 'competitions.index',
                component: () => import('@/components/Index.vue'),
                meta: {
                    middleware: ['Auth'],
                },
            },
            ...children,
        ],
    },
    {
        path: '*',
        name: 'not_found',
        component: LayoutDefault,
        children: [
            {
                path: '',
                component: NotFound,
                meta: {
                    middleware: ['Auth'],
                },
            },
        ],
    },
];

// Add the styleguide route when environment is not production.
if (process.env.NODE_ENV !== 'production') {
    routes.unshift({
        path: '/styleguide',
        component: Styleguide,
    });
}

export default routes;
