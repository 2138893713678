import Role from '@/library/enums/Role';
import {Model, Collection} from './vue-mc/Model';
import {MatchVersusParticipant} from './MatchVersus';
import {User} from './vue-mc/User';

export class TeamUser extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            user_id: null,
            user: {},
            roles: [],
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'team-users',
        };
    }

    transformations() {
        return {
            user: User,
        };
    }

    /**
     * Translate and concatenate roles into a string.
     */
    get rolesToString() {
        if (!this.roles.length) {
            // Display 'User' when no roles are found.
            return Role.$t('USER');
        }

        return this.roles.map((role) => {
            return Role.$t(role.toUpperCase());
        }).join(', ');
    }

    /**
     * Returns a MatchVersusParticipant instance of this TeamUser.
     *
     * @return {MatchVersusParticipant}
     */
    toMatchVersusParticipant() {
        return new MatchVersusParticipant(
            `${this.user.first_name} ${this.user.last_name}`,
            this.user.image[140],
        );
    }
};

export class TeamUsers extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: TeamUser,
        };
    }
};
