<template>
    <table class="table">
        <thead v-if="showHeaders && headers.length">
            <tr>
                <slot
                    v-for="(header, index) of headers"
                    :header="header"
                    :index="index"
                    :name="`header_${header}`"
                >
                    <th
                        :key="header"
                        v-trans
                    >
                        {{ header }}
                    </th>
                </slot>
            </tr>
        </thead>

        <tbody>
            <!-- The first row slot, to allow inserting markup in the first row. -->
            <slot
                :columns="headers"
                name="firstRow"
            />

            <tr
                v-if="!loading && !rows.length"
                class="no-hover no-click"
            >
                <td
                    colspan="999"
                    class="text-center border-top-0"
                >
                    <slot name="noResults">
                        {{ $t('no_results') }}
                    </slot>
                </td>
            </tr>

            <template
                v-for="(row, index) of rows"
                v-else
            >
                <slot
                    :columns="headers"
                    :index="index"
                    :row="row"
                    name="row"
                >
                    <tr
                        :is="tableRowComponent"
                        v-if="tableRowComponent"
                        :key="`row_${index}`"
                        :columns="headers"
                        :index="index"
                        :row="row"
                        v-on="$listeners"
                        @click.native="onRowClick(index)"
                    />

                    <tr
                        v-else
                        :key="`row_${index}`"
                        @click="onRowClick(index)"
                    >
                        <slot
                            v-for="property in headers"
                            :index="index"
                            :name="property"
                            :row="row"
                        >
                            <td :key="`row_${index}_${property}`">
                                {{ row[property] }}
                            </td>
                        </slot>
                    </tr>
                </slot>
            </template>

            <tr
                v-if="loading"
                class="no-hover no-click"
            >
                <td
                    colspan="999"
                    class="loader-container text-center"
                >
                    <loader :loading="true" />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'OwTable',
    props: {
        headers: {
            type: Array,
            default: () => [],
        },
        loading: Boolean,
        rows: {
            type: Array,
            default: () => [],
        },
        showHeaders: {
            type: Boolean,
            default: true,
        },
        tableRowComponent: {
            type: Object,
            default: undefined,
        },
    },
    methods: {
        onRowClick(index) {
            this.$emit('onRowClick', {
                index: index,
                row: this.rows[index],
            });
        },
    },
};
</script>
