<template>
    <ol class="cups-matches-list list-unstyled">
        <template v-if="matches.length">
            <li
                v-for="match of matches"
                :key="match.id"
            >
                <collapse :match="match" />
            </li>
        </template>

        <li
            v-else
            v-trans
            class="text-center"
        >
            cups.matches.no_results
        </li>
    </ol>
</template>

<script>
import Collapse from '@/components/cups/matches/Collapse';

export default {
    name: 'CupsMatchesList',
    components: {Collapse},
    props: {
        matches: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
ol {
    li {
        padding-bottom: .5rem;

        &:nth-child(2n):not(:last-child) {
            padding-bottom: 2rem;
        }
    }
}
</style>
