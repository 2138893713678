<template>
    <b-card
        class="bg-transparent cursor-pointer"
        no-body
        @click="$emit('click', post)"
    >
        <ow-img
            class="card-img"
            src-key="560"
            :src="post.cover_image || {}"
        />

        <b-card-body class="px-0">
            <h6 class="mb-0">
                {{ post.title }}
            </h6>

            <small class="text-muted">
                <slot
                    name="subTitleText"
                    :post="post"
                >
                    {{ subTitle }}
                </slot>
            </small>
        </b-card-body>
    </b-card>
</template>

<script>
import {Post} from '@/models/Post';
import PostType from '@/library/enums/PostType';
import moment from 'moment';

export default {
    name: 'PostCard',
    props: {
        post: {
            required: true,
            type: Post,
        },
        subTitleKey: {
            default: undefined,
            type: String,
        },
    },
    computed: {
        computedKey() {
            if (this.subTitleKey) {
                return this.subTitleKey;
            }

            switch (this.post.type) {
                case PostType.NEWS:
                    return 'created_at';

                default:
                    return 'sub_title';
            }
        },
        createdAt() {
            return moment(this.post.created_at).format();
        },
        subTitle() {
            switch (this.computedKey) {
                case 'created_at':
                    return this.createdAt;

                default:
                    return this.post[this.computedKey];
            }
        },
    },
};
</script>
