import {Collection, Model} from './vue-mc/Model';
import vueI18n from '@/lang/vueI18n';
import helpers from '@/library/helpers';
import {LeagueGroups} from './LeagueGroup';
import LeagueStatus from '@/library/enums/LeagueStatus';
import HandicapType from '@/library/enums/HandicapType';
import TeamMatchFormatType from '@/library/enums/TeamMatchFormatType';

export class League extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            current_user_league_user: null,
            current_user_team_user: null,
            data: {},
            handicap_type: null,
            league_groups: [],
            league_matchdays: [],
            slug: null,
            team_match_format_type: null,
            upcoming_league_matchday: {},
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'leagues',
            identifier: 'slug',
        };
    }

    /**
     * Mutations that should be done to the attributes of this model.
     *
     * @return {object}
     */
    mutations() {
        return {
            // TODO: Investigate a more elegant way to cast model attribute
            // to a certain type.
            league_groups: (val) => {
                if (!(val instanceof LeagueGroups)) {
                    return new LeagueGroups(val);
                }

                return val;
            },
            handicap_type: (val) => new HandicapType(val),
            team_match_format_type: (val) => new TeamMatchFormatType(val),
        };
    }

    /**
     * @return {number} Max amount of teams in the league.
     */
    get max_group_teams() {
        return this.max_groups * this.max_teams;
    }

    /**
     * @return {boolean} True if the league has started.
     */
    get has_started() {
        return [
            LeagueStatus.PLAYABLE,
            LeagueStatus.ENDED,
        ].includes(this.status);
    }

    /**
     * @return {string|undefined}
     */
    get card_background_color() {
        return helpers.dataGet(
            this,
            'advertisement_competition_card.card_background_color',
        );
    }

    /**
     * @return {string|undefined}
     */
    get card_background_image() {
        return helpers.dataGet(
            this,
            'advertisement_competition_card.web_card_background_image',
        );
    }

    /**
     * @return {string|undefined}
     */
    get header_background_image() {
        return helpers.dataGet(
            this,
            'advertisement_competition_card.web_header_background_image',
        );
    }

    /**
     * Returns data that is being send to the API.
     *
     * @return {object}
     */
    getSaveData() {
        let data = super.getSaveData();

        switch(this.status) {
            case LeagueStatus.WAITING_FOR_MATCHDAYS:
                return this.getSaveDataWaitingForMatchdays(data);
        }

        return data;
    }

    /**
     * Returns the data that is send to the API when the league is waiting for
     * matchdays.
     *
     * @param {object} The data of which the save data is extracted.
     * @return {object}
     */
    getSaveDataWaitingForMatchdays(data) {
        let leagueMatchdays = data.league_matchdays;

        if (leagueMatchdays === undefined) {
            leagueMatchdays = [];
        }

        return {
            league_matchdays: leagueMatchdays
                .filter((matchday) => matchday.changed() !== false)
                .map((matchday) => {
                    return {
                        id: matchday.id,
                        name: matchday.name,
                        location: matchday.location,
                        starts_at: matchday.starts_at,
                    };
                }),
        };
    }

    /**
     * Sets the errors on this model.
     *
     * @param {Object} errors
     */
    setErrors(errors) {
        super.setErrors(errors);

        // Add errors of league matchdays to its models.
        for (const key in errors) {
            if (!key.includes('league_matchdays.')) {
                continue;
            }

            const split = key.split('.');

            if (split.length < 3) {
                continue;
            }

            this.league_matchdays
                .models[split[1]]
                .setAttributeErrors(split[2], errors[key]);
        }
    }

    /**
     * Generate matchdays by making HTTP request to
     * `GET: /leagues/{slug}/generate-matchdays`
     *
     * @returns {Promise}
     */
    generateMatchdays() {
        const options = {
            url: this.getFetchURL() + '/generate-matchdays',
        };

        return this.fetch(options);
    }
}

export class Leagues extends Collection {
    /**
     * Options of this collection.
     */
    options() {
        return {
            model: League,
        };
    }
};
