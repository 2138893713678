import Request from '@/library/Request';

export default class Form extends Request {
    /**
     * Form constructor.
     *
     * @param {object} data
     */
    constructor(data = {}) {
        super();

        this.data = data;
    }

    /**
     * Send a POST request to the given URL.
     *
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url, this.data);
    }

    /**
     * Send a PUT request to the given URL.
     *
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url, this.data);
    }

    /**
     * Send a PATCH request to the given URL.
     *
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url, this.data);
    }
}
