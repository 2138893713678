<template>
    <b-navbar-nav>
        <b-nav-item
            v-if="!me.id"
            :to="{name: 'auth.login'}"
        >
            {{ $t('login') }}
        </b-nav-item>

        <template v-else>
            <b-nav-item>
                <ow-img
                    v-if="me.image"
                    :src="me.image"
                    class="img-table rounded-circle mr-2"
                />

                {{ me.displayName }}
            </b-nav-item>

            <b-nav-item @click="logout()">
                {{ $t('logout') }}
            </b-nav-item>
        </template>
    </b-navbar-nav>
</template>

<script>
import Me from '@/library/Me';

export default {
    name: 'NavbarNavUser',
    data() {
        return {
            dLeagues: this.leagues,
            me: new Me,
        };
    },
    methods: {
        logout() {
            this.me = undefined;

            Me.logout();
        },
    },
};
</script>
