<template>
    <div>
        <portal
            to="layout-default-header"
            name="layout-default-header-black"
        >
            <b-row
                align-v="end"
                class="pt-5"
            >
                <b-col class="mb-3 mb-sm-0">
                    <h1 class="mb-0">
                        {{ $t('statistics.index.title') }}
                    </h1>
                </b-col>
            </b-row>
        </portal>

        <statistics-table
            :loading="d_loading"
            :rows="d_rows"
            class="table-hover"
            @onRowClick="onRowClick"
        />
    </div>
</template>

<script>
import Redirect from '@/library/Redirect';
import Statistics from '@/models/Statistic';
import StatisticsTable from '@/components/statistics/Table';

export default {
    name: 'StatisticsIndex',
    components: {StatisticsTable},
    data() {
        return {
            d_loading: false,
            d_rows: [],
        };
    },
    created() {
        this.d_loading = true;

        Statistics.get().then(({data}) => {
            this.d_rows = data;

            this.d_loading = false;
        });
    },
    methods: {
        onRowClick({row}) {
            Redirect.to({name: 'statistics.show', params: {model: row.model}});
        },
    },
};
</script>
